//import { getAllTerms } from "actions/term.action";
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { EditATerm, getAllTerms } from "../../api/term";
import { Link } from "react-router-dom";
import { useQuill } from "react-quilljs";
import Loading from "../../components/Loading/Loading";
import CustomAlert from "../../components/Alerts/CustomAlert";

export default function TCManagementPersonal() {
  const [terms, setTerms] = useState();

  const [policy, setPolicy] = useState();

  const [id, setId] = useState();
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  const [message, setMessage] = useState("")


  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: "#toolbar"
    },
    formats: ["size", "bold", "script"] // Important
  });

  //const id = terms.term_id;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    getAllTerms().then((res) => {
      if (res[0]) {
        setTerms(res[0].service);
        setPolicy(res[0].personal);
        quill.clipboard.dangerouslyPasteHTML(res[0].personal)
        setId(res[0].term_id);
      }
    }).finally(()=>{
      setLoading(false)
    });

    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        setPolicy(quill.root.innerHTML.toString()); // Get innerHTML using quill
      });
    }
  }, [quill]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let term = { ...terms, ...{ [name]: value } };
    setTerms(term);
  };


  const handleClick = () => {
    setOpen(true)
    if (!policy) {
      setMessage("policy required")
    } {
      const data = {
        service: terms,
        personal: policy
      };

      EditATerm(data, id).then((res) => {
        res.status === "success" && setAlert({ type: "success", message: "Success." });;

      });
    }

  };

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>
        서비스 이용약관 관리
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-lg-6">
          <label style={{ color: "#000" }}> 특정 IP 차단</label> <br />
          <div style={{ width: 800, height: 600 }}>
            <div id="toolbar">
              <select className="ql-size">
                <option value="small" />
                <option selected />
                <option value="large" />
                <option value="huge" />
              </select>
              <button className="ql-bold" />
              <button className="ql-script" value="sub" />
              <button className="ql-script" value="super" />
            </div>
            <div ref={quillRef} />
            <div id="editor" />
          </div>
        </div>
      </div>
      <div className="text-danger">{message}</div>
      <div className="row mt-5">
        <div className="col-lg-1">
          <Button
            onClick={handleClick}
            style={{
              backgroundColor: "#5376FF",
              width: "100px",
              height: "auto",
              outline: "none",
              outline: "none"
            }}
            variant="contained"
            color="primary"
          >
            저장
          </Button>
        </div>
      </div>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
          {/*<Loading open={loading} />*/}
    </div>
  );
}
