import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { FormControlLabel } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomAlert from "../../components/Alerts/CustomAlert";
import Slide from "@mui/material/Slide";
import {
  getASetting,
  addaSetting,
  editSetting,
  deleteAnIP,
  addAnIp
} from "../../api/nft";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NFTSettings() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [message, setMessage] = useState();

  const [service, setService] = useState({
    capacity: null,
    royalty: 0,
    admin_fee_usage: false,
    admin_polyfee_usage: false,
    admin_fee: 0.001,
    admin_polyfee: 0.001,
    ip: []
  });

  const [ips, setIps] = useState();

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open3, setOpen3] = React.useState(true);

  const [stop, setStop] = useState({
    admin_id: localStorage.getItem("admin"),
    password: ""
  });

  useEffect(() => {
    getASetting().then((res) => {
      if (res) {
        setService({ admin_fee: res[0].fees.admin_fee,admin_polyfee:res[0]?.fees.admin_polyfee, admin_fee_usage: res[0].fees.admin_fee_usage, admin_polyfee_usage: res[0].fees.admin_polyfee_usage });
        setService(res[0]);
        setIps(res[0].ip);
      } else {
        localStorage.removeItem("token");
        window.location = "/";
      }
    });
  }, []);

  useEffect(() => {

  },[service])

  const handleChange = (e) => {
    const { name, value } = e.target;
   
    let formData;

    if (name === "admin_fee_usage" || name === "admin_polyfee_usage") {
      formData = {
        ...service,
        fees: {
          ...service.fees,
          [name]: value === "true",
        },
      };
    } else if (name === "admin_fee" || name === "admin_polyfee"){
      formData = {
        ...service,
        fees: {
          ...service.fees,
          [name]: Math.max(0, value),
        },
      };
    }else {
      formData = {
        ...service,
        [name]: value,
      };
    }

    setService(formData);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleAutoClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen3(false);
  };

  const handleChangeStopped = (e) => {
    const { name, value } = e.target;
    let formData = { ...stop, ...{ [name]: value } };
    setStop(formData);
  };

  const addSetting = () => {
    setOpen3(true);
    if (!service.admin_fee) {
      const data = {};
    }
    addaSetting(service)
      .then(() => {
        setAlert({ type: "success", message: "Successfully added." });
      })
      .catch((e) => {});
  };

  const updateSettings = (service, id) => {
    setOpen3(true);
    editSetting(service)
      .then((res) => {
        res.status == "success"
          ? setAlert({ type: "success", message: "Successfully edited." })
          : setAlert({ type: "error", message: "Srtting changing failed." });

        handleAutoClose();
      })
      .catch((e) => {});
  };

  const ipDelete = (id, ip) => {
    setOpen3(true);
    deleteAnIP(id, ip)
      .then(() => {
        setAlert({ type: "success", message: "IP Successfully deleted." });
        window.location.reload();
      })
      .catch((e) => {});
  };

  const ipAdd = (id, ip) => {
    setOpen3(true);
    addAnIp(stop, id, ip)
      .then(() => {
        setAlert({ type: "success", message: "IP Successfully added." });
        //window.location.reload();
      })
      .catch((e) => {});
  };

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>NFT 설정</div>
      <div className="row mt-5">
        <div className="col-lg-6">
          <label style={{ color: "#000" }}> 파일 업로드 허용 용량(mb)</label>{" "}
          <br />
          <TextField
            type="number"
            variant="outlined"
            id="capacity"
            name="capacity"
            value={service.capacity}
            onChange={handleChange}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-3">
          <FormLabel style={{ color: "#000" }} component="legend">
            {" "}
            ETH 수수료사용여부
          </FormLabel>
          <RadioGroup
          id="admin_fee_usage"
          onChange={handleChange}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                style={{ color: "#000" }}
                control={<Radio checked={service?.fees?.admin_fee_usage === true  }/>}
                label="사용함"
                value={true}
                name="admin_fee_usage"
                onChange={handleChange}
              />
              <FormControlLabel
                style={{ color: "#000" }}
                control={<Radio checked={service?.fees?.admin_fee_usage === false} />}
                label="사용안함"
                value={false}
                name="admin_fee_usage"
                onChange={handleChange}
              />
            </div>
          </RadioGroup>
        </div>
        <div className="col-lg-3">
          <label style={{ color: "#000" }}> ETH 수수료</label> <br />
          <TextField
            type="number"
            variant="outlined"
            id="admin_fee"
            name="admin_fee"
            onChange={handleChange}
            value={service.admin_fee}
            defaultValue={service.admin_fee}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: 0.001,
            }}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-3">
          <FormLabel style={{ color: "#000" }} component="legend">
            {" "}
            Polygon 수수료사용여부
          </FormLabel>
          <RadioGroup
            onChange={handleChange}
            id="admin_polyfee_usage"
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                style={{ color: "#000" }}
                control={<Radio checked={service?.fees?.admin_polyfee_usage === true } />}
                label="사용함"
                value={true}
                name="admin_polyfee_usage"
                onChange={handleChange}
              />
              <FormControlLabel
                style={{ color: "#000" }}
                control={<Radio checked={service?.fees?.admin_polyfee_usage === false } />}
                label="사용안함"
                value={false}
                name="admin_polyfee_usage"
                onChange={handleChange}
              />
            </div>
          </RadioGroup>
        </div>
        <div className="col-lg-3">
          <label style={{ color: "#000" }}> Polygon 수수료</label> <br />
          <TextField
            type="number"
            variant="outlined"
            id="admin_polyfee"
            name="admin_polyfee"
            onChange={handleChange}
            value={service.admin_polyfee}
            defaultValue={service.admin_polyfee}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 0.001,
            }}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-6">
          <label style={{ color: "#000" }}> NFT 로열티(%)</label> <br />
          <TextField
            variant="outlined"
            type="number"
            name="royalty"
            value={service.royalty}
            onChange={handleChange}
            style={{ width: "100%" }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
      </div>
      {/* <div className="row mt-5">
        <div className="col-lg-6">
          <label style={{ color: "#000" }}> 특정 IP 차단</label> <br />
          <div
            className="px-3 py-3"
            style={{ border: "1px solid #000", borderRadius: "8px" }}
          >
            {ips &&
              ips.map((ip) => {
                return (
                  <div className="d-flex py-2">
                    <div
                      style={{
                        flex: "3",
                        fontWeight: "600",
                        fontSize: "20px",
                        color: "#000"
                      }}
                    >
                      {ip}
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          ipDelete(service.fees_id, ip);
                        }}
                        style={{
                          backgroundColor: "#5376FF",
                          width: "100px",
                          height: "auto"
                        }}
                        variant="contained"
                        color="primary"
                      >
                        삭제
                      </Button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div> */}
      <br />
      <div className="text-danger">{message}</div>

      {/* <div className="row mt-3">
        <div className="col-lg-6" style={{ textAlign: "right" }}>
          <Button
            onClick={handleClickOpen2}
            style={{
              backgroundColor: "#5376FF",
              width: "100px",
              height: "auto"
            }}
            variant="contained"
            color="primary"
          >
            추가
          </Button>
        </div>
      </div> */}
      <div className="row mt-2">
        <div className="col-1 mt-2" style={{marginRight: "16px"}}>
          {service.capacity == null ? (
            <Button
              onClick={addSetting}
              disabled
              style={{
                width: "100px",
                height: "42px",
                backgroundColor: "#3a52b2",
                color: "#fff"
              }}
            >
              저장
            </Button>
          ) : (
            <Button
              onClick={() => {
                updateSettings(service, service.fees_id);
              }}
              style={{
                width: "100px",
                height: "42px",
                backgroundColor: "#5376FF",
                color: "#fff"
              }}
            >
              저장
            </Button>
          )}
        </div>
        <div className="col-lg-10"></div>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"특정 IP 발행 차단하기"}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="email"
              className="text-fields"
              placeholder="admin03"
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <input
              type="text"
              className="text-fields"
              placeholder="관리자 비밀번호를 입력해주세요."
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 추가 차단할 IP</label> <br />
            <input
              type="text"
              className="text-fields"
              placeholder="차단할 IP를 입력해주세요."
              style={{ width: "400px" }}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          {service?.admin_fee == null ? (
            <Button
              onClick={addSetting}
              style={{
                backgroundColor: "#076D42",
                color: "#fff",
                width: "150px",
                height: "auto"
              }}
            >
              Add
            </Button>
          ) : (
            <Button
              onClick={() => {
                updateSettings(service, service.fees_id);
              }}
              style={{
                backgroundColor: "#076D42",
                color: "#fff",
                width: "150px",
                height: "auto"
              }}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto"
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"특정 IP 발행 차단하기"}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="admin_id"
              disabled
              className="text-fields"
              placeholder="admin03"
              handleChange={handleChangeStopped}
              value={stop.admin_id}
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <input
              type="password"
              name="password"
              className="text-fields"
              value={stop.password}
              onChange={handleChangeStopped}
              placeholder="관리자 비밀번호를 입력해주세요."
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="ip"
              onChange={(e) => {
                setIps(e.target.value);
              }}
              className="text-fields"
              placeholder="0.0.0.0"
              style={{ width: "400px" }}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={() => {
              ipAdd(service.fees_id, ips);
            }}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto"
            }}
          >
            거래 중지
          </Button>

          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto"
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open3}
        handleClose={handleClose}
      />
    </div>
  );
}
