import axios from "axios";
const config = {
    headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
    }
};


export const getAllExposes = async (id) => {
  const limit = 10;
    try {
      let respData = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/expose/all?page=${id}&limit=${limit}`,
        config
      );
      return respData.data;
    } catch (err) {
      localStorage.removeItem("token");
      return {
        status: "failed",
        loading: false,
        error: err.response.data.errors
      };
    }
  };


  export const addNewExpose = async (data) => {
    try {
      const values = {
        category: data.category_name,
        nftName: data.title,
        status: data.status,
        isTradingStopped: false,
        originator: data.creator.creator_nickname,
        owner: data.current_owner.current_owner_nickname,
        coin: data.sale_coin,
        price: data.sale_price || data.auction_start_price,
        history: data.description,
        art_work_id: data.art_work_id
      };
      let respData = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/expose/register`,
        values,
        config
      );
  
      return {
        status: "success",
        loading: false,
        result: respData.data.result
      };
    } catch (err) {
      return {
        status: "failed",
        loading: false,
        error: err.message
      };
    }
  };
  
  export const deleteAExpose = async (id) => {
    try {
      let respData = await axios.delete(
        `${process.env.REACT_APP_SERVER}/api/expose/delete/${id}`,
        config
      );
      return respData.data.data;
    } catch (err) {
      localStorage.removeItem("token");
      return {
        status: "failed",
        loading: false,
        error: err.response.data.errors
      };
    }
  };
  