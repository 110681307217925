import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { FormControlLabel } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { getAAvatar, EditAvatar } from "../../api/avatar";
import Loading from "../../components/Loading/Loading";

import CustomAlert from "../../components/Alerts/CustomAlert";
import axios from "axios";

export default function AvatarEdit() {
  const { id } = useParams();
  const [avatar, setAvatar] = useState({
    name: "",
    image: "",
    status: ""
  });

  const [image, setImage] = useState();

  const [preview, setPreview] = useState(null);

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("");

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    setLoading(true);
    getAAvatar(id).then((res) => {
      setAvatar(res);
      setLoading(false);
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formData = { ...avatar, ...{ [name]: value } };
    setAvatar(formData);
    setMessage("");
  };

  function handleSubmit(e) {
    e.preventDefault();

    setOpen(true);

    if (!avatar.name) {
      setMessage("Fill avatar name");
    } else if (!avatar.status) {
      setMessage("Fill status");
    } else {
      if (image) {
        var formData = new FormData();
        formData.append("name", avatar.name);
        formData.append("status", avatar.status);
        for (let key = 0; key < image.length; key++) {
          formData.append("image", image[key]);
        }
        EditAvatar(formData, id).then((res) => {
          res.data.data.error === false
            ? setAlert({ type: "success", message: "Successfully changed." })
            : setAlert({ type: "error", message: "Avatar changing failed." });
        });
      } else {
        axios
          .put(
            `${process.env.REACT_APP_SERVER}/api/avatar/edit/${id}`,
            avatar,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
              }
            }
          )
          .then((res) => {
            res.data.data.error === false
              ? setAlert({ type: "success", message: "Successfully changed." })
              : setAlert({ type: "error", message: "Avatar changing failed." });
          })
          .catch((err) => {
            setAlert({ type: "error", message: "Avatar changing failed." });
          });
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px", lineHeight: "40px" }}>
        Avata registration & Edit
      </div>

      <div
        className="mt-5 row"
        style={{ fontWeight: "700", fontSize: "40px", width: "100%" }}
      >
        <div className="col-lg-6">
          <div>
            <label
              style={{ color: "#000", fontWeight: "700", fontSize: "16px" }}
            >
              {" "}
              <i class="fas fa-caret-right"></i> Avata name
            </label>{" "}
            <br />
            <TextField
              id="filled-select-currency"
              placeholder="아바타명을 입력해주세요."
              variant="outlined"
              name="name"
              value={avatar.name}
              onChange={handleChange}
              fullwidth
              style={{ width: "300px" }}
            />
          </div>
          <div className="mt-4">
            <label
              style={{ color: "#000", fontWeight: "700", fontSize: "16px" }}
            >
              {" "}
              <i class="fas fa-caret-right"></i> Avata image File
            </label>{" "}
            <br />
            <div>
              <TextField
                type="file"
                id="filled-select-currency"
                placeholder="선택된 파일 없음"
                variant="outlined"
                name="image"
                onChange={(e) => {
                  setImage(e.target.files);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                }}
                fullwidth
                style={{ width: "300px" }}
              />
            </div>
            <div className="mt-4">
              <Button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#5376FF",
                  width: "200px",
                  height: "auto"
                }}
                variant="contained"
                color="primary"
              >
                Select file
              </Button>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <label style={{ color: "#000", fontWeight: "700", fontSize: "16px" }}>
            {" "}
            <i class="fas fa-caret-right"></i> Preview
          </label>{" "}
          {preview == null ? (
            <img
              src={avatar.image}
              alt="avatar"
              className="d-flex justify-content-center align-items-center"
              style={{
                height: "200px",
                width: "200px",
                border: "1px solid #000",
                borderRadius: "4px",
                fontSize: "16px"
              }}
            />
          ) : (
            <img
              alt="avatar"
              style={{
                height: "200px",
                width: "200px",
                border: "1px solid #000",
                borderRadius: "4px",
                fontSize: "16px"
              }}
              src={preview}
              className="d-flex justify-content-center align-items-center"
            />
          )}
        </div>
      </div>
      <div className="mt-5 row">
        <div className="col-lg-6"></div>
        <div className="col-lg-6">
          <label style={{ color: "#000", fontWeight: "700", fontSize: "16px" }}>
            {" "}
            <i class="fas fa-caret-right"></i> Status
          </label>{" "}
          <RadioGroup
            defaultValue={avatar?.status}
            id="usage"
            onChange={handleChange}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                style={{ color: "#000" }}
                value="true"
                name="status"
                control={<Radio checked={avatar?.status === "true"} />}
                label="used"
              />
              <FormControlLabel
                style={{ color: "#000" }}
                value="false"
                name="status"
                control={<Radio checked={avatar?.status === "false"} />}
                label="not used"
              />
            </div>
          </RadioGroup>
        </div>
      </div>
      <div
        className="row mt-5"
        style={{
          fontWeight: "700",
          fontSize: "18px",
          display: "flex",
          width: "100%"
        }}
      >
        <div className="text-danger">{message}</div>

        <div className="col-lg-1">
          <Link to="/admin-list">
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: "#5376FF",
                width: "100px",
                height: "auto"
              }}
              variant="contained"
              color="primary"
            >
              save
            </Button>
          </Link>
        </div>
        <div className="col-lg-1">
          <Link to="/avatar-list">
            <Button
              style={{
                border: "1px solid #5376FF",
                color: "#5376FF",
                width: "100px",
                height: "auto",
                backgroundColor: "transparent"
              }}
              variant="contained"
              color="primary"
            >
              List
            </Button>
          </Link>
        </div>
        <div className="col-lg-10"></div>
      </div>
      {/*<Loading open={loading} />*/}
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
