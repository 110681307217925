import React, { useEffect, useState } from "react";
import "../../pages/Login/index.css";

function Pagination({ pages, setAdmin, getList, getSearchList, setSize, setNewPage, setLoading, searchParams}) {
  const [clicked, setClicked] = useState(0);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    setNewPage(current);
  }, [current, setNewPage]);

  // const itemsPerPage = 10; 

  // const calculateStartingIndex = (pageNum) => {
  //   return (pageNum - 1) * itemsPerPage;
  // };

  const handlePageClick = (pageNum) => {
    setLoading(true);

    // const searchParamsToSend = new URLSearchParams(searchParams).toString();

    if (searchParams) {
      getList(pageNum, searchParams).then((res) => {
        console.log("searchParams :", searchParams);
        setLoading(false);
        setAdmin(res.data);
        setClicked(pageNum - 1);
        setCurrent(pageNum - 1);
        setSize(res?.data?.length);
      });
    } else {
      
      getList(pageNum, "").then((res) => {
        setLoading(false);
        setAdmin(res.data);
        setClicked(pageNum - 1);
        setCurrent(pageNum - 1);
        setSize(res?.data?.length);
      });
    }
  };

  return (
    <div>
      <nav>
        <ul className="d-flex justify-content-center">
          <div
            className="pagination-item"
            // onClick={(e) => {
            //   setLoading(true);
            //   getList(1).then((res) => {
            //     setLoading(false);
            //     setAdmin(res.data);
            //     setClicked(0);
            //     setCurrent(0);
            //     setSize(res?.data?.length);
            //   });
            // }}
            onClick={() => handlePageClick(1)}
          >
            First
          </div>
          <div
            className="pagination-item"
            style={{width: "74px", textAlign: "center"}}
            // onClick={(e) => {
            //   clicked > 0 &&
            //     setLoading(true);
            //     getList(clicked).then((res) => {
            //       setLoading(false);
            //       setAdmin(res.data);
            //       setClicked(clicked - 1);
            //       setSize(res.data.length);
            //       setCurrent(current - 1);
            //     });
            // }}
            onClick={() => {
              if (clicked > 0) {
                handlePageClick(clicked + 1);
              }
            }}
          >
            Previous
          </div>
          {Array.from(Array(pages), (e, i) => {
            return (
              <div
                className={
                  i === clicked ? "pagination-item-active" : "pagination-item"
                }
                style={{width:"32px", textAlign:"center"}}
                key={i}
                tabIndex={i}
                // onClick={(e) => {
                //   setLoading(true);
                //   getList(i + 1).then((res) => {
                //     setLoading(false);
                //     setAdmin(res.data);
                //     setClicked(i);
                //     setSize(res.data.length);
                //     setCurrent(i);
                //   });
                // }}
                onClick={() => handlePageClick(i + 1)}
              >
                {i + 1}
              </div>
            );
          })}
          <div
            className="pagination-item"
            style={{width: "74px", textAlign: "center"}}
            // onClick={(e) => {
            //   clicked <= pages - 2 &&
            //     setLoading(true);
            //     getList(clicked + 2).then((res) => {
            //       setLoading(false);
            //       setAdmin(res.data);
            //       setClicked(clicked + 1);
            //       setCurrent(current + 1);
            //     });
            // }}
            onClick={() => {
              if (clicked < pages - 1) {
                handlePageClick(clicked + 2);
              }
            }}
          >
            Next
          </div>
          <div
            className="pagination-item"
            // onClick={(e) => {
            //   setLoading(true);
            //   getList(pages).then((res) => {
            //     setLoading(false);
            //     setAdmin(res.data);
            //     setClicked(pages - 1);
            //     setSize(res?.data?.length);
            //     setCurrent(pages - 1);
            //   });
            // }}
            onClick={() => handlePageClick(pages)}
          >
            Last
          </div>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
