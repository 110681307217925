import React, { useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useEffect } from "react";
import Pagination2 from "../../components/Pagination/Pagination";
import { getAllPopular } from "../../api/popular";
import Loading from "../../components/Loading/Loading";
import { padding } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopularList() {
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9)
  ];

  const useStyles = makeStyles({
    table: {
      minWidth: 700
    }
  });
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [open2, setOpen2] = React.useState(false);

  const [open3, setOpen3] = React.useState(false);

  const [users, setUsers] = useState();

  const [popular, setPopular] = useState();

  const [change, setChange] = useState(false);

  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState();
  const [pages, setPages] = useState(1);

  const [size2, setSize2] = useState();
  const [newPage2, setNewPage2] = useState();
  const [pages2, setPages2] = useState(1);

  const [stop, setStop] = useState({
    admin_id: localStorage.getItem("admin"),
    password: ""
  });
  const [userId, setUserId] = useState();

  const [message, setMessage] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenHistory = () => {
    setOpen2(true);
  };

  const handleOpenDelete = (id) => {
    setOpen3(true);
    setUserId(id);
  };

  const handleAutoClose = () => {
    setOpen(false);
    setOpen2(false);
    setOpen3(false);
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllPopular(1).then((res) => {
      setLoading(true);
      setPopular(res.data);
      if (res.data) {
        setSize(res.data.length);
      }
      if (res.count !== 0) {
        setPages(res.count);
      }
    }).finally(() => {
      setLoading(false);
    });
  }, [change]);

  const renderingFunction = () => {
    change === true ? setChange(false) : setChange(true);
  };

  const handleChangeStopped = (e) => {
    const { name, value } = e.target;
    let formData = { ...stop, ...{ [name]: value } };
    setStop(formData);
  };

  return (
    <div>
      <div className="mx-5">
        <div style={{ fontWeight: "700", fontSize: "40px"}}>
          인기 크리에이터 목록
        </div>

        <div style={{ display: "flex", margin: "2% 0" }}>
          <div style={{ flex: "1" }}>
            <Button
              style={{
                border: "2px solid #5376FF",
                color: "#fff",
                outline: "none",
                paddingTop: "24px",
                paddingBottom: "24px"
              }}
              variant="contained"
              onClick={handleClickOpen}
            >
              인기 크리에이터 추가
            </Button>
          </div>
          <div style={{ flex: "8" }}></div>
        </div>

        <div
          className="align-items-center"
          style={{ fontWeight: "700", display: "flex" }}
        >
          <div style={{ flex: "7", margin: "0 10px" }}></div>
          <div style={{ margin: "0 10px", color: "#000", fontWeight: "700" }}>
            등록일 기간 검색
          </div>
        </div>

        <div
          className="align-items-center mt-2"
          style={{ fontWeight: "700", display: "flex" }}
        >
          <div style={{ flex: "6" }}></div>
          <div style={{ flex: "3", color: "#000", fontWeight: "700" }}>
            <TextField
              id="filled-select-currency"
              select
              placeholder="=상태="
              variant="outlined"
              style={{ width: "150px" }}
              label="=상태="
              name="status"
            >
              <MenuItem value="=검색옵션="> =검색옵션=</MenuItem>
              <MenuItem value="아이디">아이디</MenuItem>
              <MenuItem value="이름">이름</MenuItem>
            </TextField>
          </div>
          <div style={{ flex: "3" }}>
            <TextField
              id="filled-select-currency"
              placeholder="검색옵션을 선택해주세요."
              variant="outlined"
              fullwidth
            />
          </div>
          <div style={{ flex: "1", marginLeft:"-28px" }} >
            <Button
              style={{
                backgroundColor: "#5376FF",
                color: "#fff",
                padding: "28px 2px",
                
              }}
            >
              {" "}
              검색{" "}
            </Button>
          </div>
          <div style={{ flex: "1", marginLeft:"8px" }}>
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#5376FF",
                padding: "27px 1px",
                border: "1px solid #c4c4c4"
              }}
              onClick={handleClickOpenHistory}
            >
              {" "}
              Edit{" "}
            </Button>
          </div>
        </div>

        <div style={{ fontWeight: "500" }}>Total : 14 Count (1/1)Page</div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#E5E5E5" }}>
              <TableRow>
                <TableCell>NO</TableCell>
                <TableCell align="center">PK</TableCell>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">e-mail</TableCell>
                <TableCell align="center">ETH 수량</TableCell>
                <TableCell align="center">NFT발행 횟수</TableCell>
                {/* <TableCell align="center">MATIC 수량</TableCell> */}
                <TableCell align="center">등록일시</TableCell>
                <TableCell align="center">삭제</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {popular &&
                popular.map((user, index) => (
                  <TableRow key={index + 1}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{user.calories}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.nickname}</TableCell>
                    {user.wallet_type &&
                      user.wallet_type.map((wallet) => {
                        return (
                          <TableCell
                            style={{ fontSize: "10px" }}
                            align="center"
                          >
                            {wallet.wallet_key}
                          </TableCell>
                        );
                      })}

                    {(user.wallet_type == "" || user.wallet_type == null) && (
                      <TableCell
                        style={{ fontSize: "10px" }}
                        align="center"
                      ></TableCell>
                    )}
{/* 
                    {(user.wallet_type == "" || user.wallet_type == null) && (
                      <TableCell
                        style={{ fontSize: "10px" }}
                        align="center"
                      ></TableCell>
                    )} */}
                    <TableCell align="center">{user.status}</TableCell>
                    <TableCell align="center">
                      {user.created_at.split("T")[0]}
                      {"  "}
                      {user.created_at.split("T")[1].split(".")[0]}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() => {
                          handleOpenDelete(user._id);
                        }}
                        style={{
                          backgroundColor: "#5376FF",
                          color: "#fff",
                          padding: "0 2px",
                          outline: "none"
                        }}
                      >
                        {" "}
                        검색{" "}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="d-flex justify-content-center mt-5">
          <div style={{ flex: "8" }}>
            {
              //<Pagination count={30} variant="outlined" shape="rounded" />
            }
            <Pagination2
              pages={pages}
              // setAdmin={(popular) => setPopular(popular)}
              // getList={getAllPopulars}
              setSize={(size) => {
                setSize(size);
              }}
              setNewPage={(newPage) => {
                setNewPage(newPage + 1);
              }}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"인기 크리에이터 목록"}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <div
              className="align-items-center mt-2"
              style={{ fontWeight: "700", display: "flex" }}
            >
              <div style={{ flex: "6" }}></div>
              <div style={{ flex: "5", color: "#000", fontWeight: "700" }}>
                <TextField
                  id="filled-select-currency"
                  select
                  placeholder="=상태="
                  variant="outlined"
                  style={{ width: "150px" }}
                >
                  <MenuItem>=검색옵션=</MenuItem>
                  <MenuItem>아이디</MenuItem>
                  <MenuItem>이름</MenuItem>
                </TextField>
              </div>
              <div style={{ flex: "3" }}>
                <TextField
                  id="filled-select-currency"
                  placeholder="검색옵션을 선택해주세요."
                  variant="outlined"
                  fullwidth
                />
              </div>
              <div style={{ flex: "1" }}>
                <Button
                  style={{
                    backgroundColor: "#5376FF",
                    color: "#fff",
                    padding: "0 2px"
                  }}
                  onClick={handleClickOpen}
                >
                  {" "}
                  검색{" "}
                </Button>
              </div>
              <div style={{ flex: "1" }}>
                <Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#5376FF",
                    padding: "0 2px"
                  }}
                >
                  {" "}
                  Edit{" "}
                </Button>
              </div>
            </div>
          </DialogContentText>
          <DialogContentText>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead
                  style={{
                    backgroundColor: "#E5E5E5"
                  }}
                >
                  <TableRow style={{ fontSize: "15px !important" }}>
                    <TableCell>NO</TableCell>
                    <TableCell align="center">PK</TableCell>
                    <TableCell align="center">아이디</TableCell>
                    <TableCell align="center">닉네임</TableCell>
                    <TableCell align="center">NFT발행수</TableCell>
                    <TableCell align="center">삭제</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users &&
                    users.map((user, index) => (
                      <TableRow key={index + 1}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{user.calories}</TableCell>
                        <TableCell align="center">{user.email}</TableCell>
                        <TableCell align="center">{user.role}</TableCell>
                        <TableCell align="center">{user.status}</TableCell>
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              // addNewPopular(user).then((res) => {
                              //   res.status === "success" && alert("Success");
                              //   renderingFunction();
                              // });
                            }}
                            style={{
                              backgroundColor: "#5376FF",
                              color: "#fff",
                              padding: "0 2px",
                              outline: "none"
                            }}
                          >
                            {" "}
                            검색{" "}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>

        <DialogContent>
          <div className="d-flex justify-content-center mt-5">
            <div
              className="d-flex justify-content-center"
              style={{ flex: "8" }}
            >
              <Pagination2
                pages={pages2}
                // setAdmin={(user) => setUsers(user)}
                // getList={getAllUsers}
                setSize={(size2) => {
                  setSize2(size2);
                }}
                setNewPage={(newPage2) => {
                  setNewPage2(newPage2 + 1);
                }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"인기 크리에이터 삭제 관리자 확인"}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="email"
              className="text-fields"
              placeholder="admin03"
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <input
              type="text"
              className="text-fields"
              placeholder="관리자 비밀번호를 입력해주세요."
              style={{ width: "400px" }}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto"
            }}
          >
            거래 중지
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto"
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"아바타 거래중지 관리자 확인"}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="admin_id"
              value={stop.admin_id}
              className="text-fields"
              placeholder="admin03"
              disabled
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <input
              type="password"
              name="password"
              className="text-fields"
              value={stop.password}
              onChange={handleChangeStopped}
              placeholder="관리자 비밀번호를 입력해주세요."
              style={{ width: "400px" }}
            />
            {message && <p style={{ color: "red" }}>Password is incorrect</p>}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={() => {
              // stoppedChange(stop, userId)
              //   .then((res) => {
              //     res.error === true
              //       ? setMessage("Password is incorrect")
              //       : deleteAPopulars(userId).then((res) => {
              //           alert(res.message);
              //           renderingFunction();
              //           handleAutoClose();
              //         });
              //   })
              //   .catch((e) => {
              //     console.error(e);
              //   });
            }}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none"
            }}
          >
            거래 중지
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none"
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Loading open={loading} />*/}
    </div>
  );
}
