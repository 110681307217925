import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { FormControlLabel } from "@mui/material";
import Slide from "@mui/material/Slide";
import { addNewnotice } from "../../api/notice";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

import CustomAlert from "../../components/Alerts/CustomAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegistrationOfNotice() {

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAutoClose = () => {
    setOpen(false);
  };

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: "",
      usage: "",
      content: "",
    },
    onSubmit: async (val, { resetForm }) => {
      setOpen(true);
      try {
        const result = await addNewnotice(val);

        if (result && result.status === "success") {
          setAlert({
            type: "success",
            message: "New Notice Added Successfully.",
          });
          setTimeout(() => {
            window.location = "/notice-list";
          }, 500);
        } else {
          setAlert({
            type: "error",
            message: "Error occured Try again Later.",
          });
        }
      } catch (err) {
        setAlert({ type: "error", message: "Error occured Try again Later." });
      }
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Fill title"),
      usage: Yup.string().required("Fill usage"),
      content: Yup.string().required("Fill content"),
    }),
  });

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>공지사항 등록</div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> 공지사항 제목</label> <br />
            <TextField
              variant="outlined"
              id="title"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            {formik.touched.title && formik.errors.title ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.title}
              </small>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> 공지사항 조회수</label> <br />
            <TextField
              variant="outlined"
              id="views"
              name="views"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.views}
              type="number"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            {formik.touched.views && formik.errors.views ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.views}
              </small>
            ) : (
              ""
            )}
          </div>
        </div> */}
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> 공지사항 내용</label> <br />
            <textarea
              id="content"
              name="content"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.content}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                height: "200px",
                boder: "1px solid #000",
                borderRadius: "8px",
              }}
            />
            <br />
            {formik.touched.content && formik.errors.content ? (
              <small className="text-danger" style={{ fontSize: 13 }}>{formik.errors.content}</small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6">
            <FormLabel style={{ color: "#000" }} component="legend">
              사용여부
            </FormLabel>
            <RadioGroup>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="true"
                  name="usage"
                  id="usage"
                  onChange={formik.handleChange}
                  control={<Radio />}
                  label="사용함"
                />
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="false"
                  id="usage"
                  name="usage"
                  onChange={formik.handleChange}
                  control={<Radio />}
                  label="사용안함"
                />
              </div>
            </RadioGroup>
            <br />
            {formik.touched.usage && formik.errors.usage ? (
              <small className="text-danger">{formik.errors.usage}</small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-1">
            <Button
              style={{
                backgroundColor: "#5376FF",
                width: "100px",
                height: "auto",
                outline: "none",
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              저장
            </Button>
          </div>
          <div className="col-lg-1">
            <Link to="/notice-list">
              <Button
                style={{
                  border: "1px solid #5376FF",
                  color: "#5376FF",
                  width: "100px",
                  height: "auto",
                  outline: "none",
                  backgroundColor: "transparent",
                }}
                variant="contained"
                color="primary"
              >
                목록
              </Button>
            </Link>
          </div>
          <div className="col-lg-10"></div>
        </div>
      </form>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
