import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Provider } from "react-redux";

//Middlewares
import store from "./redux/store";

//Pages
import AllRoutes from "./components/Routes/AllRoutes";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AllRoutes />
      </Router>
    </Provider>
  );
}

export default App;
