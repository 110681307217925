import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/TableBody";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Pagination2 from "../../components/Pagination/NFTManagementPagination";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";
import TableToExcel from "react-html-table-to-excel";
import Loading from "../../components/Loading/Loading";
import "../Login/index.css";
import "../../components/Pagination/index.css";
import * as XLSX from "xlsx";

import {
  getAllNFT,
  stoppedChange,
  getNFTManagementList,
  getNFTArtworks,
  getSearchNft,
  getAllNft,
} from "../../api/nft";
import ReactPaginate from "react-paginate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NFTManagement() {
  const [nft, setNFT] = useState([]);
  const useStyles = makeStyles({
    table: {
      minWidth: 300,
      overflowX: "auto",
    },
  });

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [open2, setOpen2] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(14);
  const [historydata, setHistoryData] = useState([]);

  const handleExcelExport = () => {
    const wb = XLSX.utils.table_to_book(document.getElementById("nft-table"));
    XLSX.writeFile(wb, "nft_management_data.xlsx");
  };

  const [serach, setSearch] = useState({
    from: "",
    to: "",
    category: "",
    status: "",
    coin: "",
    option: "",
    text: "",
  });

  // const [admin, setAdmin] = useState([]);
  const [stop, setStop] = useState({
    admin_id: localStorage.getItem("admin"),
    password: "",
  });

  const [nftId, setNftId] = useState();
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState();
  const [pages, setPages] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(0);
  const [totalItems, setTotalItems] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState(1);
  const [image, setImage] = useState();
  const selling = useRef(0);

  const handleClickOpen = (id) => {
    setOpen(true);
    setNftId(id);
  };

  const handleClickOpenHistory = () => {
    setOpen2(true);
  };

  const onclickViewHistory = async (artworkid) => {
    handleClickOpenHistory();
    try {
      getNFTArtworks(artworkid).then((res) => {
        setHistoryData(res.data.data);
      });
    } catch (err) {
      throw err;
    }
  };

  const data = nft;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 100;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(data?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, data]);

  const handleAutoClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  const handlePageClick = (event) => {
    setLoading(true);
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
    getSearchNft(event.selected + 1, serach.text)
      .then((res) => {
        setNFT(res?.data);
        setTotalPages(res?.totalPages);
        setPageCount(res?.totalPages);
        setNewPage(res?.currentPage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPost - postsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...serach, ...{ [name]: value } };
    setSearch(formData);
  };

  const handleChangeStopped = (e) => {
    const { name, value } = e.target;
    let formData = { ...stop, ...{ [name]: value } };
    setStop(formData);
  };

  const handleSearch = () => {
    setLoading(true);
    getSearchNft(1, serach.text)
      .then((res) => {
        setLoading(true);
        setNFT(res.data);
        setItemPerPage(res.itemPerPage);
        setTotalPages(res.totalPages);
        setSize(res.data.length);
        setPages(res.totalPages);
        setPageCount(res.totalPages);
        setTotalItems(res.totalItems);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setPages(5);
    getSearchNft(1, serach.text)
      .then((res) => {
        // setLoading(true);
        setNFT(res?.data);
        setItemPerPage(res?.itemPerPage);
        setTotalPages(res?.totalPages);
        setPageCount(res.totalPages);
        setNewPage(res?.currentPage);
        setSize(res?.data?.length);
        setPages(res?.totalPages);
        if (res?.totalPages !== 0) {
          setPages(res?.totalPages);
        }
        setTotalItems(res?.totalItems);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [serach.text]);

  const clearText = () => {
    setSearch({ ...serach, ...{ text: "", option: "" } });
    setNewPage(1)
  };

  const changeToStop = () => {
    stoppedChange(stop, nftId)
      .then((res) => {
        res.error === true
          ? setMessage("Password is incorrect")
          : window.location.reload();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div className="container w-70 m-0 p-0">
      <div className="mx-5 table-user ">
        <div className="row">
          <div
            className="col-sm-12 col-lg-4 "
            style={{ fontWeight: "700", fontSize: "40px", textAlign: "left" }}
          >
            NFT 목록
          </div>
        </div>
        <div className="row mt-2">
          <div
            className="col-sm-12 col-lg-2 my-2 "
            style={{ float: "left", width: "200px" }}
          >
            <button
              className="excel-download-button"
              onClick={handleExcelExport}
              nftData={nft}
            >
              EXCEL 다운로드
            </button>
          </div>
        </div>

        <div
          className="row align-items-center mt-2"
          style={{ marginLeft: "30%", textAlign: "right" }}
        >
          <div className="col-sm-12 col-lg-3"></div>
          <div className="row d-flex flex-start">
            <div
              className="col-sm-12 col-lg-3 my-2"
              style={{ color: "#000", fontWeight: "700" }}
            >
              등록일 기간 검색
            </div>
            <div className="col-sm-5  col-lg-4 my-2">
              <TextField
                variant="outlined"
                id="date"
                type="date"
                name="from"
                onChange={handleChange}
                label="시작일"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ float: "center" }}
              />
            </div>

            <div className="col-sm-2 col-lg-1 my-2">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#A9ABB0",
                  width: "55px",
                  height: "55px",
                  borderRadius: "3px",
                  textAlign: "center",
                  float: "center",
                }}
              >
                ~
              </div>
            </div>
            <div className="col-sm-5 col-lg-4 my-2">
              <TextField
                variant="outlined"
                id="date"
                type="date"
                label="종료일"
                name="to"
                onChange={handleChange}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ float: "center" }}
              />
            </div>
          </div>
          <div className="row" style={{ marginLeft: "0%", textAlign: "right" }}>
            <div className="col-sm-12 col-lg-3 my-2">
              <TextField
                id="filled-select-currency"
                select
                placeholder="=상태="
                variant="outlined"
                style={{ width: "150px", float: "center", marginRight: "12px" }}
                name="category"
                value={serach.category}
                onChange={handleChange}
                label="=상태="
              >
                <MenuItem value=""> Default </MenuItem>
                <MenuItem value="회원권">Membership</MenuItem>
                <MenuItem value="특허권">Patent</MenuItem>
                <MenuItem value="디자인">Design</MenuItem>
                <MenuItem value="상표권">Trademark</MenuItem>
                <MenuItem value="저작권">Copyright</MenuItem>
              </TextField>
            </div>
            <div className="col-sm-12 col-lg-3 my-2">
              <TextField
                id="filled-select-currency"
                select
                // helperText="Status"
                variant="outlined"
                name="status"
                value={serach.status}
                onChange={handleChange}
                style={{ width: "150px", margin: "0 5px", float: "center" }}
                label="Status"
              >
                <MenuItem value="">Default</MenuItem>
                <MenuItem value="fixed_price">Fixed Price</MenuItem>
                <MenuItem value="auction">Auction</MenuItem>
                <MenuItem value="waiting_for_sale">Waiting for sale</MenuItem>
              </TextField>
            </div>
            <div className="col-sm-12 col-lg-2 my-2">
              <TextField
                id="search-text"
                placeholder="검색옵션을 선택해주세요."
                variant="outlined"
                name="text"
                autoComplete="off"
                value={serach.text}
                onChange={handleChange}
                fullwidth
                label="검색옵션을 선택해주세요."
                style={{ float: "center" }}
              />
            </div>
            <div
              className="col-sm-12 col-lg-2 my-2"
              style={{ marginLeft: "1px" }}
            >
              <Button
                style={{
                  backgroundColor: "#5376FF",
                  color: "#fff",
                  padding: "0 2px",
                  width: "12px",
                  height: "54px",
                }}
              >
                {" "}
                검색{" "}
              </Button>
            </div>
            <div className="col-sm-12 col-lg-1 my-2">
              <Button
                style={{
                  backgroundColor: "#fff",
                  color: "#5376FF",
                  padding: "0 2px",
                  width: "12px",
                  // display:"flex",
                  // justifySelf:"end",
                  float: "center",
                  marginRight: "12px",
                  border: "1px solid #c4c4c4",
                }}
                onClick={() => {
                  clearText();
                }}
              >
                {" "}
                Clear{" "}
              </Button>
            </div>
          </div>
        </div>
        <div style={{ fontWeight: "500" }}>
          Total : {totalItems} Count ({newPage}/{totalPages}) Page
        </div>

        <TableContainer
          component={Paper}
          style={{
            width: "100%",
            overflowX: "auto",
            margin: 0,
          }}
        >
          <Table
            className={classes.table}
            aria-label="simple table"
            id="nft-table"
          >
            <TableHead style={{ backgroundColor: "#E5E5E5" }}>
              <TableRow>
                <TableCell>NO</TableCell>
                <TableCell align="center">PK</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">NFT name(artwork name)</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Stop trading</TableCell>
                <TableCell align="center">Creator</TableCell>
                <TableCell align="center">Owner</TableCell>
                <TableCell align="center">Sales Coin</TableCell>
                <TableCell align="center">Price(current)</TableCell>
                <TableCell align="center">KRW Price</TableCell>
                <TableCell align="center">Sales history</TableCell>
                <TableCell align="center">Registration date</TableCell>
                <TableCell align="center">Edit</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <TableBody>
                {nft &&
                  nft
                    .filter((val) => {
                      if (serach.status == "") {
                        return val;
                      } else if (val.form_of_sale.includes(serach.status)) {
                        return val;
                      }
                    })
                    .filter((val) => {
                      if (serach.from === "") {
                        return val;
                      } else {
                        let from = new Date(serach.from);
                        let to = new Date(serach.to);
                        let value = new Date(val.created_at);

                        if (from < value && to > value) {
                          return val;
                        }
                      }
                    })
                    .filter((val) => {
                      if (serach.category_name === "") {
                        return val;
                      } else if (val.category_name.includes(serach.category)) {
                        return val;
                      }
                    })
                    // .filter((val) => {
                    //   if (serach.text === "") {
                    //     return val;
                    //   } else if (val.current_owner.current_owner_nickname.includes(serach.text)) {
                    //     return val;
                    //   }
                    // })
                    // .filter((val) => {
                    //   if (serach.text === "") {
                    //     return val;
                    //   } else if (val?.title.includes(serach.text)) {
                    //     return val;
                    //   }
                    // })
                    .map((nft, index) => {
                      selling.current = index;
                      return (
                        <TableRow key={nft.art_work_id}>
                          <TableCell component="th" scope="row">
                            {index + 1 + (newPage - 1) * itemPerPage}
                          </TableCell>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {nft.category_name}
                          </TableCell>
                          <TableCell align="center">{nft.title}</TableCell>
                          <TableCell align="center">
                            {nft.form_of_sale}
                          </TableCell>
                          <TableCell align="center">
                            {nft.selling_available === false ? (
                              <Button
                                disabled
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#ff5983",
                                  padding: "8px 16px",
                                  outline: "none",
                                  border: "1px solid #ff5983",
                                }}
                              >
                                거래중지
                              </Button>
                            ) : (
                              <Button
                                onClick={() => {
                                  handleClickOpen(nft.art_work_id);
                                }}
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#bb002f",
                                  fontWeight: "700",
                                  padding: "8px 16px",
                                  outline: "none",
                                  border: "1px solid #bb002f",
                                }}
                              >
                                거래중지
                              </Button>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {nft.creator?.creator_nickname}
                          </TableCell>
                          <TableCell align="center">
                            {nft.current_owner?.current_owner_nickname}
                          </TableCell>
                          <TableCell align="center">
                            {nft.sale_coin === 0 ? "ETH" : "MATIC"}
                          </TableCell>
                          <TableCell align="center">
                            {nft?.sale_price
                              ? nft?.sale_price
                              : nft?.auction_start_price}
                          </TableCell>
                          <TableCell align="center">
                            {nft?.korean_price ? nft?.korean_price : ""}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                onclickViewHistory(nft.art_work_id);
                                console.log(nft?.files[0]);
                                setImage(nft?.files[0]);
                              }}
                              style={{
                                backgroundColor: "#fff",
                                color: "#5376FF",
                                padding: "8px 16px",
                                outline: "none",
                                border: "1px solid #5376FF",
                              }}
                            >
                              View History
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {nft.created_at.split("T")[0]}
                            {"  "}
                            {nft.created_at.split("T")[1].split(".")[0]}
                          </TableCell>
                          <TableCell align="center">
                            <Link to={`/nft-modification/${nft.art_work_id}`}>
                              <Button
                                style={{
                                  backgroundColor: "#fff",
                                  color: "#5376FF",
                                  padding: "8px 16px",
                                  outline: "none",
                                  border: "1px solid #5376FF",
                                }}
                              >
                                Edit
                              </Button>
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <div className="row d-flex flex-wrap justify-content-center mt-5">
          <div className="d-flex justify-content-center" style={{ flex: "8" }}>
            <div style={{ flex: "8" }}>
              <ReactPaginate
                breakLabel=" . . . "
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="active"
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"아바타 거래중지 관리자 확인"}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="admin_id"
              value={stop.admin_id}
              className="text-fields"
              placeholder="admin03"
              disabled
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <input
              type="password"
              name="password"
              className="text-fields"
              value={stop.password}
              onChange={handleChangeStopped}
              placeholder="관리자 비밀번호를 입력해주세요."
              style={{ width: "400px" }}
            />
            {message && <p style={{ color: "red" }}>Password is incorrect</p>}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={changeToStop}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none",
            }}
          >
            거래 중지
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none",
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"WORK HISTORY"}</strong>
        </DialogTitle>

        <DialogContent>
          <div className="d-flex justify-content-center">
            {/* <img
              alt="history"
              src={`${process.env.REACT_APP_IPFS_URL}${image}`}
              style={{ borderRadius: '8px 8px 45px 8px' }}
            /> */}
          </div>
        </DialogContent>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#E5E5E5" }}>
                <TableRow>
                  <TableCell>NO</TableCell>
                  <TableCell align="right">type</TableCell>
                  <TableCell align="right">Seller</TableCell>
                  <TableCell align="right">Buyer</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historydata.map((row, index) => (
                  <TableRow key={row?.name}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="right">{row?.purchase_type}</TableCell>
                    <TableCell align="right">
                      {row?.creator?.creator_nickname}
                    </TableCell>
                    <TableCell align="right">
                      {row?.buyer?.buyer_nickname}
                    </TableCell>
                    <TableCell align="right">{row?.price}</TableCell>
                    <TableCell align="right">{row?.created_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5 mt-2">
          <Button
            fullwidth
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#183B56",
              color: "#fff",
              margin: "0 4%",
            }}
          >
            돌아가기
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Loading open={loading} />*/}
    </div>
  );
}
