import React from "react";

function Page404() {
  return (
    <div style={{ height: "92.5vh" }}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div style={{ display: "block", textAlign: "center" }}>
          <h1>Loading...</h1>
        </div>
      </div>
    </div>
  );
}

export default Page404;
