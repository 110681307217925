import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import Button from "@mui/material/Button";
import { getAnAdmin } from "../../api/admin";
import { editAdmin } from "../../api/admin";
import CustomAlert from "../../components/Alerts/CustomAlert";

import Loading from "../../components/Loading/Loading";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";


export default function MyProfile() {
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleNavigateToList = () => {
    navigate("/admin-list");
  };

  const [admin, setAdmin] = useState({
    admin_id: "",
    name: "",
    mobile: "",
    department: "",
    password: "",
  });

  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getAnAdmin(localStorage.getItem("admin"))
      .then((res) => {
        setAdmin(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...admin, ...{ [name]: value } };
    setAdmin(formData);
    setMessage("");
    setHasChanges(true);
  };

  const handlePhoneChange = (value) => {
    setAdmin((prevAdmin) => ({
      ...prevAdmin,
      mobile: value,
    }));
    setMessage("");
    setHasChanges(true);
  };  

  console.log("Check", hasChanges);
  const formik = useFormik({
    initialValues: {
      department: admin.department || "",
      password: admin.password || "",
      name: admin.name || "",
    },
    enableReinitialize: true,
    onSubmit: async (val, { resetForm }) => {
      setOpen(true);
      console.log("Changes", hasChanges);
      if (!hasChanges) {
        setAlert({ type: "warning", message: "Nothing changed to Save!" });
        setSubmitting(false);
        return;
      }
      setSubmitting(true);

      try {
        const inputValue = {
          mobile: admin.mobile,
          admin_id: admin.admin_id,
          ...val,
        };
        const result = await editAdmin(
          inputValue,
          localStorage.getItem("admin")
        );
        if (result && result.status == "success") {
          setAlert({ type: "success", message: "Successfully updated" });
        } else {
          setAlert({ type: "error", message: "Something went wrong." });
        }
      } catch (err) {
        setAlert({ type: "error", message: "Error occured Try again Later." });
      } finally {
        setSubmitting(false);
      }
    },
    validationSchema: Yup.object({
      department: Yup.string().required("Fill department"),
      password: Yup.string().required("Fill password"),
      name: Yup.string().required("Fill name"),
    }),
  });

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>내 프로필</div>
      <form onSubmit={formik.handleSubmit}>
        <div
          className="mt-5 row"
          style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
        >
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <TextField
              id="admin_id"
              placeholder="아이디를 입력해주세요."
              variant="outlined"
              value={admin.admin_id}
              disabled
              onChange={handleChange}
              fullwidth
              style={{ width: "300px" }}
            />
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 이름</label> <br />
            <TextField
              id="name"
              placeholder="이름을 입력해주세요."
              variant="outlined"
              name="name"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              fullwidth
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.name && formik.errors.name ? (
              <small className="text-danger">{formik.errors.name}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 휴대폰 번호</label> <br />
            <PhoneInput
              specialLabel=""
              country={"us"}
              onChange={handlePhoneChange}
              value={admin.mobile}
              style={{
                borderRadius: "8px",
                backgroundColor: "transparent",
                padding: "0% 0",
                height: "40px",
              }}
            />
          </div>
        </div>

        <div
          className="row mt-5"
          style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
        >
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 담당부서 </label> <br />
            <TextField
              id="department"
              placeholder="영업부"
              variant="outlined"
              name="department"
              fullwidth
              onChange={handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.department}
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.department && formik.errors.department ? (
              <small className="text-danger">{formik.errors.department}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 비밀번호</label> <br />
            <TextField
              id="password"
              variant="outlined"
              fullwidth
              name="password"
              type="password"
              onChange={handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              //value={admin.password}
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.password && formik.errors.password ? (
              <small className="text-danger">{formik.errors.password}</small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className="row mt-5"
          style={{
            fontWeight: "700",
            fontSize: "18px",
            display: "flex",
            width: "100%",
          }}
        >
          <div className="text-danger">{message}</div>
          <div className="col-lg-1" style={{ marginRight: "24px" }}>
            <Button
              type="submit"
              style={{
                border: "1px solid #5376FF",
                backgroundColor: "#5376FF",
                width: "100px",
                height: "auto",
              }}
              variant="contained"
              color="primary"
            >
              저장
            </Button>
          </div>
          <div className="col-lg-1">
            <Button
              style={{
                border: "1px solid #5376FF",
                color: "#5376FF",
                width: "100px",
                height: "auto",
                outline: "none",
                backgroundColor: "transparent",
              }}
              variant="contained"
              color="primary"
              onClick={handleNavigateToList}
            >
              목록
            </Button>
          </div>
          <div className="col-lg-10"></div>
        </div>
      </form>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
      {/*<Loading open={loading} />*/}
    </div>
  );
}
