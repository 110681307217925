import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { allCategory } from "../../api/nft";
import Pagination2 from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";

export default function NFTCategoryList() {
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const classes = useStyles();

  const [postsPerPage, setPostsPerPage] = useState(14);
  
  const [search, setSearch] = useState({
    used: "",
    option: "",
    text: "",
  });
  
  const [category, setCategory] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState();
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setLoading(true);
    allCategory(1)
      .then((res) => {
        setCategory(res.data);
        setSize(res.data.length);
        if (res.count !== 0) {
          setPages(res.count);
        }
        setCount(res.count)
        setCurrentPage(res.currentPage) 
        setTotalPages(res.totalPages) 
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...search, ...{ [name]: value } };
    setSearch(formData);
  };

  const clearText = () => {
    setSearch({ ...search, ...{ text: "", option: "", used: "" } });
  };

  //pagination states

  return (
    <div className="mx-5 table-user">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>
        NFT 카테고리 목록
      </div>

      <div className="row">
        <div className="col-lg-3 col-sm-8 col-md-6">
          <Link to="/nft-category-reg">
            <Button
              style={{ backgroundColor: "#5376FF" }}
              variant="contained"
              color="primary"
            >
              상태 변경
            </Button>
          </Link>
        </div>
      </div>

      <div className="row mt-2" style={{ fontWeight: "700" }}>
        <div className="col-lg-3 col-sm-12">
          <TextField
            id="filled-select-currency"
            select
            name="used"
            placeholder="=상태="
            label="=사용 여부="
            value={search.used}
            variant="outlined"
            onChange={handleChange}
            // helperText="=사용 여부="
            style={{ width: "150px" }}
          >
            <MenuItem value="">Default</MenuItem>
            <MenuItem value="사용함">사용함</MenuItem>
            <MenuItem value="사용안함">사용안함</MenuItem>
          </TextField>
        </div>

        <div className="col-lg-3 col-sm-12">
          <TextField
            id="filled-select-currency"
            select
            name="option"
            value={search.option}
            placeholder="=상태="
            variant="outlined"
            label="검색옵션"
            // helperText="검색옵션"
            onChange={handleChange}
            style={{ width: "150px" }}
          >
            <MenuItem value="">Default</MenuItem>
            <MenuItem value="Category name">Category name</MenuItem>
          </TextField>
        </div>

        <div className="col-lg-3 col-sm-12">
          <TextField
            id="filled-select-currency"
            // placeholder="검색옵션을 선택해주세요."
            label="검색옵션을 선택해주세요."
            variant="outlined"
            fullwidth
            name="text"
            value={search.text}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-2 col-sm-12">
          <Button
            style={{
              backgroundColor: "#5376FF",
              color: "#fff",

              height: "55px",
            }}
          >
            {" "}
            검색{" "}
          </Button>
        </div>
        <div className="col-lg-2 col-sm-12">
          <Button
            onClick={() => {
              clearText();
            }}
            style={{
              backgroundColor: "#fff",
              color: "#5376FF",

              border: "1px solid #c4c4c4",
              height: "54px",
            }}
          >
            {" "}
            Edit{" "}
          </Button>
        </div>
      </div>

      <div style={{ fontWeight: "500" }}>Total : {count} Count ({currentPage}/{totalPages})Page</div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#E5E5E5" }}>
            <TableRow>
              <TableCell>NO</TableCell>
              <TableCell align="center">PK</TableCell>
              <TableCell align="center">Category name</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {category
              ?.filter((val) => {
                if (search.text == "" || search.option == "") {
                  return val;
                } else if (search.option === "Category name") {
                  if (search?.text == "") {
                    return val;
                  } else if (val.category.includes(search?.text)) {
                    return val;
                  }
                }
              })
              .filter((val) => {
                if (search.used == "") {
                  return val;
                } else if (val.usage.includes(search.used)) {
                  return val;
                }
              })
              .map((data, index) => (
                <TableRow key={data?.id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{data?.category}</TableCell>
                  <TableCell align="center">{data.usage}</TableCell>
                  <TableCell align="center">
                    <Link to={`/nft-category-edit/${data?._id}`}>
                      <Button
                        style={{
                          backgroundColor: "#fff",
                          color: "#5376FF",
                          padding: "8px 16px",
                          outline: "none",
                          border: "1px solid #5376FF",
                        }}
                      >
                        Edit
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="d-flex justify-content-center mt-5">
        <div className="d-flex justify-content-center" style={{ flex: "8" }}>
          <div style={{ flex: "8" }}>
            {
              //<Pagination count={30} variant="outlined" shape="rounded" />
            }
            <Pagination2
              pages={pages}
              setAdmin={(category) => setCategory(category)}
              getList={allCategory}
              setSize={(size) => {
                setSize(size);
              }}
              setNewPage={(newPage) => {
                setNewPage(newPage + 1);
              }}
            />
          </div>
        </div>
      </div>
      {/*<Loading open={loading} />*/}
    </div>
  );
}
