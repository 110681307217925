import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { FormControlLabel } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { getANotice, EditANotice } from "../../api/notice";
import Loading from "../../components/Loading/Loading";
import CustomAlert from "../../components/Alerts/CustomAlert";

import * as Yup from "yup";
import { useFormik } from "formik";

export default function EditNotice() {
  const { id } = useParams();

  const [notices, setNotices] = useState({
    title: "",
    usage: "",
    views: null,
    content: ""
  });

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getANotice(id)
      .then((res) => {
        setNotices(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let noticeData = { ...notices, ...{ [name]: value } };
    setNotices(noticeData);
    setMessage("");
  };

  const formik = useFormik({
    initialValues: {
      content: notices?.content || "",
      title: notices?.title || "",
      usage: notices?.usage,
      views: notices?.views || ""
    },
    enableReinitialize: true,
    onSubmit: async (val, { resetForm }) => {
      setOpen(true);
      try {
        const result = await EditANotice(val, id);

        if (result && result.status === "success") {
          setAlert({ type: "success", message: "Saved Successfully." });
          window.location = "/notice-list";
        } else {
          setAlert({
            type: "error",
            message: "Error occured Try again Later."
          });
        }
      } catch (err) {
        setAlert({ type: "error", message: "Error occured Try again Later." });
      }
    },
    validationSchema: Yup.object({
      content: Yup.string().required("Fill name"),
      title: Yup.string().required("Fill Email"),
      usage: Yup.string().required("Fill usage"),
      views: Yup.string().required("Fill views")
    })
  });

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>공지사항 등록</div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> 공지사항 제목</label> <br />
            <TextField
              variant="outlined"
              id="title"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <br />
            {formik.touched.title && formik.errors.title ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.title}
              </small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> 공지사항 조회수</label> <br />
            <TextField
              variant="outlined"
              id="views"
              name="views"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.views}
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <br />
            {formik.touched.views && formik.errors.views ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.views}
              </small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> 공지사항 내용</label> <br />
            <textarea
              id="content"
              name="content"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.content}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                height: "200px",
                boder: "1px solid #000",
                borderRadius: "8px"
              }}
            />
            {formik.touched.content && formik.errors.content ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.content}
              </small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <FormLabel style={{ color: "#000" }} component="legend">
              사용여부
            </FormLabel>
            <RadioGroup
              defaultValue={formik?.values?.usage}
              id="usage"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="사용함"
                  name="usage"
                  control={
                    <Radio checked={formik?.values?.usage === "사용함"} />
                  }
                  label="사용함"
                />
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="사용안함"
                  name="usage"
                  control={
                    <Radio checked={formik?.values?.usage === "사용안함"} />
                  }
                  label="사용안함"
                />
              </div>
            </RadioGroup>
          </div>
        </div>
        <div className="text-danger">{message}</div>
        <div className="row mt-5">
          <div className="col-lg-1">
            <Button
              style={{
                backgroundColor: "#5376FF",
                width: "100px",
                height: "auto",
                outline: "none"
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              저장
            </Button>
          </div>
          <div className="col-lg-1">
            <Link to="/notice-list">
              <Button
                style={{
                  border: "1px solid #5376FF",
                  color: "#5376FF",
                  width: "100px",
                  height: "auto",
                  outline: "none",
                  backgroundColor: "transparent"
                }}
                variant="contained"
                color="primary"
              >
                목록
              </Button>
            </Link>
          </div>
          <div className="col-lg-10"></div>
        </div>
      </form>
      {/*<Loading open={loading} />*/}
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
