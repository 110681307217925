// import package
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

// @material-ui/core
import { FormControlLabel } from "@mui/material";

import Button from "@mui/material/Button";

// import action
import { editAdmin } from "../../api/admin";

// import lib

import TextField from "@mui/material/TextField";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";

import { getAnAdmin } from "./../../api/admin";
import TransferList from "../../components/TransferMenu/TransferMenu";

import Loading from "../../components/Loading/Loading";
import * as Yup from "yup";
import { useFormik } from "formik";

import CustomAlert from "../../components/Alerts/CustomAlert";

const AdminEdit = (props) => {
  // state

  const [message, setMessage] = useState();

  const { id } = useParams();

  const [formValue, setFormValue] = useState({
    name: "",
    mobile: "",
    email: "",
    department: "",
    password: "",
    confirm_password: "",
    status: "",
    notes: "",
  });

  const [menu, setMenu] = useState({
    admin_list: null,
    category_list: null,
    nft_list: null,
    nft_settings: null,
    transaction_history: null,
  });
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ type: "", message: "" });
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    getAnAdmin(id)
      .then((res) => {
        console.log("Res :", res);
        setFormValue(res);
        setLoading(false);
        setMenu(res.menu);
      })
      .catch(() => {
        localStorage.removeItem("token");
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    setMessage("");
  };
  console.log("formValue", formValue);
  const formik = useFormik({
    initialValues: {
      name: formValue?.name || "",
      email: formValue?.email || "",
      mobile: formValue?.mobile || "",
      department: formValue?.department || "",
      password: formValue?.password || "",
      status: formValue?.status,
      notes: formValue?.notes || "",
    },
    enableReinitialize: true,
    onSubmit: async (val, { resetForm }) => {
      setOpen(true);
      try {
        const inputData = { mobile: formValue.mobile, ...val };
        const result = await editAdmin(inputData, id);
        if (result && result.status === "success") {
          setAlert({ type: "success", message: "Saved Successfully." });
          setTimeout(() => {
            window.location.href = "/admin-list";
          }, 500);
        } else {
          setAlert({ type: "error", message: "Email already Exist." });
        }
      } catch (err) {
        setAlert({ type: "error", message: "Error occured Try again Later." });
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Fill name"),
      email: Yup.string().required("Fill Email").email("Fill valid email"),
      department: Yup.string().required("Fill department"),
      password: Yup.string().required("Fill passwiord"),
      status: Yup.string().required("Fill status"),
      notes: Yup.string().required("Fill notes"),
      mobile: Yup.number().required("Fill Mobileno"),
    }),
  });

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "30px" }}>
        관리자 등록 & 수정
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div
          className="mt-5 row"
          style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
        >
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <TextField
              id="email"
              placeholder="아이디를 입력해주세요."
              variant="outlined"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              fullwidth
              inputProps={{ readOnly: true }}
              style={{ width: "300px" }}
            />
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 이름</label> <br />
            <TextField
              id="name"
              placeholder="이름을 입력해주세요."
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              name="name"
              fullwidth
              style={{ width: "300px" }}
            />
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 휴대폰 번호</label> <br />
            <PhoneInput
              specialLabel=""
              country={"us"}
              id="mobile"
              value={formik.values.mobile}
              // onChange={(e) => (formValue.mobile = e)}
              onChange={(e) => formik.setFieldValue("mobile", e)}
              style={{
                borderRadius: "8px",
                backgroundColor: "transparent",
                padding: "0px 4px 0px 4px",
                height: "39px",
                lineHeight: "60px",
              }}
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <small className="text-danger">{formik.errors.mobile}</small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className="row mt-5"
          style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
        >
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 담당부서 </label> <br />
            <TextField
              id="department"
              placeholder="영업부"
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.department}
              name="department"
              fullwidth
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.department && formik.errors.department ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.department}
              </small>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 비밀번호</label> <br />
            <TextField
              id="password"
              variant="outlined"
              fullwidth
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.password && formik.errors.password ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.password}
              </small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4" style={{ width: "300px" }}>
            <FormLabel style={{ color: "#000" }} component="legend">
              상태
            </FormLabel>
            <RadioGroup
              id="status"
              // defaultValue={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  style={{ color: "#000" }}
                  control={
                    <Radio checked={formik?.values?.status === "활성" || formik?.values?.status === "비활성화"} />
                  }
                  value="활성"
                  label="활성"
                  name="status"
                />
                <FormControlLabel
                  style={{ color: "#000" }}
                  control={
                    <Radio checked={formik?.values?.status === "비활성" || formik?.values?.status === "활성화"} />
                  }
                  value="비활성"
                  label="비활성"
                  name="status"
                />
              </div>
            </RadioGroup>
            <br />
            {formik.touched.status && formik.errors.status ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.status}
              </small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className="row mt-5"
          style={{
            fontWeight: "600",
            fontSize: "16px",
            width: "100%",
            display: "flex",
          }}
        >
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> 관리자 담당부서 </label> <br />
            <textarea
              name="notes"
              id="notes"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notes}
              style={{
                height: "100px",
                width: "100%",
                backgroundColor: "transparent",
                borderRadius: "6px",
              }}
            />
            <br />
            {formik.touched.notes && formik.errors.notes ? (
              <small className="text-danger" style={{ fontSize: 13 }}>
                {formik.errors.notes}
              </small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6"></div>
        </div>

        <div className="text-danger">{message}</div>

        <div
          className="row mt-5"
          style={{
            fontWeight: "700",
            fontSize: "18px",
            display: "flex",
            width: "100%",
          }}
        >
          <div className="col-lg-1">
            <Button
              type="submit"
              style={{
                backgroundColor: "#5376FF",
                width: "100px",
                height: "auto",
                outline: "none",
              }}
              variant="contained"
              color="primary"
            >
              저장
            </Button>
          </div>
          <div className="col-lg-1">
            <Link to="/admin-list">
              <Button
                style={{
                  border: "1px solid #5376FF",
                  color: "#5376FF",
                  width: "100px",
                  height: "auto",
                  outline: "none",
                  backgroundColor: "transparent",
                }}
                variant="contained"
                color="primary"
              >
                목록
              </Button>
            </Link>
          </div>
          <div className="col-lg-10"></div>
        </div>
        <CustomAlert
          type={alert.type}
          message={alert.message}
          open={alert.type !== ""}
          // open={open}
          handleClose={handleClose}
        />
        <TransferList menu={menu} id={id} />
      </form>
    </div>
  );
};

export default AdminEdit;
