import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { EditATerm, getAllTerms } from "../../api/term";
import { Link } from "react-router-dom";
import { useQuill } from "react-quilljs";
import CustomAlert from "../../components/Alerts/CustomAlert";
import Loading from "../../components/Loading/Loading";

export default function TCManagement() {
  const [terms, setTerms] = useState();

  const [policy, setPolicy] = useState();

  const [id, setId] = useState();
  const [message, setMessage] = useState("")


  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: "#toolbar"
    },
    formats: ["size", "bold", "script"] // Important
  });


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const termsdata="";
    setLoading(true)
    getAllTerms().then((res) => {
      if (res[0]) {
        setTerms(res[0].service);
        quill.clipboard.dangerouslyPasteHTML(res[0].service)
        setPolicy(res[0].personal);
        setId(res[0].term_id);
      }
    }).finally(() => {
      setLoading(false)
    });

    if (quill) {

      // quill.clipboard.dangerouslyPasteHTML(termsdata);

      quill.on("text-change", (delta, oldDelta, source) => {
        setTerms(quill.root.innerHTML.toString()); // Get innerHTML using quill
      });
    }
  }, [quill]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    let term = { ...terms, ...{ [name]: value } };
    setTerms(term);
  };


  const handleClick = () => {
    setOpen(true)
    if (!terms) {
      setMessage("term is required")
    } else {
      const data = {
        service: terms,
        personal: policy
      };

      EditATerm(data, id).then((res) => {
        res.status === "success" && setAlert({ type: "success", message: "Updated successfully." });
      });
    }

  };


  return (
    <div className="mx-5 mt-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>
        서비스 이용약관 관리
      </div>
      <div className="row mt-5 mb-5">
        <div className="col-lg-6">
          <div style={{ width: 600, height: 300 }}>
            <div id="toolbar">
              <select className="ql-size">
                <option value="small" />
                <option selected />
                <option value="large" />
                <option value="huge" />
              </select>
              <button className="ql-bold" />
              <button className="ql-script" value="sub" />
              <button className="ql-script" value="super" />
            </div>
            <div ref={quillRef}/>
            <div id="editor" />
          </div>
        </div>
      </div>

      <div className="text-danger">{message}</div>

      <div className="row mt-5">
        <div className="col-lg-1">
          <Button
            onClick={handleClick}
            style={{
              backgroundColor: "#5376FF",
              width: "100px",
              height: "42px",
              outline: "none",
              outline: "none"
            }}
            variant="contained"
            color="primary"
          >
            저장
          </Button>
        </div>
        <div className="col-lg-1" style={{marginLeft: "24px"}}>
          <Link to="/admin-list">
            <Button
              style={{
                border: "1px solid #5376FF",
                color: "#5376FF",
                width: "100px",
                height: "42px",
                outline: "none",
                backgroundColor: "transparent"
              }}
              variant="contained"
              color="primary"
            >
              목록
            </Button>
          </Link>
        </div>
        <div className="col-lg-10"></div>
      </div>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
      {/*<Loading open={loading} />*/}
    </div>
  );
}
