import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { FormControlLabel, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import { addCategory, getACategory, editCategory , allNFTCategory} from "../../api/nft";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";

import CustomAlert from "../../components/Alerts/CustomAlert";

export default function NFTCategoryRegistration() {
  const { id } = useParams();

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);
  const [artCategory, setArtCategory] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [category, setCategory] = useState({
    category: "",
    usage: "",
    id:""
  });

  const [message, setMessage] = useState("");

  useEffect(async() => {
    await getACategory(id).then((res) => {
      setCategory(res);
    });
    let category = await allNFTCategory();
    setArtCategory(category.data);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...category, ...{ [name]: value } };
    setCategory(formData);
    setMessage("");
  };

  const handleubmit = () => {
    setOpen(true);
    if (!category.category) {
      setMessage("Fill category");
    } else if (!category.usage) {
      setMessage("Fill usage");
    } else {
      editCategory(category, id).then(() => {
        setAlert({ type: "success", message: "Updated successfully." });
        setTimeout(() => {
          window.location="/nft-categories"
        }, 500);
      });
    }
  };

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>
        NFT 수정 (카테고리 정보만 변경 가능합니다.)
      </div>
      <div className="row mt-5">
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 카테고리명</label> <br />
          <Select
            name="category"
            fullWidth
            defaultValue="Webtoon"
            onChange={handleChange}
            disabled={true}
            value={category.category ? category.category : "Webtoon"}
            helperText="=상태="
            InputLabelProps={{
              shrink: true
            }}
            style={{ width: "150px", margin: "0 5px" }}
          >
            {
              artCategory?.map(category => {
                return <MenuItem value={category.category_name}> {category.category_name}</MenuItem>
              })
            }
          </Select>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-4">
          <FormLabel style={{ color: "#000" }} component="legend">
            사용여부
          </FormLabel>
          <RadioGroup
            defaultValue={category.usage}
            id="usage"
            onChange={handleChange}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                style={{ color: "#000" }}
                value="사용함"
                name="usage"
                control={<Radio checked={category.usage === "사용함"} />}
                label="사용함"
              />
              <FormControlLabel
                style={{ color: "#000" }}
                value="사용안함"
                name="usage"
                control={<Radio checked={category.usage === "사용안함"} />}
                label="사용안함"
              />
            </div>
          </RadioGroup>
        </div>
      </div>

      <div className="text-danger">{message}</div>

      <div className="row mt-5">
        <div className="col-lg-1">
          <Button
            style={{
              backgroundColor: "#5376FF",
              width: "100px",
              height: "auto",
              outline: "none"
            }}
            variant="contained"
            color="primary"
            onClick={handleubmit}
          >
            저장
          </Button>
        </div>
        <div className="col-lg-1">
          <Link to="/nft-categories">
            <Button
              style={{
                border: "1px solid #5376FF",
                color: "#5376FF",
                width: "100px",
                height: "auto",
                outline: "none",
                backgroundColor: "transparent"
              }}
              variant="contained"
              color="primary"
            >
              목록
            </Button>
          </Link>
        </div>
        <div className="col-lg-10"></div>
      </div>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
