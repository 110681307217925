import React, { useEffect, useState } from "react";
import "../../pages/Login/index.css";

function Pagination({
  pages,
  setAdmin,
  getList,
  setLoading,
  setSize,
  setNewPage,
  search,
}) {
  const [clicked, setClicked] = useState(0);
  const [current, setCurrent] = useState(0);
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(4);
  const numPagesToShow = 5;
  const startPageNum = Math.max(
    1,
    Math.min(
      clicked - Math.floor(numPagesToShow / 2),
      pages - numPagesToShow + 1
    )
  );

  useEffect(() => {
    setNewPage(current);
  }, [current, setNewPage]);

  const handlePageClickButton = (pageNum) => {
    setLoading(true);
    getList(pageNum + 1, search).then((res) => {
      setAdmin(res?.data);
      setClicked(pageNum);
      setCurrent(pageNum);
      setSize(res?.data?.length);
      setLoading(false);
    });
  };

  const handlePageClickNumbers = (pageNum) => {
    setLoading(true);
    getList(pageNum, search).then((res) => {
      setAdmin(res?.data);
      setClicked(pageNum - 1);
      setCurrent(pageNum - 1);
      setSize(res.data.length);
      setLoading(false);
    });
  };

  useEffect(() => {
    console.log("pages", pages);
    if (pages > 5) {
      if (clicked < startPage) {
        setStartPage(clicked);
        setEndPage(clicked + 4);
      } else if (clicked > endPage) {
        setEndPage(clicked);
        setStartPage(clicked);
      }
    }
  }, [clicked, pages, startPage, endPage]);

  return (
    <div>
      <nav>
        <ul className="d-flex justify-content-center">
          <div
            className="pagination-item"
            onClick={() => handlePageClickButton(0)}
          >
            First
          </div>
          <div
            className="pagination-item"
            style={{ width: "74px", textAlign: "center" }}
            onClick={() => {
              if (clicked > 0) {
                handlePageClickButton(clicked - 1);
              }
            }}
          >
            Previous
          </div>
          {Array.from(Array(Math.min(endPage - startPage + 1)), (e, i) => {
            const pageNum = startPage + i + 1;
            const maxPageNum = Math.min(pageNum, pages);
            const isLastPage = pages - pageNum <= 4;
            return (
              <div
                className={
                  pageNum === clicked + 1
                    ? "pagination-item-active"
                    : "pagination-item"
                }
                style={{ width: "32px", textAlign: "center" }}
                key={i}
                tabIndex={i}
                onClick={() => handlePageClickNumbers(pageNum)}
              >
                {pages === maxPageNum ? maxPageNum : pageNum}
              </div>
            );
          })}
          <div
            className="pagination-item"
            style={{ width: "74px", textAlign: "center" }}
            onClick={() => {
              if (clicked < pages - 1) {
                handlePageClickButton(clicked + 1);
              }
            }}
          >
            Next
          </div>
          <div
            className="pagination-item"
            onClick={() => handlePageClickButton(pages - 1)}
          >
            Last
          </div>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
