import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Pagination2 from "../../components/Pagination/Pagination";
import * as XLSX from "xlsx";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import "../Login/index.css";
import Loading from "../../components/Loading/Loading";

import historyImage from "../../images/item_3.png";
import { getNFTManagementList, getSearchNft } from "../../api/nft";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NFTTransaction() {
  const [nft, setNFT] = useState([]);

  // function createData(name, calories, fat, carbs, protein) {
  //   return { name, calories, fat, carbs, protein };
  // }

  // const rows = [
  //   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  //   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  //   createData("Eclair", 262, 16.0, 24, 6.0),
  //   createData("Cupcake", 305, 3.7, 67, 4.3),
  //   createData("Gingerbread", 356, 16.0, 49, 3.9),
  // ];

  const useStyles = makeStyles({
    table: {
      minWidth: 300,
      overflowX: "auto",
    },
    // container: {
    //   overflowX: "unset", // Remove the overflowX style
    // },
  });

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [open2, setOpen2] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(14);
  const [itemPerPage, setItemPerPage] = useState(0);
  const [totalItems, setTotalItems] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleExcelExport = () => {
    const wb = XLSX.utils.table_to_book(document.getElementById("nft-table"));
    XLSX.writeFile(wb, "nft_data.xlsx");
  };

  const [serach, setSearch] = useState({
    from: "",
    to: "",
    category: "",
    option: "",
    text: "",
    status: "",
  });

  // const [admin, setAdmin] = useState([]);
  const [stop, setStop] = useState({
    admin_id: localStorage.getItem("admin"),
    password: "",
  });

  const [nftId, setNftId] = useState();

  const [message, setMessage] = useState();
  const page = 1;
  const limit = 20;

  /*useEffect(() => {
    getAllNFT().then((data) => {
      
      //setNFT(data);
    });
  }, []);
*/
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    getSearchNft(serach.text, 1)
      .then((res) => {
        setNFT(res.data);
        setItemPerPage(res.itemPerPage);
        setTotalPages(res.totalPages);
        setSize(res.data.length);
        setPages(res.totalPages);
        setTotalItems(res.totalItems);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log("Search Text", serach);
  }, []);

  const handleClickOpen = (id) => {
    setOpen(true);
    setNftId(id);
  };

  const handleClickOpenHistory = () => {
    setOpen2(true);
  };

  const handleAutoClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPost - postsPerPage;
  //const currentPosts = nft.slice(indexOfFirstPage, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...serach, ...{ [name]: value } };

    if (name === "text" && value === "") {
      formData.option = ""; 
    } else if (name === "option" && value === "") {
      formData.text = ""; 
    }

    setSearch(formData);
  };

  useEffect(() => {
    if (serach.text != "") {
      setLoading(true);
      getSearchNft(serach.text, 1)
        .then((res) => {
          setNFT(res.data);
          setItemPerPage(res.itemPerPage);
          setTotalPages(res.totalPages);
          setSize(res.data.length);
          setPages(res.totalPages);
          setTotalItems(res.totalItems);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [serach.text]);

  const handleChangeStopped = (e) => {
    const { name, value } = e.target;
    let formData = { ...stop, ...{ [name]: value } };
    setStop(formData);
  };

  const clearText = () => {
    setSearch({ ...serach, ...{ text: "", option: "" } });
  };

  const changeToStop = () => {
    // stoppedChange(stop, nftId)
    //   .then((res) => {
    //     res.error === true
    //       ? setMessage("Password is incorrect")
    //       : window.location.reload();
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //   });
  };

  const [pages, setPages] = useState(1);
  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState();

  const ExportableTable = ({ nftData }) => {
    return (
      <table id="nft-table">
        <thead>
          <tr>
            <th>NO</th>
            <th>PK</th>
            <th>Category</th>
            <th>NFT name(artwork name)</th>
            <th>Creator</th>
            <th>Status</th>
            <th>Owner</th>
            <th>Sales Coin</th>
            <th>Price(current)</th>
            <th>Registration date</th>
          </tr>
        </thead>
        <tbody>
          {nftData.map((nft, index) => (
            <tr key={nft.art_work_id}>
              <td>{index + 1}</td>
              <td>{index + 1}</td>
              <td>{nft.category_name}</td>
              <td>{nft.title}</td>
              <td>{nft.creator.creator_nickname}</td>
              <td>{nft.form_of_sale}</td>
              <td>{nft.current_owner.current_owner_email}</td>
              <td>{nft.coin_name}</td>
              <td>{nft.sale_price}</td>
              <td>
                {nft.created_at.split("T")[0]}
                {"  "}
                {nft.created_at.split("T")[1].split(".")[0]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <div className="mx-5 table-user">
        <div style={{ fontWeight: "700", fontSize: "40px" }}>NFT 거래내역</div>

        <div className="row ">
          <div className="col-sm-3 col-md-3 col-lg-3 w-60">
            <button
              className="excel-download-button"
              onClick={handleExcelExport}
              nftData={nft}
            >
              EXCEL 다운로드
            </button>
          </div>
        </div>
        {/* <ExportableTable nftData={nft} /> */}
        <div className="row mt-4" style={{ fontWeight: "700" }}>
          <div
            className="col-sm-12 col-lg-3 my-1"
            style={{
              color: "#000",
              fontWeight: "700",
            }}
          >
            등록일 기간 검색
          </div>
          <div className="col-sm-12 col-lg-3 my-1">
            <TextField
              variant="outlined"
              id="date"
              type="date"
              name="from"
              onChange={handleChange}
              label="시작일"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>

          <div className="col-sm-10 col-lg-1 my-1 text-center">
            <div
              style={{
                backgroundColor: "#A9ABB0",
                width: "50px",
                height: "52px",
                borderRadius: "3px",
              }}
            >
              ~
            </div>
          </div>

          <div className="col-sm-12 col-lg-3 my-1 ">
            <TextField
              variant="outlined"
              id="date"
              type="date"
              label="종료일"
              name="to"
              onChange={handleChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>

        <div className="row" style={{ color: "#000", fontWeight: "700" }}>
          <div className="col-sm-12 col-lg-3 my-1 ">
            <TextField
              id="filled-select-currency"
              select
              placeholder="=상태="
              label="=상태="
              variant="outlined"
              style={{ width: "150px", margin: "0 0px" }}
              name="category"
              value={serach.category}
              onChange={handleChange}
            >
              <MenuItem value=""> Default </MenuItem>
              <MenuItem value="Game">Game</MenuItem>
              <MenuItem value="Art">ART</MenuItem>
              <MenuItem value="Picture">Picture</MenuItem>
              <MenuItem value="Trading card">Trading card</MenuItem>
              <MenuItem value="Music">Music</MenuItem>
              <MenuItem value="Domain name">Domain name</MenuItem>
              <MenuItem value="Meme">Meme</MenuItem>
              <MenuItem value="Collectibles">Collectibles</MenuItem>
            </TextField>
          </div>
          <div className="col-sm-12 col-lg-3 my-1 ">
            <TextField
              id="filled-select-currency"
              select
              placeholder="=상태="
              variant="outlined"
              onChange={handleChange}
              name="status"
              value={serach.status}
              style={{ width: "150px" }}
              label="=상태="
            >
              <MenuItem value="">=상태=</MenuItem>
              <MenuItem value="fixed_price">고정 가격</MenuItem>
              <MenuItem value="auction">경매</MenuItem>
              <MenuItem value="waiting_for_sale">판매 대기 중</MenuItem>
            </TextField>
          </div>
          <div className="col-sm-12 col-lg-3 my-1 ">
            <TextField
              id="filled-select-currency"
              select
              placeholder="=상태="
              variant="outlined"
              onChange={handleChange}
              name="option"
              value={serach.option}
              style={{ width: "150px" }}
              label="=상태="
            >
              <MenuItem>=검색옵션=</MenuItem>
              <MenuItem value="owner">소유자</MenuItem>
              <MenuItem value="nftName">NFT 이름</MenuItem>
            </TextField>
          </div>

          <div className="col-sm-12 col-lg-3 my-1 ">
            <TextField
              id="filled-select-currency"
              placeholder="검색옵션을 선택해주세요."
              variant="outlined"
              name="text"
              value={serach.text}
              onChange={handleChange}
              fullwidth
              label="검색옵션을 선택해주세요."
              autocomplete="off"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-3 my-1 ">
            <Button
              style={{
                backgroundColor: "#5376FF",
                color: "#fff",
                padding: "0 2px",
                height: "54px",
                float: "left",
              }}
            >
              {" "}
              검색{" "}
            </Button>
          </div>
          <div className="col-sm-12 col-lg-3 my-1 ">
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#5376FF",
                padding: "0 2px",
                height: "52px",
                border: "1px solid #c4c4c4",
              }}
              onClick={() => {
                clearText();
              }}
            >
              {" "}
              Edit{" "}
            </Button>
          </div>
        </div>

        <div style={{ fontWeight: "500" }}>
          Total : {totalItems} Count ({newPage}/{totalPages}) Page
        </div>

        <TableContainer
          component={Paper}
          style={{
            width: "100%",
            overflowX: "auto",
            margin: 0,
          }}
        >
          <Table
            className={classes.table}
            aria-label="simple table"
            id="nft-table"
          >
            <TableHead style={{ backgroundColor: "#E5E5E5" }}>
              <TableRow>
                <TableCell>NO</TableCell>
                <TableCell align="center">PK</TableCell>
                <TableCell align="center">Category</TableCell>
                <TableCell align="center">NFT name(artwork name)</TableCell>

                <TableCell align="center">Creator</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Owner</TableCell>
                <TableCell align="center">Sales Coin</TableCell>
                <TableCell align="center">Price(current)</TableCell>
                <TableCell align="center">Registration date</TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress style={{marginLeft: '500px'}} />
              </div>
            ) : (
              <TableBody>
                {console.log("nft :", nft)}
                {nft &&
                  nft
                    // .filter((val) => {
                    //   if (serach.status == "") {
                    //     return val;
                    //   } else if (val.form_of_sale.includes(serach.status)) {
                    //     return val;
                    //   }
                    // })
                    // .filter((val) => {
                    //   if (serach.from === "") {
                    //     return val;
                    //   } else {
                    //     let from = new Date(serach.from);
                    //     let to = new Date(serach.to);
                    //     let value = new Date(val.created_at);

                    //     if (from < value && to > value) {
                    //       return val;
                    //     }
                    //   }
                    // })
                    // .filter((val) => {
                    //   if (serach.category_name === "") {
                    //     return val;
                    //   } else if (val.category_name.includes(serach.category)) {
                    //     return val;
                    //   }
                    // })
                    .filter((val) => {
                      if (serach.text == "" || serach.option == "" ) {
                        return val;
                      } else if (serach.option === "owner") {
                        if (serach.text === "") {
                          return val;
                        } else if (val.current_owner.current_owner_nickname.includes(serach.text)) {
                          return val;
                        }
                      } else if (serach.option === "nftName") {
                        if (serach.text === "") {
                          return val;
                        } else if (val.title.includes(serach.text)) {
                          return val;
                        }
                      }
                    })
                    .map((nft, index) => (
                      <TableRow key={nft.art_work_id}>
                        <TableCell component="th" scope="row">
                          {index + 1 + (newPage - 1) * itemPerPage}
                        </TableCell>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">
                          {nft.category_name}
                        </TableCell>
                        <TableCell align="center">{nft.title}</TableCell>
                        <TableCell align="center">
                          {nft.creator.creator_nickname}
                        </TableCell>
                        <TableCell align="center">{nft.form_of_sale}</TableCell>
                        <TableCell align="center">
                          {nft.current_owner.current_owner_nickname}
                        </TableCell>
                        <TableCell align="center">
                          {nft.sale_coin === 0 ? "ETH" : "MATIC"}
                        </TableCell>
                        <TableCell align="center">
                          {nft?.sale_price
                            ? nft?.sale_price
                            : nft?.auction_start_price}
                        </TableCell>
                        <TableCell align="center">
                          {nft?.created_at.split("T")[0]}
                          {"  "}
                          {nft?.created_at.split("T")[1].split(".")[0]}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <div className="row">
          <div className="col-lg-6 col-sm-12" style={{ flex: "8" }}>
            <div style={{ flex: "8" }}>
              {
                //<Pagination count={30} variant="outlined" shape="rounded" />
              }
              <Pagination2
                search={serach.text}
                pages={pages}
                setLoading={(loading) => setLoading(loading)}
                setAdmin={(admin) => setNFT(admin)}
                getList={getSearchNft}
                setSize={(size) => {
                  setSize(size);
                }}
                setNewPage={(newPage) => {
                  setNewPage(newPage + 1);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"아바타 거래중지 관리자 확인"}</strong>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="admin_id"
              value={stop.admin_id}
              className="text-fields"
              placeholder="admin03"
              disabled
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <input
              type="password"
              name="password"
              className="text-fields"
              value={stop.password}
              onChange={handleChangeStopped}
              placeholder="관리자 비밀번호를 입력해주세요."
              style={{ width: "400px" }}
            />
            {message && <p style={{ color: "red" }}>Password is incorrect</p>}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={changeToStop}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none",
            }}
          >
            거래 중지
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none",
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"WORK HISTORY"}</strong>
        </DialogTitle>

        <DialogContent>
          <div className="d-flex justify-content-center">
            <img
              alt="history"
              src={historyImage}
              style={{ borderRadius: "8px 8px 45px 8px" }}
            />
          </div>
        </DialogContent>

        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#E5E5E5" }}>
                <TableRow>
                  <TableCell>NO</TableCell>
                  <TableCell align="right">type</TableCell>
                  <TableCell align="right">Seller</TableCell>
                  <TableCell align="right">Buyer</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5 mt-2">
          <Button
            fullwidth
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#183B56",
              color: "#fff",
              margin: "0 4%",
            }}
          >
            돌아가기
          </Button>
        </DialogActions>
      </Dialog> */}
      {/*<Loading open={loading} />*/}
    </div>
  );
}
