import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import "react-phone-input-2/lib/material.css";
import Button from "@mui/material/Button";
import { updateUserState } from "../../api/users";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useState } from "react";
import CustomAlert from "../../components/Alerts/CustomAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Expansion({ data, id }) {
  const [open, setOpen] = React.useState(false);

  const admin_id = localStorage.getItem("admin");

  const [application, setApplication] = React.useState({
    admin_id: admin_id,
    password: "",
    comments: "",
  });

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open2, setOpen2] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const [state, setState] = React.useState();

  const handleClickOpen = (e) => {
    setOpen(true);
    // e.preventDefault();
  };

  const handleAutoClose = () => {
    setOpen(false);
  };
  const [errMsg, _errMsg] = useState("");
  const [message, setMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...application, ...{ [name]: value } };
    _errMsg("");
    setMessage(false);
    setApplication(formData);
  };

  return (
    <div>
      {data?.map((data, index) => {
        return (
          <Accordion key={Math.random()}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Application {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form //</AccordionDetails>onSubmit={handleSubmit}
              >
                <div
                  className="mt-5 row"
                  style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
                >
                  <div className="col-lg-4">
                    <label style={{ color: "#000" }}> Name</label> <br />
                    <TextField
                      id="filled-select-currency"
                      variant="standard"
                      name="name"
                      value={data.name}
                      fullwidth
                      disabled
                      inputProps={{ readOnly: true }}
                      style={{ width: "300px" }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label style={{ color: "#000" }}> ID type</label> <br />
                    {data.id_type === "0" ? (
                      <TextField
                        id="filled-select-currency"
                        variant="standard"
                        value="Registration Card"
                        name="name"
                        disabled
                        //onChange={handleChange}
                        fullwidth
                        style={{ width: "300px" }}
                      />
                    ) : data.id_type === "1" ? (
                      <TextField
                        id="filled-select-currency"
                        variant="standard"
                        value="Drivers License"
                        name="name"
                        disabled
                        //onChange={handleChange}
                        fullwidth
                        style={{ width: "300px" }}
                      />
                    ) : data.id_type === "2" ? (
                      <TextField
                        id="filled-select-currency"
                        variant="standard"
                        value="Passport"
                        name="name"
                        disabled
                        //onChange={handleChange}
                        fullwidth
                        style={{ width: "300px" }}
                      />
                    ) : (
                      <TextField
                        id="filled-select-currency"
                        variant="standard"
                        value="No any registered type"
                        name="name"
                        disabled
                        //onChange={handleChange}
                        fullwidth
                        style={{ width: "300px" }}
                      />
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label style={{ color: "#000" }}> Contact</label> <br />
                    <TextField
                      id="filled-select-currency"
                      variant="standard"
                      name="contact"
                      value={data.contact}
                      fullwidth
                      disabled
                      inputProps={{ readOnly: true }}
                      style={{ width: "300px" }}
                    />
                  </div>
                </div>

                <div
                  className="row mt-5"
                  style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
                >
                  <div className="col-lg-4">
                    <label style={{ color: "#000" }}>Passport/ID number</label>
                    <br />
                    <TextField
                      id="filled-select-currency"
                      variant="standard"
                      name="department"
                      value={data?.id_arc}
                      disabled
                      fullwidth
                      style={{ width: "300px" }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label style={{ color: "#000" }}>Date of birth</label>{" "}
                    <br />
                    <TextField
                      id="filled-select-currency"
                      variant="standard"
                      fullwidth
                      value={data.date_of_birth}
                      name="date_of_birth"
                      disabled
                      //onChange={handleChange}
                      style={{ width: "300px" }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label style={{ color: "#000" }}> Country or region</label>{" "}
                    <br />
                    <TextField
                      id="filled-select-currency"
                      variant="standard"
                      fullwidth
                      name="country"
                      value={data.country}
                      disabled
                      //onChange={handleChange}
                      style={{ width: "300px" }}
                    />
                  </div>
                </div>

                <div
                  className="mt-5 row"
                  style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
                >
                  <div className="col-lg-4">
                    <label style={{ color: "#000" }}> Gender</label> <br />
                    <TextField
                      id="filled-select-currency"
                      variant="standard"
                      name="gender"
                      value={data.gender}
                      fullwidth
                      disabled
                      inputProps={{ readOnly: true }}
                      style={{ width: "300px" }}
                    />
                  </div>
                </div>

                <div
                  className="row mt-5"
                  style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
                >
                  <label style={{ color: "#000" }}> ID picture</label> <br />
                  <div
                    className="col-lg-8"
                    style={{
                      border: "2px solid #000",
                      height: "auto",
                      borderRadius: "12px",
                    }}
                  >
                    <div className="row py-4">
                      <div
                        className="col-lg-6"
                        style={{
                          textAlign: "center",
                          color: "#808080",
                          objectFit: "contain",
                        }}
                      >
                        <img
                          src={data.id_image_front}
                          alt="id-front"
                          style={{ maxWidth: "100%" }}
                        />
                        <br />
                        <span>ID picture (front)</span>
                      </div>
                      <div
                        className="col-lg-6"
                        style={{
                          textAlign: "center",
                          color: "#808080",
                          objectFit: "contain",
                        }}
                      >
                        <img
                          src={data.id_image_back}
                          alt="id-back"
                          style={{ maxWidth: "100%" }}
                        />
                        <br />
                        <span>ID picture (back)</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 d-flex"
                    style={{
                      position: "absolute",
                      bottom: "60px",
                      right: "10px",
                    }}
                  >
                    <div style={{ flex: "2" }}></div>
                    <div style={{ flex: "1" }}>
                      <Button
                        type="button"
                        onClick={() => {
                          handleClickOpen();
                          setState("verified");
                        }}
                        style={{
                          backgroundColor: "#5376FF",
                          width: "100px",
                          height: "auto",
                          outline: "none",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Approval
                      </Button>
                    </div>
                    <div style={{ flex: "1" }}>
                      <Button
                        onClick={() => {
                          handleClickOpen();
                          setState("unverified");
                        }}
                        style={{
                          border: "1px solid #5376FF",
                          color: "#5376FF",
                          width: "140px",
                          height: "auto",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Re-request
                      </Button>
                    </div>
                    <div style={{ flex: "1" }}>
                      <Button
                        onClick={() => {
                          handleClickOpen();
                          setState("pending");
                        }}
                        style={{
                          border: "1px solid #5376FF",
                          color: "#5376FF",
                          width: "140px",
                          height: "auto",
                          outline: "none",
                          backgroundColor: "transparent",
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Pending
                      </Button>
                    </div>
                  </div>
                </div>

                <div
                  className="row mt-5"
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div className="col-lg-10"></div>
                </div>
              </form>
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title" className="mt-3 text-center">
          <h2>Approved by KYC manager</h2>
          <p
            className="font-weight-light mx-5 mt-3 mb-5"
            style={{ fontSize: "16px", color: "#7E99AE" }}
          >
            If you approve KYC, you need the administrator password. Please
            enter it and proceed.
          </p>
        </DialogTitle>

        <DialogContent className="mx-4">
          <DialogContentText>
            <label style={{ color: "#000" }}> Password</label> <br />
            <input
              type="password"
              name="password"
              className="text-fields"
              value={application.password}
              placeholder="Please enter the administrator password."
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </DialogContentText>
          <p style={{ color: "red" }}>{errMsg}</p>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={() => {
              if (!application.password) {
                _errMsg("Fill password");
              } else {
                setOpen2(true);
                updateUserState(application, id, state).then((res) => {
                  if (res.error === false) {
                    setAlert({
                      type: "success",
                      message: res.message,
                    });
                    setOpen(false);
                    window.location = "/unverified-user-list";
                  } else {
                    setAlert({
                      type: "error",
                      message: "Password is incorrect",
                    });
                  }
                });
              }
            }}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none",
            }}
          >
            confirm
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none",
            }}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open2}
        handleClose={handleClose}
      />
    </div>
  );
}
