import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Loading from "../../components/Loading/Loading";

import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";

import { getAllAdmin } from "./../../api/admin";

import Button from "@mui/material/Button";

import { Link } from "react-router-dom";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";

import Pagination2 from "../../components/Pagination/Pagination";

import "./style.css";
import { height } from "@mui/system";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    //marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function AdminList() {
  const classes = useStyles();

  const [admin, setAdmin] = useState();
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(0);
  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState();

  const [serach, setSearch] = useState({
    from: "",
    to: "",
    status: "",
    option: "",
    text: "",
  });


  useEffect(() => {
    setLoading(true);
    getAllAdmin(1)
      .then((res) => {
        setLoading(false);
        setAdmin(res.data);
        setSize(res.data.length);
        setPages(res?.totalPages);
        setCount(res.count);
        setItemPerPage(res.itemPerPage);
        setTotalPages(res.totalPages);
        // if (res.count !== 0) {
        //   setPages(res.count);
        // }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAutoClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...serach, ...{ [name]: value } };
    setSearch(formData);
  };

  const clearText = () => {
    setSearch({ ...serach, ...{ text: "", option: "" } });
  };

  return (
    <div className="mx-5 mt-5">
      <div className="title">관리자 목록</div>

      <div>
        <div className="add-button row">
          <div className="col-sm-12 col-lg-2">
            <Link to="/admin-add">
              <Button
                style={{
                  backgroundColor: "#5376FF",
                  outline: "none",
                  borderRadius: "6px",
                  width: "100px",
                }}
                variant="contained"
                color="primary"
              >
                등록
              </Button>
            </Link>
          </div>
          <div className="col-lg-8"></div>
        </div>

        <div
          className="align-items-center row"
          style={{ fontWeight: "700", display: "flex" }}
        >
          <div
            className="col-sm-12 col-lg-6"
            style={{ flex: "7", margin: "0 10px" }}
          ></div>
          <div
            className="col-sm-12 col-lg-2"
            style={{ color: "#000", fontWeight: "700" }}
          >
            등록일 기간 검색
          </div>
          <div className="col-sm-12 col-lg-2 my-2">
            <TextField
              variant="outlined"
              id="date"
              type="date"
              label="시작일"
              name="from"
              fullWidth
              className="text-box"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-sm-12 col-lg-1 d-flex justify-content-center">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "#A9ABB0",
                width: "55px",
                height: "55px",
                borderRadius: "3px",
              }}
            >
              ~
            </div>
          </div>
          <div className="col-sm-12 col-lg-2 my-3">
            <TextField
              variant="outlined"
              id="date"
              type="date"
              label="종료일"
              name="to"
              fullWidth
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>

        <div
          className="align-items-center mt-2 row"
          style={{ fontWeight: "700", display: "flex", justifyContent: "flex-end", alignItems: "center"}}
        >
          <div className="col-sm-12 col-lg-4" ></div>
          <div
            className="col-sm-12 col-lg-3"
            style={{ color: "#000", fontWeight: "700", display: "flex", alignItems: "center"}}
          >
            <TextField
              id="filled-select-currency"
              select
              label="=상태="
              name="status"
              fullWidth
              onChange={handleChange}
              value={serach.status}
              variant="outlined"
              // helperText="=상태="
              style={{ width: "150px", margin: "0 5px" }}
            >
              <MenuItem value="">Default</MenuItem>
              <MenuItem value="활성 ">활성</MenuItem>
              <MenuItem value="비활성">비활성</MenuItem>
            </TextField>

            <TextField
              id="filled-select-currency"
              select
              label="=검색옵션"
              value={serach.option}
              variant="outlined"
              // helperText=" =검색옵션="
              name="option"
              fullWidth
              onChange={handleChange}
              style={{ width: "150px" }}
            >
              <MenuItem value="">Default</MenuItem>
              <MenuItem value="아이디">아이디</MenuItem>
              <MenuItem value="이름">이름</MenuItem>
            </TextField>
          </div>
          <div className="col-sm-12 col-lg-3 d-flex justify-content-end" style={{marginRight: "0", paddingRight: "0"}}>
            <TextField
              id="filled-select-currency"
              placeholder="검색옵션을 선택해주세요."
              label="검색옵션을 선택해주세요."
              variant="outlined"
              name="text"
              value={serach.text}
              onChange={handleChange}
              fullwidth
            />
          </div>
          <div className="col-sm-12 col-lg-1" style={{ paddingLeft: "4px"}} >
            <Button
              //onClick={handleClickOpen}
              style={{
                backgroundColor: "#5376FF",
                color: "#fff",
                padding: "0 px",
                outline: "none",
                borderRadius: "6px",
                height: "53px"
                // MozBorderRadiusTopright: "6px",
                // MozBorderRadiusBottomright: "6px",
              }}
            >
              검색
            </Button>
          </div>
        </div>

        <div style={{ fontWeight: "500" }}>
          Total {count}: Count ({newPage}/{totalPages})Page
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#E5E5E5" }}>
            <TableRow>
              <TableCell>NO</TableCell>
              <TableCell align="left">PK</TableCell>
              <TableCell align="left">아이디</TableCell>
              <TableCell align="left">이름</TableCell>
              <TableCell align="left">담당부서</TableCell>
              <TableCell align="left">상태</TableCell>
              <TableCell align="left">등록일시</TableCell>
              <TableCell align="left">수정</TableCell>
            </TableRow>
          </TableHead>
          {loading ? 
            <div style={{ position: 'relative', height: '50vh' }}>
            <div style={{ position: 'absolute', top: '50%', left: '500px', transform: 'translate(-50%, -50%)' }}>
              <CircularProgress />
            </div>
          </div>
          :
          <TableBody>
            {admin &&
              admin
                .filter((val) => {
                  if (serach.from === "") {
                    return val;
                  } else {
                    let from = new Date(serach.from);
                    let to = new Date(serach.to);     
                    let value = new Date(val.created_at);

                    if (from < value && to > value) {
                      return val;
                    }
                  }
                })
                .filter((val) => {
                  if (serach.status === "") {
                    return val;
                  } else if (val.status.includes(serach.status)) {
                    return val;
                  }
                })
                .filter((val) => {
                  if (serach.text === "" || serach.option === "") {
                    return val;
                  } else if (serach.option === "아이디") {
                    if (serach.text === "") {
                      return val;
                    } else if (val.email.includes(serach.text)) {
                      return val;
                    }
                  } else if (serach.option === "이름") {
                    if (serach.text === "") {
                      return val;
                    } else if (val.name.includes(serach.text)) {
                      return val;
                    }
                  }
                })
                .map((row, index) => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.department}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">
                      {row.created_at.split("T")[0]}
                      {"  "}
                      {row.created_at.split("T")[1].split(".")[0]}
                    </TableCell>
                    <TableCell align="left">
                      <Link to={`/admin-edit/${row.admin_id}`}>
                        <Button
                          style={{
                            backgroundColor: "#fff",
                            color: "#5376FF",
                            padding: "8px 16px",
                            outline: "none",
                            border: "1px solid #5376FF",
                          }}
                        >
                          수정
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
         }
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-center mt-5">
        <div style={{ flex: "8" }}>
          <Pagination2
            pages={pages}
            setLoading={loading => setLoading(loading)}
            setAdmin={(admin) => setAdmin(admin)}
            getList={getAllAdmin}
            setSize={(size) => {
              setSize(size);
            }}
            setNewPage={(newPage) => {
              setNewPage(newPage + 1);
            }}
          />
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center"
        >
          <strong>{"인증 요청 확인"}</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ fontWeight: "500", color: "#000" }}
          >
            사용자 인증 내용
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <textarea
            style={{
              height: "100px",
              width: "300px",
              backgroundColor: "transparent",
              borderRadius: "6px",
            }}
            placeholder="제발 인증 좀 해주세요~~~~~"
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button
          
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "100px",
              height: "auto",
              outline: "none",
            }}
          >
            인증 승인
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "100px",
              height: "auto",
              outline: "none",
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Loading open={loading} />*/}
    </div>
  );
}
