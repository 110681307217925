import React, { useEffect, useState } from "react";
import "../../pages/Login/index.css";

function Pagination({ pages, setAdmin, getList, setSize, setNewPage, search }) {
  const [clicked, setClicked] = useState(0);
  const [current, setCurrent] = useState(0);
  const [startPage, setStartPage] = useState(0); 
  const [endPage, setEndPage] = useState(4);  
  setNewPage(current);

  // const startPage = Math.max(0, current - 1);
  // const endPage = Math.min(pages - 1, startPage + 4);

  const handlePageClickButtun = (pageNum) => {
    getList(search, pageNum+1).then((res) => {
      setAdmin(res?.data);
      setClicked(pageNum);
      setCurrent(pageNum);
      setSize(res?.data?.length);
    });
  };

  const handlePageClickNumbers = (pageNum) => {
    getList(search, pageNum).then((res) => {
      setAdmin(res?.data);
      setClicked(pageNum - 1);
      setCurrent(pageNum - 1);
      setSize(res.data.length);
    });
  };

  useEffect(() => {
    
    if (clicked < startPage) {
      setStartPage(clicked);
      setEndPage(clicked + 4);
    } else if (clicked > endPage) {
      setEndPage(clicked);
      setStartPage(clicked - 4);
    }
  }, [clicked]);

  return (
    <div>
      <nav>
        <ul className="d-flex justify-content-center">
          <div
            className="pagination-item"
            // onClick={(e) => {
            //   getList(search, 1).then((res) => {
            //     setAdmin(res.data);
            //     setClicked(0);
            //     setCurrent(0);
            //     setSize(res?.data?.length);
            //   });
            // }}
            onClick={() => handlePageClickButtun(0)}
          >
            First
          </div>
          <div
            className="pagination-item"
            style={{ width: "74px", textAlign: "center" }}
            // onClick={(e) => {
            //   clicked > 0 &&
            //     getList(search, clicked).then((res) => {
            //       setAdmin(res.data);
            //       setClicked(clicked - 1);
            //       setSize(res.data.length);
            //       setCurrent(current - 1);
            //     });
            // }}
            onClick={() => {
              if (clicked > 0) {
                handlePageClickButtun(clicked-1);
              }
            }}
          >
            Previous
          </div>
          {Array.from(Array(endPage - startPage + 1), (e, i) => {
            const pageNum = startPage + i + 1;
            return (
              <div
                className={
                  pageNum === clicked + 1
                    ? "pagination-item-active"
                    : "pagination-item"
                }
                style={{ width: "32px", textAlign: "center" }}
                key={i}
                tabIndex={i}
                onClick={() => handlePageClickNumbers(pageNum)}
              >
                {pageNum}
              </div>
            );
          })}
          <div
            className="pagination-item"
            style={{ width: "74px", textAlign: "center" }}
            onClick={(e) => {
              // clicked <= pages - 2 &&
              //   getList(search, clicked + 2).then((res) => {
              //     setAdmin(res.data);
              //     setClicked(clicked + 1);
              //     setCurrent(current + 1);
              //   });
              handlePageClickButtun(clicked+1);
            }}
          >
            Next
          </div>
          <div
            className="pagination-item"
            // onClick={(e) => {
              // getList(search, pages).then((res) => {
              //   setAdmin(res.data);
              //   setClicked(pages - 1);
              //   setSize(res?.data?.length);
              //   setCurrent(pages - 1);
              // });
            // }}
            onClick={() => handlePageClickButtun(pages-1)}
          >
            Last
          </div>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;
