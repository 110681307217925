import React, { useState } from "react";
import axios from "axios";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CustomAlert from "../../components/Alerts/CustomAlert";
import "./index.css";

import Logo from "./logo.png";

import * as Yup from "yup";
import { useFormik } from "formik";

export default function SignIn() {
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    onSubmit: (val, { resetForm }) => {
      setOpen(true);
      axios
        .post(`${process.env.REACT_APP_SERVER}/api/admin/login`, val)
        .then((result) => {
          result.data.data.error === true &&
            setMessage(result.data.data.message);
          localStorage.setItem("token", result.data.data.data.accessToken);
          localStorage.setItem("admin", result.data.data.data.user_id);
          setAlert({ type: "success", message: "Successfully logged." });
          setTimeout(() => {
            window.location.href = "/admin-list";
          }, 500);
        })
        .catch((err) => {
          setAlert({ type: "error", message: "Login not success." });
        });
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Fill Email!!").email("Invalid Email"),
      password: Yup.string().required("Fill Password!!")
    })
  });

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ backgroundColor: "#36373B", height: "100vh" }}
    >
      <div className="container ">
        <form
          className="login-form mx-auto"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <div className="heading-text">
            <img src={Logo} />
          </div>
          <div className="sub-heading-text">Administrator</div>
          <label className="lables">아이디</label>
          <input
            type="text"
            id="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            className="text-fields"
            placeholder="Please enter your registered email address."
          />
          {formik.touched.email && formik.errors.email ? (
            <small className="text-danger">{formik.errors.email}</small>
          ) : (
            ""
          )}
          <Box mt={3}></Box>

          <label className="lables">비밀번호</label>

          <input
            type="password"
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            className="text-fields"
            placeholder="Please enter a password."
          />
          {formik.touched.password && formik.errors.password ? (
            <small className="text-danger">{formik.errors.password}</small>
          ) : (
            ""
          )}

          {/* {message && <p className="text-danger mt-3">{message}</p>} */}

          <Box mt={3}></Box>

          <button type="submit" className="submit">
            LOG IN
          </button>

          <Grid container>
            <Grid item xs></Grid>
          </Grid>
        </form>
      </div>

      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
