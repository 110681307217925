import React, { useState } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import Pagination2 from "../../components/Pagination/Pagination";
import Loading from "../../components/Loading/Loading";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import {
  approvePurchanseRequest,
  getAllPurchaserequest,
  rejectPurchanseRequest,
} from "../../api/purchage";
import { Stack } from "@mui/material";
import CustomAlert from "../../components/Alerts/CustomAlert";

export default function PurchaseApproval() {
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const classes = useStyles();

  const [category, setCategory] = useState([]);

  const [open2, setOpen2] = useState(false);

  const [kycInfo, _kycInfo] = useState([]);

  const handleClickOpenHistory = () => {
    setOpen2(true);
  };

  const handleAutoClose = () => {
    setOpen2(false);
  };
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onclickViewHistory = async (kyc_data) => {
    if (kyc_data != undefined || kyc_data != null) {
      _kycInfo(kyc_data[0]);
      handleClickOpenHistory();
    } else {
      setOpen(true);
      setAlert({ type: "error", message: "KYC data not Availbale." });
    }
  };

  const clearText = () => {
    setSearch({ ...serach, ...{ text: "", option: "", status: "" } });
  };

  const [serach, setSearch] = useState({
    from: "",
    to: "",
    category: "",
    status: "",
    coin: "",
    option: "",
    text: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...serach, ...{ [name]: value } };
    setSearch(formData);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(14);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPage = indexOfLastPost - postsPerPage;
  const currentPosts =
    category && category.slice(indexOfFirstPage, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [loading, setLoading] = useState(false);
  const [purchanseRequets, _purchanseRequets] = useState([]);

  const getAllPurchaseRequest = () => {
    setLoading(true);
    getAllPurchaserequest()
      .then((res) => {
        _purchanseRequets(res);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllPurchaseRequest();
  }, []);

  const onApproveRequest = (id, user, _id) => {
    setOpen(true);
    approvePurchanseRequest(id, user, _id)
      .then((res) => {
        setAlert({ type: "success", message: "Saved Successfully." });
        getAllPurchaseRequest();
      })
      .catch(() => {
        setAlert({ type: "error", message: "Something went wrong." });
      });
  };

  const onRejectRequest = (id, user, _id) => {
    setOpen(true);
    rejectPurchanseRequest(id, user, _id)
      .then((res) => {
        setAlert({ type: "success", message: "Saved Successfully." });
        getAllPurchaseRequest();
      })
      .catch(() => {
        setAlert({ type: "error", message: "Something went wrong." });
      });
  };

  //pagination states
  const [pages, setPages] = useState(1);
  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState();

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>
        Purchase Requests
      </div>

      <div style={{ display: "flex", margin: "2% 0" }}>
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "8" }}></div>
      </div>

      <div
        className="align-items-center mt-2"
        style={{ fontWeight: "700", display: "flex" }}
      >
        <div style={{ flex: "6" }}></div>
      </div>
      <div
        className="align-items-center row mt-5"
        style={{ fontWeight: "700", display: "flex" }}
      >
        <div className="col-sm-12 col-lg-5"></div>
        <div
          className="col-sm-12 col-lg-2 my-2"
          style={{ color: "#000", fontWeight: "700", textAlign: "left" }}
        >
          등록일 기간 검색
        </div>
        <div className="col-sm-12 col-lg-2 my-2">
          <TextField
            variant="outlined"
            id="date"
            type="date"
            label="시작일"
            fullWidth
            name="from"
            onChange={handleChange}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="col-sm-12 col-lg-1 my-2 d-flex justify-content-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#A9ABB0",
              width: "55px",
              height: "55px",
              borderRadius: "3px",
              textAlign: "center",
            }}
          >
            ~
          </div>
        </div>
        <div className="col-sm-12 col-lg-2 my-2">
          <TextField
            variant="outlined"
            id="date"
            type="date"
            label="종료일"
            name="to"
            fullWidth
            onChange={handleChange}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>

      <div
        className="align-items-center mt-2 row mb-3"
        style={{ fontWeight: "700", display: "flex" }}
      >
        <div className="col-sm-12 col-lg-4 my-2"></div>
        <div className="col-sm-12 col-lg-4 my-2 select-box">
          <TextField
            id="status"
            name="status"
            value={serach.status}
            onChange={handleChange}
            select
            // placeholder="=상태="
            label="=상태="
            variant="outlined"
            style={{ width: "150px", margin: "0 5px" }}
          >
            <MenuItem value="">=기본=</MenuItem>
            <MenuItem value="waiting">대기 중</MenuItem>
            <MenuItem value="approved">확인</MenuItem>
          </TextField>

          <TextField
            id="filled-select-currency"
            select
            // placeholder="=상태="
            label="=상태="
            name="option"
            variant="outlined"
            onChange={handleChange}
            value={serach.option}
            style={{ width: "150px" }}
          >
            <MenuItem value="">=검색옵션=</MenuItem>
            <MenuItem value="art_work_id">예술 작품 ID</MenuItem>
            <MenuItem value="purchase_request_id">구매_요청_id</MenuItem>
          </TextField>
        </div>
        <div className="col-sm-12 col-lg-2 my-2">
          <TextField
            id="filled-select-currency"
            // placeholder="검색옵션을 선택해주세요."
            label="검색옵션을 선택해주세요."

            variant="outlined"
            fullwidth
            name="text"
            value={serach.text}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-6 col-lg-1 my-2">
          <Button
            style={{
              backgroundColor: "#5376FF",
              color: "#fff",
              padding: "0 2px",
              height: "55px",
              marginLeft:"-20px"
            }}
          >
            {" "}
            검색{" "}
          </Button>
        </div>
        <div className="col-sm-6 col-lg-1 my-2">
          <Button
            style={{
              backgroundColor: "#fff",
              color: "#5376FF",
              padding: "0 2px",
              height: "54px",
              border: "1px solid #c4c4c4"
            }}
            onClick={() => {
              clearText();
            }}
          >
            {" "}
            Edit{" "}
          </Button>
        </div>
      </div>

      <div style={{ fontWeight: "500" }}>Total : 14 Count (1/1)Page</div>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#E5E5E5" }}>
            <TableRow>
              <TableCell>NO</TableCell>
              <TableCell align="center">PK</TableCell>
              <TableCell align="center">art_work_id</TableCell>
              <TableCell align="center">Buyer_id </TableCell>
              <TableCell align="center">Seller_id </TableCell>
              <TableCell align="center">approval_status</TableCell>
              <TableCell align="center">purchase_request_id</TableCell>
              <TableCell align="center">Register Date</TableCell>
              <TableCell align="center">Buyer KYC</TableCell>
              <TableCell align="center">Seller KYC</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchanseRequets &&
              purchanseRequets
                .filter((val) => {
                  if (serach.from === "") {
                    return val;
                  } else if (val.created_at.includes(serach.from)) {
                    return val;
                  }
                })
                .filter((val) => {
                  if (serach.to == "") {
                    return val;
                  } else if (val.created_at.includes(serach.to)) {
                    return val;
                  }
                })
                .filter((val) => {
                  if (serach.status === "") {
                    return val;
                  } else if (val.approval_status.includes(serach.status)) {
                    return val;
                  }
                })
                .filter((val) => {
                  if (serach.text == "" || serach.option == "") {
                    return val;
                  } else if (serach.option === "art_work_id") {
                    if (serach.text == "") {
                      return val;
                    } else if (val?.art_work_id.includes(serach.text)) {
                      return val;
                    }
                  } else if (serach.option === "purchase_request_id") {
                    if (serach.text == "") {
                      return val;
                    } else if (val?.purchase_request_id.includes(serach.text)) {
                      return val;
                    }
                  }
                })
                .map((data, index) => (
                  <TableRow key={`purchaseRequest.${index}`}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    {console.log(data)}
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{data?.art_work_id}</TableCell>
                    <TableCell align="center">
                      {data?.buyer?.buyer_user_id}
                    </TableCell>
                    <TableCell align="center">
                      {data?.seller?.seller_user_id}
                    </TableCell>
                    <TableCell align="center">
                      {data?.approval_status}
                    </TableCell>
                    <TableCell align="center">
                      {data?.purchase_request_id}
                    </TableCell>
                    <TableCell align="center">{data?.created_at}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          onclickViewHistory(data?.buyer?.buyer_kyc_info);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          onclickViewHistory(data?.seller?.seller_kyc_info);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Stack spacing={2} direction="row">
                        <Button
                          variant="contained"
                          onClick={() => {
                            onApproveRequest(
                              data?.art_work_id,
                              data?.buyer?.buyer_user_id,
                              data?.id
                            );
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            onRejectRequest(
                              data?.art_work_id,
                              data?.buyer?.buyer_user_id,
                              data?.id
                            );
                          }}
                        >
                          Reject
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="d-flex justify-content-center mt-5">
        <div>
          <Button size="small" style={{ height: "30px", width: "30px" }}>
            {" "}
            First{" "}
          </Button>
        </div>
        <div className="d-flex justify-content-center" style={{ flex: "8" }}>
          <div style={{ flex: "8" }}>
            {
              //<Pagination count={30} variant="outlined" shape="rounded" />
            }
            <Pagination2
              pages={pages}
              // setAdmin={(admin) => setAdmin(admin)}
              getList={getAllPurchaserequest}
              setSize={(size) => {
                setSize(size);
              }}
              setNewPage={(newPage) => {
                setNewPage(newPage + 1);
              }}
            />
          </div>
        </div>
        <div>
          <Button size="small" style={{ height: "30px", width: "30px" }}>
            {" "}
            End{" "}
          </Button>
        </div>
      </div>

      <Dialog
        open={open2}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"KYC INFO"}</strong>
        </DialogTitle>

        <DialogContent>
          <div className="d-flex justify-content-center"></div>
        </DialogContent>

        <DialogContent>
          <div
            className="mt-5 row"
            style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
          >
            <div className="col-lg-4">
              <label style={{ color: "#000" }}> Name</label> <br />
              <TextField
                id="filled-select-currency"
                variant="standard"
                name="name"
                value={kycInfo?.name}
                fullwidth
                disabled
                inputProps={{ readOnly: true }}
                style={{ width: "300px" }}
              />
            </div>
            <div className="col-lg-4">
              <label style={{ color: "#000" }}> ID type</label> <br />
              {kycInfo?.id_type === "0" ? (
                <TextField
                  id="filled-select-currency"
                  variant="standard"
                  value="Registration Card"
                  name="name"
                  disabled
                  //onChange={handleChange}
                  fullwidth
                  style={{ width: "300px" }}
                />
              ) : kycInfo?.id_type === "1" ? (
                <TextField
                  id="filled-select-currency"
                  variant="standard"
                  value="Drivers License"
                  name="name"
                  disabled
                  //onChange={handleChange}
                  fullwidth
                  style={{ width: "300px" }}
                />
              ) : kycInfo?.id_type === "2" ? (
                <TextField
                  id="filled-select-currency"
                  variant="standard"
                  value="Passport"
                  name="name"
                  disabled
                  //onChange={handleChange}
                  fullwidth
                  style={{ width: "300px" }}
                />
              ) : (
                <TextField
                  id="filled-select-currency"
                  variant="standard"
                  value="No any registered type"
                  name="name"
                  disabled
                  //onChange={handleChange}
                  fullwidth
                  style={{ width: "300px" }}
                />
              )}
            </div>
            <div className="col-lg-4">
              <label style={{ color: "#000" }}> Contact</label> <br />
              <TextField
                id="filled-select-currency"
                variant="standard"
                name="contact"
                value={kycInfo?.contact}
                fullwidth
                disabled
                inputProps={{ readOnly: true }}
                style={{ width: "300px" }}
              />
            </div>
          </div>

          <div
            className="row mt-5"
            style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
          >
            <div className="col-lg-4">
              <label style={{ color: "#000" }}>Passport/ID number</label>
              <br />
              <TextField
                id="filled-select-currency"
                variant="standard"
                name="department"
                value={kycInfo?.id_arc}
                disabled
                fullwidth
                style={{ width: "300px" }}
              />
            </div>
            <div className="col-lg-4">
              <label style={{ color: "#000" }}>Date of birth</label> <br />
              <TextField
                id="filled-select-currency"
                variant="standard"
                fullwidth
                value={kycInfo?.date_of_birth}
                name="date_of_birth"
                disabled
                //onChange={handleChange}
                style={{ width: "300px" }}
              />
            </div>
            <div className="col-lg-4">
              <label style={{ color: "#000" }}> Country or region</label> <br />
              <TextField
                id="filled-select-currency"
                variant="standard"
                fullwidth
                name="country"
                value={kycInfo?.country}
                disabled
                //onChange={handleChange}
                style={{ width: "300px" }}
              />
            </div>
          </div>

          <div
            className="mt-5 row"
            style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
          >
            <div className="col-lg-4">
              <label style={{ color: "#000" }}> Gender</label> <br />
              <TextField
                id="filled-select-currency"
                variant="standard"
                name="gender"
                value={kycInfo?.gender}
                fullwidth
                disabled
                inputProps={{ readOnly: true }}
                style={{ width: "300px" }}
              />
            </div>
          </div>

          <div
            className="row mt-5"
            style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
          >
            <label style={{ color: "#000" }}> ID picture</label> <br />
            <div
              className="col-lg-8"
              style={{
                border: "2px solid #000",
                height: "auto",
                borderRadius: "12px",
              }}
            >
              <div className="row py-4">
                <div
                  className="col-lg-6"
                  style={{
                    textAlign: "center",
                    color: "#808080",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={kycInfo?.id_image_front}
                    alt="id-front"
                    style={{ maxWidth: "100%" }}
                  />
                  <br />
                  <span>ID picture (front)</span>
                </div>
                <div
                  className="col-lg-6"
                  style={{
                    textAlign: "center",
                    color: "#808080",
                    objectFit: "contain",
                  }}
                >
                  <img
                    src={kycInfo?.id_image_back}
                    alt="id-back"
                    style={{ maxWidth: "100%" }}
                  />
                  <br />
                  <span>ID picture (back)</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex"
              style={{
                position: "absolute",
                bottom: "60px",
                right: "10px",
              }}
            >
              <div style={{ flex: "2" }}></div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5 mt-2">
          <Button
            fullwidth
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#183B56",
              color: "#fff",
              margin: "0 4%",
            }}
          >
            돌아가기
          </Button>
        </DialogActions>
      </Dialog>

      {/*<Loading open={loading} />*/}
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
