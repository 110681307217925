import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { quizink } from "react-router-dom";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import one from "../../images/1.png";
import two from "../../images/2.png";
import three from "../../images/3.png";
import four from "../../images/4.png";
import five from "../../images/5.png";
import six from "../../images/6.png";
import seven from "../../images/7.png";
import eight from "../../images/8.png";
import Collapse from "@mui/material/Collapse";
import Logo from "../../pages/Login/logo.png";

import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";

// core components

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Navigate } from "react-router-dom";

import { getanAdminMenu } from "../../api/admin";

const drawerWidth = 250;

const useStyles = makeStyles({
  paper: {
    background: "black",
    color: "white",
  },
});

function SideBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const theme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: "#000",
          },
        },
      },
    },
  });

  const [sidemenu, setSideMenu] = useState({
    admin_list: "",
    category_list: "",
    nft_list: "",
    nft_settings: "",
    transaction_history: "",
    popular_list: "",
    product_list: "",
    filtering_list: "",
    member_list: "",
    avatar_list: "",
    notice_list: "",
    faq_list: "",
    one_question_list: "",
    terms: "",
    policy: "",
  });

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick2 = () => {
    setOpen2(!open2);
  };

  const handleClick3 = () => {
    setOpen3(!open3);
  };

  const handleClick4 = () => {
    setOpen4(!open4);
  };

  const handleClick5 = () => {
    setOpen5(!open5);
  };

  // redux-state
  const authData = useSelector((state) => state.auth);
  function logoutAdmin() {
    localStorage.removeItem("token");
  }

  const adminId = localStorage.getItem("admin");

  useEffect(() => {
    getanAdminMenu(adminId).then((res) => {
      if (res.result) {
        setSideMenu(res.result[0]);
      } else {
        localStorage.removeItem("token");
        window.location = "/";
      }
    });
  }, []);

  const { image } = props;
  var links = (
    <List className={classes.list + " main_menu"}>
      {sidemenu?.admin_list != "false" && (
        <ListItem button component={Link} to="/admin-list">
          <ListItemIcon>
            <img src={one} />
          </ListItemIcon>
          <ListItemText
            primary="관리자 목록"
            disableTypography={true}
            style={{ color: "#fff" }}
          />
          <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
        </ListItem>
      )}

      <ListItem button onClick={handleClick4}>
        <ListItemIcon>
          <img src={two} />
        </ListItemIcon>

        <ListItemText
          primary="회원관리"
          disableTypography={true}
          style={{ color: "#fff" }}
        />
        {open4 ? <ExpandLess /> : <ExpandMore />}
        <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
      </ListItem>

      <Collapse in={open4} timeout="auto" unmountOnExit>
        <List disablePadding>
          {sidemenu?.member_list != "false" && (
            <ListItem button component={Link} to="/unverified-user-list">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText
                // primary="Unverified Member List"
                primary="미확인 회원 목록"
                style={{ color: "#fff" }}
              />
            </ListItem>
          )}
          {sidemenu?.member_list != "false" && (
            <ListItem button component={Link} to="/verified-member-list">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText primary="검증된 회원 목록" style={{ color: "#fff" }} />
            </ListItem>
          )}
          {/*sidemenu?.avatar_list != "false" && (
            <ListItem button component={Link} to="/avatar-list">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText primary="Avatar" style={{ color: "#fff" }} />
            </ListItem>
          )*/}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleClick}
        //</List>component={Link} to="/nft-management"
      >
        <ListItemIcon>
          <img src={three} />
        </ListItemIcon>
        <ListItemText
          primary="NFT 관리"
          disableTypography={true}
          style={{ color: "#fff" }}
        />

        <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {sidemenu?.nft_list != "false" && (
            <ListItem button component={Link} to="/nft-management">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText primary="NFT 목록" style={{ color: "#fff" }} />
            </ListItem>
          )}
          {sidemenu?.transaction_history != "false" && (
            <ListItem button component={Link} to="/nft-transaction">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText primary="NFT 거래내역" style={{ color: "#fff" }} />
            </ListItem>
          )}
          {sidemenu?.category_list != "false" && (
            <ListItem button component={Link} to="/nft-categories">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText
                primary="NFT 카테고리 목록"
                style={{ color: "#fff" }}
              />
            </ListItem>
          )}
          {sidemenu?.nft_settings != "false" && (
            <ListItem button component={Link} to="/nft-settings">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText primary="NFT 설정" style={{ color: "#fff" }} />
            </ListItem>
          )}
          {/* <ListItem button component={Link} to="/nft-verification">
            <ListItemIcon style={{ color: "#fff" }}>
              <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
            </ListItemIcon>
            <ListItemText
              // primary="NFT Artwork verification"
              primary="NFT 작품 검증"
              style={{ color: "#fff" }}
            />
          </ListItem> */}
        </List>
      </Collapse>
      <ListItem
        button
        onClick={handleClick3}
        //</List>component={Link} to="/nft-management"
      >
        <ListItemIcon>
          <img src={four} alt="four" />
        </ListItemIcon>
        <ListItemText
          // primary="Service Center Management"
          primary="서비스 센터 관리"
          disableTypography={true}
          style={{ color: "#fff" }}
        />

        <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
        {open3 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open3} timeout="auto" unmountOnExit>
        <List disablePadding>
          {sidemenu?.notice_list != "false" && (
            <ListItem button component={Link} to="/notice-list">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText 
              // primary="Notice" 
              primary="알아채다" 
              style={{ color: "#fff" }} />
            </ListItem>
          )}
          {sidemenu?.faq_list != "false" && (
            <ListItem button component={Link} to="/frequent-list">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText 
              // primary="FAQ" 
              primary="자주 묻는 질문" 
              style={{ color: "#fff" }} />
            </ListItem>
          )}
          {sidemenu?.one_question_list != "false" && (
            <ListItem button component={Link} to="/question-list">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText 
              // primary="1:1 inquiry" 
              primary="1:1 문의" 
              style={{ color: "#fff" }} />
            </ListItem>
          )}
        </List>
      </Collapse>

      {/* <ListItem button onClick={handleClick5}>
        <ListItemIcon>
          <img src={five} />
        </ListItemIcon>
        <ListItemText
          primary="Delete"
          disableTypography={true}
          style={{ color: "#fff" }}
        />
        {open5 ? <ExpandLess /> : <ExpandMore />}
        <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
      </ListItem> */}
      <Collapse in={open5} timeout="auto" unmountOnExit>
        <List disablePadding>
          {sidemenu?.terms != "false" && (
            <ListItem button component={Link} to="/terms">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText
                primary="Terms and conditions"
                style={{ color: "#fff" }}
              />
            </ListItem>
          )}

          {sidemenu?.policy != "false" && (
            <ListItem button component={Link} to="/policy">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText
                primary="Privacy policy"
                style={{ color: "#fff" }}
              />
            </ListItem>
          )}
        </List>
      </Collapse>

      <ListItem
        button
        onClick={handleClick2}
        //</List>component={Link} to="/nft-management"
      >
        <ListItemIcon>
          <img src={three} />
        </ListItemIcon>
        <ListItemText
          // primary="Site management"
          primary="사이트 관리"
          disableTypography={true}
          style={{ color: "#fff" }}
        />

        <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
        {open2 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open2} timeout="auto" unmountOnExit>
        <List disablePadding>
          {/* {sidemenu?.popular_list != "false" && (
            <ListItem button component={Link} to="/popular">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText
                primary="Popular Creator List"
                style={{ color: "#fff" }}
              />
            </ListItem>
          )} */}

          <ListItem button component={Link} to="/expose">
            <ListItemIcon style={{ color: "#fff" }}>
              <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
            </ListItemIcon>
            <ListItemText
              // primary="Banner Management"
              primary="배너 관리"
              style={{ color: "#fff" }}
            />
          </ListItem>
          {/* {sidemenu?.filtering_list != "false" && (
            <ListItem button component={Link} to="/world-filter">
              <ListItemIcon style={{ color: "#fff" }}>
                <FiberManualRecordRoundedIcon style={{ fontSize: "small" }} />
              </ListItemIcon>
              <ListItemText
                primary="word filtering list"
                style={{ color: "#fff" }}
              />
            </ListItem>
          )} */}
        </List>
      </Collapse>

      <ListItem button component={Link} to="/purchase-requests">
        <ListItemIcon>
          <img src={seven} />
        </ListItemIcon>
        <ListItemText
          // primary="Purchase Request"
          primary="구매 요청"
          disableTypography={true}
          style={{ color: "#fff" }}
        />
        <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
      </ListItem>

      <ListItem button component={Link} to="/admin-profile">
        <ListItemIcon>
          <img src={seven} />
        </ListItemIcon>
        <ListItemText
          primary="내 프로필"
          disableTypography={true}
          style={{ color: "#fff" }}
        />
        <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
      </ListItem>
      <a href="/" style={{ textDecoration: "none" }}>
        <ListItem button onClick={logoutAdmin}>
          <ListItemIcon>
            <img src={six} />
          </ListItemIcon>
          <ListItemText
            primary="로그아웃"
            disableTypography={true}
            style={{ color: "#fff" }}
          />

          <i style={{ color: "white" }} className="fas fa-chevron-right"></i>
        </ListItem>
      </a>
    </List>
  );

  var brand = (
    <div className="d-flex justify-content-center my-3">
      <a
        href="/admin-profile"
        style={{ textDecoration: "none", color: "white" }}

        // target="_blank"
      >
        <div className={classes.logoImage}>
          {<img src={Logo} alt="logo" className={classes.img} />}
        </div>
        {/* {logoText} */}
      </a>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {brand}
            <div className={classes.sidebarWrapper}>{links}</div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ background: "#36373B" }}
              />
            ) : null}
          </Drawer>

          <ThemeProvider theme={theme}>
            <Drawer
              variant="permanent"
              classes={{ paper: classes.paper }}
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {brand}
              <div className={classes.sidebarWrapper}>{links}</div>
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{ background: "#36373B" }}
                />
              ) : null}
            </Drawer>
          </ThemeProvider>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
        >
          {props.data ? props.component : <Navigate to="/" replace />}
        </Box>
      </Box>
    </div>
  );
}

SideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SideBar;
