import axios from "axios";
const config = {
    headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
    }
};

export const getAllPopular = async () => {
    try {
        let respData = await axios.get(
            `${process.env.REACT_APP_SERVER}/api/popular/all`,
            config
        );
        return respData.data;
    } catch (err) {
        localStorage.removeItem("token");
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        };
    }
};