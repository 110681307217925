import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getSearchVerifiedUsers, getUsers, getVerifiedUsers } from "../../api/users";
import Button from "@mui/material/Button";
import customInputStyle from "../../styles/customInputStyle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./style.css";
import Pagination2 from "../../components/Pagination/PaginationNormal";
import Loading from "../../components/Loading/Loading";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const customStyle = makeStyles(customInputStyle);

export default function AllUsers() {
  const classes = useStyles();

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [userID, setUserID] = useState();

  const [users, setUsers] = useState();
  const [count, setCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(0);

  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState();
  const [pages, setPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [serach, setSearch] = useState({
    from: "",
    to: "",
    status: "",
    option: "",
    text: "",
  });

  const handleClickOpen = (id) => {
    setOpen(true);
    setUserID(id);
  };

  const handleAutoClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...serach, ...{ [name]: value } };
    setSearch(formData);
  };

  const clearText = () => {
    setSearch({ ...serach, ...{ text: "", option: "" } });
  };

  const handleClearText = () => {
    setLoading(true);
    setSearch({
      from: "",
      to: "",
      status: "",
      option: "",
      text: "",
    });

    getSearchVerifiedUsers(1, "")
      .then((res) => {
        setUsers(res.data);
        setSize(res.data.length);
        setPages(res?.totalPages);
        setCount(res.count);
        setItemPerPage(res.itemPerPage);
        setTotalPages(res.totalPages);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = () => {
    setLoading(true);
    
    getSearchVerifiedUsers(1, serach.text)
    .then((res) => {
      setLoading(false);
      setUsers(res.data);
      setSize(res.data.length);
      setPages(res?.totalPages);
      setCount(res.count);
      setItemPerPage(res.itemPerPage);
      setTotalPages(res.totalPages);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    handleSearch()
  }, [serach.text])
  

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>회원 목록</div>

      <div>
        {/*}
        <div style={{ display: "flex", margin: "2% 0" }}>
          <div className="col-sm-12 col-lg-2 my-2">
            <TableToExcel
              className="excel-download-button"
              table="user-table"
              sheet="Sheet"
              filename="user-details"
              buttonText="EXCEL 다운로드"
            />
          </div>
          <div style={{ flex: "8" }}></div>
        </div>
  */}
        <div
          className="align-items-center row mt-5"
          style={{ fontWeight: "700", display: "flex" }}
        >
          <div className="col-sm-12 col-lg-5"></div>
          <div
            className="col-sm-12 col-lg-2 my-2"
            style={{ color: "#000", fontWeight: "700", textAlign: "left" }}
          >
            등록일 기간 검색
          </div>
          <div className="col-sm-12 col-lg-2 my-2">
            <TextField
              variant="outlined"
              id="date"
              type="date"
              label="시작일"
              fullWidth
              name="from"
              onChange={handleChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className="col-sm-12 col-lg-1 my-2 d-flex justify-content-center">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#A9ABB0",
                width: "55px",
                height: "55px",
                borderRadius: "3px",
                textAlign: "center",
              }}
            >
              ~
            </div>
          </div>
          <div className="col-sm-12 col-lg-2 my-2">
            <TextField
              variant="outlined"
              id="date"
              type="date"
              label="종료일"
              name="to"
              fullWidth
              onChange={handleChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>

        <div
          className="align-items-center mt-2 row mb-3"
          style={{ fontWeight: "700", display: "flex" }}
        >
          <div className="col-sm-12 col-lg-4 my-2"></div>
          <div className="col-sm-12 col-lg-4 my-2 select-box">
            <TextField
              id="status"
              name="status"
              value={serach.status}
              onChange={handleChange}
              select
              // placeholder="=상태="
              label="=상태="
              variant="outlined"
              style={{ width: "150px", margin: "0 5px" }}
            >
              <MenuItem value="">=상태=</MenuItem>
              <MenuItem value="1">비활성</MenuItem>
              <MenuItem value="0">활성</MenuItem>
            </TextField>

            <TextField
              id="filled-select-currency"
              select
              // placeholder="=상태="
              label="=검색옵션="
              name="option"
              variant="outlined"
              onChange={handleChange}
              value={serach.option}
              style={{ width: "150px" }}
            >
              <MenuItem value=" ">=검색옵션=</MenuItem>
              <MenuItem value="by-nickname">별칭</MenuItem>
              <MenuItem value="by-email">이메일로</MenuItem>
            </TextField>
          </div>
          <div className="col-sm-12 col-lg-2 my-2">
            <TextField
              id="filled-select-currency"
              // placeholder="검색옵션을 선택해주세요."
              label="검색옵션을 선택해주세요."
              variant="outlined"
              fullwidth
              name="text"
              value={serach.text}
              onChange={handleChange}
            />
          </div>
          <div className="col-sm-6 col-lg-1 my-2">
            <Button
              style={{
                backgroundColor: "#5376FF",
                color: "#fff",
                padding: "0 2px",
                height: "55px",
                marginLeft: "-20px",
              }}
            >
              {" "}
              검색{" "}
            </Button>
          </div>
          <div className="col-sm-6 col-lg-1 my-2">
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#5376FF",
                padding: "0 2px",
                border: "1px solid #c4c4c4",
                height: "54px",
              }}
              // onClick={() => {
              //   clearText();
              // }}
              onClick={handleClearText}
            >
              {" "}
              Clear{" "}
            </Button>
          </div>
        </div>

        <div style={{ fontWeight: "500" }}>
          Total : {count} Count ({newPage}/{totalPages})Page
        </div>
      </div>

      <TableContainer
        component={Paper}
        style={{ width: "100%", fontSize: "12px !important" }}
      >
        <Table
          id="user-table"
          className={classes.table}
          aria-label="simple table"
          // style={{msOverflowX:"scroll"}}
        >
          <TableHead style={{ backgroundColor: "#E5E5E5" }}>
            <TableRow>
              <TableCell>NO</TableCell>
              <TableCell align="center">PK</TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">e-mail</TableCell>
              <TableCell align="center">Nickname</TableCell>
              <TableCell align="center">ETH 지갑주소</TableCell>
              {/* <TableCell align="center">ETH quantity</TableCell> */}
              <TableCell align="center">KYC verification</TableCell>
              <TableCell align="center">KYC application date</TableCell>
              <TableCell align="center">Last login</TableCell>
              <TableCell align="center">Registration date</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Edit</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <div style={{ position: "relative", height: "100vh" }}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "500px",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </div>
            </div>
          ) : (
            <TableBody>
              {users &&
                users
                  .filter((val) => {
                    if (serach.from == "") {
                      return val;
                    } else {
                      const from = new Date(serach.from);
                      const to = new Date(serach.to);
                      const value = new Date(val.created_at);

                      if (from < value && to > value) {
                        return val;
                      }
                    }
                  })
                  .filter((val) => {
                    if (serach.status == "") {
                      return val;
                    } else if (val?.status == serach.status) {
                        return val;
                    } 
                  })
                  .filter((val) => {
                    if (serach.text === "" || serach.option === "") {
                      return val;
                    } else if (serach?.option === "by-email") {
                      if (serach.text === "") {
                        return val;
                      } else if (val?.email_plain.includes(serach.text)) {
                        return val;
                      }
                    } else if (serach?.option === "by-id") {
                      if (serach?.text === "") {
                        return val;
                      } else if (val?.id.includes(serach.text)) {
                        return val;
                      }
                    } else if (serach?.option === "by-nickname") {
                      if (serach?.text === "") {
                        return val;
                      } else if (
                        val?.nickname
                          ?.toLowerCase()
                          .includes(serach.text.toLowerCase())
                      ) {
                        return val;
                      }
                    }
                  })
                  .map((user, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1 + (newPage - 1) * itemPerPage}
                      </TableCell>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{user.user_id}</TableCell>
                      <TableCell align="center">{user.email}</TableCell>
                      <TableCell align="center">{user?.nickname}</TableCell>

                      {user.wallet_type &&
                        user.wallet_type.map((wallet, index) => {
                          return (
                            <TableCell
                              key={index}
                              style={{ fontSize: "10px" }}
                              align="center"
                            >
                              {wallet?.wallet_key}
                            </TableCell>
                          );
                        })}

                      {(user.wallet_type == "" || user.wallet_type == null) && (
                        <TableCell
                          style={{ fontSize: "10px" }}
                          align="center"
                        ></TableCell>
                      )}

                      {(user.wallet_type === "" ||
                        user.wallet_type === null) && (
                        <TableCell
                          style={{ fontSize: "10px" }}
                          align="center"
                        ></TableCell>
                      )}

                      {/* <TableCell align="center">{"ss"}</TableCell> */}

                      {user.kyc_status == "unverified" ? (
                        <TableCell align="center">미인증</TableCell>
                      ) : user.kyc_status == "verified" ||
                        user.kyc_status == "waiting" ? (
                        <TableCell align="center">요청</TableCell>
                      ) : (
                        <TableCell align="center">Uncerified</TableCell>
                      )}
                      {user.kyc_status === "verified" ? (
                        <TableCell align="center">인증됨</TableCell>
                      ) : (
                        <TableCell align="center">인증안됨</TableCell>
                      )}
                      <TableCell align="center">
                        {/*user.updated_at.split("T")[0]}
                      {"  "}
                      {user.updated_at.split("T")[1].split(".")[0]*/}
                      </TableCell>
                      <TableCell align="right">
                        {user.created_at.split("T")[0]}
                        {"  "}
                        {user.created_at.split("T")[1].split(".")[0]}
                      </TableCell>
                      <TableCell align="center">
                        {user.status === 1 ? "비활성" : "활성"}
                      </TableCell>
                      <TableCell align="right">
                        {/* <Link to={`/user-edit/${user.user_id}`}> */}
                        <Link to={`/user-edit/${user.user_id}`}>
                          <Button
                            style={{
                              backgroundColor: "#fff",
                              color: "#5376FF",
                              padding: "8px 16px",
                              outline: "none",
                              border: "1px solid #5376FF",
                            }}
                          >
                            수정
                          </Button>
                        </Link>
                        {/* </Link> */}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-center mt-5">
        <div style={{ flex: "8" }}>
          {
            //<Pagination count={30} variant="outlined" shape="rounded" />
          }
          <Pagination2
            pages={pages}
            setLoading={(loading) => setLoading(loading)}
            setAdmin={(users) => setUsers(users)}
            getList={getSearchVerifiedUsers}
            setSize={(size) => {
              setSize(size);
            }}
            setNewPage={(newPage) => {
              setNewPage(newPage + 1);
            }}
            searchParams={serach.text}
          />
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center"
        >
          <strong>{"인증 요청 확인"}</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ fontWeight: "500", color: "#000" }}
          >
            사용자 인증 내용
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <textarea
            style={{
              height: "100px",
              width: "300px",
              backgroundColor: "transparent",
              borderRadius: "6px",
            }}
            placeholder="제발 인증 좀 해주세요~~~~~"
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button
            onClick={() => {
              /* changeStates(userID).then((res) => {
                              alert(res.status);
                              window.location.reload();
                            });
                            */
            }}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "100px",
              height: "auto",
            }}
          >
            인증 승인
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "100px",
              height: "auto",
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Loading open={loading} />*/}
    </div>
  );
}
