// import axios
import axios from "axios";
// import lib
import setAuthToken from "../lib/setAuthToken";

const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

export const getAllUsers = async (page) => {
  const limit = 50;
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin/kyc-unverified?page=${page}&limit=${limit}`,
      config
    );
    return respData.data;
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

let cancelTokenSource = null;

export const getAllSearchUsers = async (page, search) => {
  
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Request cancelled due to new search");
  }
  console.log("searchParams nft.js API call:", search);

  const limit = 50;

  cancelTokenSource = axios.CancelToken.source();

  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin/kyc-unverified-search?page=${page}&limit=${limit}&search=${search}`,
      {
        cancelToken: cancelTokenSource.token,
        ...config,
      }
    );
    return respData.data;
  } catch (err) {
    // localStorage.removeItem("token");
    // return {
    //   status: "failed",
    //   loading: false,
    //   error: err.response.data.errors,
    // };

    if (axios.isCancel(err)) {
      localStorage.removeItem("token");
      console.log("Request was cancelled:", err.message);
      return {
        status: "cancelled",
        loading: false,
      };
    } else {
      return {
        status: "failed",
        loading: false,
        error: err.response ? err.response.data.errors : "An error occurred",
      };
    }
  }
};

export const getSearchVerifiedUsers = async (page, search) => {
   
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Request cancelled due to new search");
  }
  console.log("searchParams nft.js API call:", search);
  
  const limit = 50;

  cancelTokenSource = axios.CancelToken.source();

  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin/kyc-verified-search?page=${page}&limit=${limit}&search=${search}`,
       {
        cancelToken: cancelTokenSource.token,
        ...config,
      }
    );
    return respData.data;
  } catch (err) {
    // localStorage.removeItem("token");
    // return {
    //   status: "failed",
    //   loading: false,
    //   error: err.response.data.errors,
    // };
    
    if (axios.isCancel(err)) {
      localStorage.removeItem("token");
      console.log("Request was cancelled:", err.message);
      return {
        status: "cancelled",
        loading: false,
      };
    } else {
      return {
        status: "failed",
        loading: false,
        error: err.response ? err.response.data.errors : "An error occurred",
      };
    }
  }
};

export const getAUserkyc = async (id) => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin-users/get-user-detail?user_id=${id}`,
      config
    );
    return respData.data;
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateUserState = async (application, id, status) => {
  const admin_id = application.admin_id;
  const password = application.password;

  const data = {
    user_id: id,
    kycStatus: status,
    admin_id: admin_id,
    password: password,
  };

  try {
    let respData = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/admin-users/kyc`,
      data,
      config
    );
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const updateUserNFTState = async (application, id, price, isPublic) => {
  const admin_id = application.admin_id;
  const password = application.password;

  const data = {
    user_id: id,
    korean_price: price,
    isPricePublic: isPublic,
    admin_id: admin_id,
    password: password,
  };

  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/admin-art-work/edit-price?art_work_id=${id}`,
      data,
      config
    );
    console.log("Result :", respData.data);
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const changeNickname = async (id, data) => {
  const value = {
    nickname: data.nickname,
    status: data.status,
    kycVerified: data.kycVerified,
    // kyc_status: data.kyc_status,
    image: data.image,
  };

  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/admin-users/edit-nickname?id=${id}`,
      value,
      config
    );
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAUser = async (id) => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/user/details?user_id=${id}`,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getUsers = async (id) => {
  const limit = 500;
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin-users/all?page=${id}&limit=${limit}`,
      config
    );
    return respData.data;
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getVerifiedUsers = async (id) => {
  const limit = 100;
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin-users/verified-users?page=${id}&limit=${limit}`,
      config
    );
    return respData.data;
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
