import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getAllUsers,
  getAUser,
  changeNickname,
  getAUserkyc,
} from "../../api/users";
import Button from "@mui/material/Button";
import customInputStyle from "../../styles/customInputStyle";

import TextField from "@mui/material/TextField";

import "react-phone-input-2/lib/material.css";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";

import Avatar from "@mui/material/Avatar";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import "./style.css";

import { getAvatarsWithoutPagination } from "../../api/avatar";

import Expansion from "../../components/Expansion/Expansion";

import Loading from "../../components/Loading/Loading";
import CustomAlert from "../../components/Alerts/CustomAlert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    //paddingLeft: theme.spacing(2),
    //paddingRight: theme.spacing(1)
  },

  title: {
    flex: "1 1 76%",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    // marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userDetails, setUserDetails] = useState({
    wallet_type: [{ wallet_key: "" }],
  });
  const [kYCInfo, setKYCInfo] = useState();

  const [avatars, setAvatars] = useState();

  const { id } = useParams();

  const [alert, setAlert] = useState({ type: "", message: "" });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ type: "", message: "" }); 
    setOpen(false);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    getAUserkyc(id).then((res) => {
      setKYCInfo(res.data.kyc_info);
    });

    getAUser(id).then((res) => {
      setUserDetails(res?.data);
    });
    getAvatarsWithoutPagination()
      .then((res) => {
        setAvatars(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAutoClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...userDetails, ...{ [name]: value } };
    setUserDetails(formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(userDetails);
      const result = await changeNickname(id, userDetails)
        console.log("result", result.data.error);

        if (result.data.error === false) {
          setAlert({
            type: "success",
            message: "User Details Updated Successfully!",
          });
          setTimeout(() => {
            window.location.href = "/unverified-user-list";
          }, 500);
        } else {
          console.log("Entering error block");
          setAlert({ type: "error", message: "Error occurred. Please try again later." });
        }
    } catch (err) {
      setAlert({ type: "error", message: "Error occured Try again Later." });
    }
  };

  var profile = String(userDetails?.profile_image);

  return (
    <div className="mx-5 table-user">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>회원 수정</div>
      <div
        className="mt-5 row"
        style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
      >
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 회원 아이디</label> <br />
          <TextField
            id="filled-select-currency"
            value={userDetails?.user_id}
            disabled
            placeholder="user01"
            variant="outlined"
            fullwidth
            style={{ width: "300px" }}
          />
        </div>
        <div className="col-lg-4 ">
          <label style={{ color: "#000" }}> 아바타</label> <br />
          <div className="d-flex align-items-center">
            <div>
              <Avatar alt="Remy Sharp" src={userDetails?.profile_image} />
            </div>
            <div style={{ flex: "2" }} className="px-2">
              <div
                className="d-flex justify-content-right pl-3 align-items-center"
                style={{
                  border: "1px solid #C4C4C4",
                  borderRadius: "6px",
                  fontSize: "14px",
                  height: "45px",
                  fontWeight: "500",
                  padding: "2% 0",
                }}
              >
                {profile.split("-")[7]}
              </div>
            </div>

            <div>
              <Button
                onClick={handleClickOpen}
                style={{
                  border: "1px solid #5376FF",
                  color: "#5376FF",
                  width: "100px",
                  height: "auto",
                  backgroundColor: "transparent",
                }}
                variant="contained"
                color="primary"
              >
                변경하기
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row mt-5"
        style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
      >
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 회원 닉네임 </label> <br />
          <TextField
            id="filled-select-currency"
            placeholder="홍길동"
            variant="outlined"
            fullwidth
            name="nickname"
            value={userDetails?.nickname}
            onChange={handleChange}
            style={{ width: "300px" }}
          />
        </div>
        <div className="col-lg-3">
          <label style={{ color: "#000" }}> ETH 지갑주소</label> <br />
          <TextField
            id="filled-select-currency"
            variant="outlined"
            disabled
            fullwidth
            value={userDetails && userDetails?.wallet_type[0]?.wallet_key}
            placeholder="0x9Eeb60d8F2dAd6B429d7d5A25f68C219459c6444"
            style={{ width: "300px" }}
          />
        </div>
        <div className="col-lg-2" style={{ width: "300px" }}>
          <FormLabel style={{ color: "#000" }} component="legend">
            회원 상태
          </FormLabel>
          <RadioGroup
            defaultValue={userDetails?.status}
            id="status"
            onChange={handleChange}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                style={{ color: "#000" }}
                value={1}
                control={<Radio checked={userDetails?.status == 1} />}
                label="활성"
                name="status"
              />
              <FormControlLabel
                style={{ color: "#000" }}
                value={0}
                control={<Radio checked={userDetails?.status == 0} />}
                label="비활성"
                name="status"
              />
            </div>
          </RadioGroup>
        </div>
        <div className="col-lg-3" style={{ width: "300px" }}>
          <FormLabel style={{ color: "#000" }} component="legend">
            회원 인증 여부
          </FormLabel>
          <RadioGroup
            value={String(userDetails?.kycVerified)}
            id="kycVerified"
            onChange={handleChange}
          >
            <div style={{ display: "flex" }}>
              <FormControlLabel
                style={{ color: "#000" }}
                value={"true"}
                control={<Radio />}
                label="인증됨"
                name="kycVerified"
              />
              <FormControlLabel
                style={{ color: "#000" }}
                value={"false"}
                control={<Radio />}
                name="kycVerified"
                label="인증안됨"
              />
            </div>
          </RadioGroup>
        </div>
      </div>

      <div
        className="row mt-5"
        style={{
          fontWeight: "700",
          fontSize: "18px",
          display: "flex",
          width: "100%",
        }}
      >
        <div className="col-lg-1">
          <Button
            style={{
              backgroundColor: "#5376FF",
              width: "100px",
              height: "auto",
            }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            저장
          </Button>
        </div>
        <div className="col-lg-1">
          <Link to="/unverified-user-list">
            <Button
              style={{
                border: "1px solid #5376FF",
                color: "#5376FF",
                width: "100px",
                height: "auto",
                backgroundColor: "transparent",
              }}
              onClick={handleClickOpen}
              variant="contained"
              color="primary"
            >
              목록
            </Button>
          </Link>
        </div>
        <CustomAlert
            type={alert.type}
            message={alert.message}
            open={alert.type !== ""}
            // open={open}
            handleClose={handleClose}
          />
        <div className="col-lg-10"></div>
      </div>

      <div
        className="row mt-4"
        style={{
          fontWeight: "700",
          fontSize: "18px",
          display: "flex",
          width: "100%",
        }}
      >
        <Expansion data={kYCInfo} id={id} />
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center"
        >
          <strong>{"아바타 변경하기"}</strong>
        </DialogTitle>
        <DialogContent className="my-4">
          <div className="row mb-3">
            {avatars?.map((avatar) => {
              return (
                <div className="col-lg-3 mx-2" key={Math.random()}>
                  <img
                    style={{ width: "60px", height: "60px" }}
                    src={avatar.image}
                    name="image"
                    onClick={() =>
                      setUserDetails({
                        ...userDetails,
                        ...{ image: avatar.image },
                      })
                    }
                  />
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#5376FF",
              color: "#fff",
              width: "100px",
              height: "auto",
            }}
          >
            인증 승인
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "transparent",
              border: "1px solid #5376FF",
              color: "#5376FF",
              width: "100px",
              height: "auto",
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Loading open={loading} />*/}
    </div>
  );
}
