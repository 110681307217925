import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { FormControlLabel } from "@mui/material";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import { getAQuestion } from "../../api/frequentQuestions";
import { useParams } from "react-router-dom";
import { EditAQuestion } from "../../api/frequentQuestions";
import { Link } from "react-router-dom";

import Loading from "../../components/Loading/Loading";

import CustomAlert from "../../components/Alerts/CustomAlert";

import * as Yup from "yup";
import { useFormik } from "formik";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditFrequance() {

  const [question, setQuestion] = useState({
    questions: "",
    category: "",
    answer: "",
    usage: ""
  });

  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAQuestion(id).then((res) => {
      setQuestion(res);
      setLoading(false);
    });
  }, []);

  const onChange = (e) => {
    const { name, value } = e.target;
    const questionData = { ...question, ...{ [name]: value } };
    setQuestion(questionData);
  };

  // const handleSubmit = (e) => {
  //   EditAQuestion(question, id).then((res) => {
  //     res.status === "success" && alert("Success");
  //     setQuestion({
  //       questions: "",
  //       category: "",
  //       answer: "",
  //       usage: ""
  //     });
  //   });
  // };


  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      questions: question?.questions || "",
      category: question?.category || "",
      answer: question?.answer || "",
      usage: question?.usage || "",
    },
    enableReinitialize: true,
    onSubmit: async (val, { resetForm }) => {
      setOpen(true);
      try {
        // addNewQuestion(val).then((res) => {
        //   if (res.error === false) {
        //     setAlert({ type: "success", message: "Success." });
        //     window.location = "/admin-list";
        //   }
        // })

        EditAQuestion(val, id).then((res) => {
          res.status === "success" && setAlert({ type: "success", message: "Success." });
          window.location="/frequent-list"
        });

      } catch (err) {
        setAlert({ type: "error", message: "Error occured Try again Later." });
      }
    },
    validationSchema: Yup.object({
      questions: Yup.string().required("Fill questions"),
      category: Yup.string().required("Fill category"),
      answer: Yup.string().required("Fill answer"),
      usage: Yup.string().required("Fill usage"),
    }),
  });


  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>FAQ 등록1</div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> FAQ 제목</label> <br />
            <TextField
              variant="outlined"
              id="questions"
              name="questions"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.questions}
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true
              }}
            />
            <br />
            {formik.touched.questions && formik.errors.questions ? (
              <small className="text-danger" style={{ fontSize: 13 }}>{formik.errors.questions}</small>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> FAQ 조회수</label> <br />
            <TextField
              id="category"
              select
              name="category"
              placeholder="=상태="
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              style={{ width: "100%" }}
            >
              <MenuItem value="">=카테고리==</MenuItem>
              <MenuItem value="normal">일반</MenuItem>
              <MenuItem value="support">서포트</MenuItem>
              <MenuItem value="deal">거래</MenuItem>
              <MenuItem value="fees">수수료</MenuItem>
            </TextField>
            <br />
            {formik.touched.category && formik.errors.category ? (
              <small className="text-danger" style={{ fontSize: 13 }}>{formik.errors.category}</small>
            ) : (
              ""
            )}

          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6">
            <label style={{ color: "#000" }}> FAQ 내용</label> <br />
            <textarea
              name="answer"
              id="answer"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.answer}
              style={{
                width: "100%",
                backgroundColor: "transparent",
                height: "200px",
                boder: "1px solid #000",
                borderRadius: "8px"
              }}
            />
            <br />
            {formik.touched.answer && formik.errors.answer ? (
              <small className="text-danger" style={{ fontSize: 13 }}>{formik.errors.answer}</small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-6">
            <FormLabel style={{ color: "#000" }} component="legend">
              사용여부
            </FormLabel>
            <RadioGroup defaultValue={formik?.values?.usage} id="usage" onChange={formik.handleChange}
              onBlur={formik.handleBlur} >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="사용함"
                  name="usage"
                  control={<Radio checked={formik?.values?.usage === "사용함"} />}
                  label="사용함"
                />
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="사용안함"
                  name="usage"
                  control={<Radio checked={formik?.values?.usage === "사용안함"} />}
                  label="사용안함"
                />
                <br />
                {formik.touched.usage && formik.errors.usage ? (
                  <small className="text-danger" style={{ fontSize: 13 }}>{formik.errors.usage}</small>
                ) : (
                  ""
                )}
              </div>
            </RadioGroup>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-1">
            <Button
              style={{
                backgroundColor: "#5376FF",
                width: "100px",
                height: "auto",
                outline: "none"
              }}
              variant="contained"
              color="primary"
              type="submit"
            // onClick={handleSubmit}
            >
              저장
            </Button>
          </div>
          <div className="col-lg-1">
            <Link to="/frequent-list">
              <Button
                style={{
                  border: "1px solid #5376FF",
                  color: "#5376FF",
                  width: "100px",
                  height: "auto",
                  outline: "none",
                  backgroundColor: "transparent"
                }}
                variant="contained"
                color="primary"
              >
                목록
              </Button>
            </Link>
          </div>
          <div className="col-lg-10"></div>
        </div>
      </form>
      {/*<Loading open={loading} />*/}
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}
