import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import axios from "axios";
import { FormLabel, Table } from "react-bootstrap";
import {
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomAlert from "../../components/Alerts/CustomAlert";
import { getNFTArtworks } from "../../api/nft";
import { makeStyles } from "@mui/styles";
import { updateUserNFTState } from "../../api/users";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NFTModification() {
  const [open, setOpen] = useState(false);

  const admin_id = localStorage.getItem("admin");

  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open2, setOpen2] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen2(false);
  };

  const [application, setApplication] = React.useState({
    admin_id: admin_id,
    password: "",
    comments: "",
  });

  const [state, setState] = useState();
  const [errMsg, _errMsg] = useState("");
  const [message, setMessage] = useState(false);

  const { id } = useParams();

  const [details, setDetails] = useState({
    category_name: "",
    creator: {
      creator_email: "",
      creator_nickname: "",
      user_id: "",
    },
    current_owner: {
      current_owner_email: "",
      current_owner_id: "",
      current_owner_nickname: "",
    },
  });
  const [price, setPrice] = useState();
  const [isPublic, setPublic] = useState(false);

  const [category, setCategory] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [historydata, setHistoryData] = useState([]);
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAutoClose = () => {
    setOpen(false);
  };

  const [royalty, setRoyalty] = useState();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_SERVER}/api/user/art-work/details?art_work_id=${id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setDetails(data.data.data);
        setRoyalty(data.data.data.royalty);
        setPrice(data.data.data.korean_price);
        setPublic(data.data.data.isPricePublic);
      });

    fetch(`${process.env.REACT_APP_SERVER}/api/nft/category/all`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCategory(data.data);
      });

    /*getANFT(id).then((res) => {
      setDetails(res);
    });
    */
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...application, ...{ [name]: value } };
    _errMsg("");
    setMessage(false);
    setApplication(formData);
  };

  const deleteRoyalty = () => {
    fetch(`${process.env.REACT_APP_SERVER}/api/nft/token?art_work_id=${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      method: "DELETE",
    })
      .then((res) => res.json())
      .then(() => {
        alert("Success");
        window.location.href = "/nft-management";
      })
      .catch((e) => {});
  };

  const updateCategory = () => {
    fetch(
      `${process.env.REACT_APP_SERVER}/api/user/art-work/edit-category?art_work_id=${id}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        method: "PUT",
        body: details,
      }
    )
      .then((res) => res.json())
      .then(async () => {
        await updatePrice();
      })
      .catch((e) => {});
  };

  const updatePrice = () => {
    axios
      .put(
        `${process.env.REACT_APP_SERVER}/api/admin-art-work/edit-price?art_work_id=${id}`,
        { korean_price: price, isPricePublic: isPublic }
      )
      .then((res) => {
        setShowAlert(true);
      })

      .catch((e) => {});
  };

  const handleChangePublic = () => {
    setPublic(!isPublic);
  };

  const callAlert = () => {
    return (
      <div>
        <CustomAlert type="success" message="Price Updated" />
      </div>
    );
  };

  const onclickViewHistory = async (artworkid) => {
    try {
      getNFTArtworks(artworkid).then((res) => {
        setHistoryData(res?.data?.data);
      });
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="mx-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>
        NFT 수정 (카테고리 정보만 변경 가능합니다.)
      </div>
      {showAlert && callAlert()}
      <div
        className="mt-5 row"
        style={{ fontWeight: "700", fontSize: "40px", width: "100%" }}
      >
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> NFT명 (작품명)</label> <br />
          <TextField
            id="filled-select-currency"
            placeholder="ART OF PICASO"
            variant="outlined"
            fullWidth
            disabled
            value={details.title}
            style={{ width: "300px" }}
          />
        </div>
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 카테고리</label> <br />
          <TextField
            select
            id="filled-select-currency"
            placeholder="예술품"
            variant="outlined"
            fullwidth
            name="category_name"
            style={{ width: "300px" }}
            onChange={handleChange}
          >
            <MenuItem value=""> Default </MenuItem>
            {category &&
              category.map((data, index) => {
                return (
                  <MenuItem value={data.category}>{data.category}</MenuItem>
                );
              })}
          </TextField>
        </div>
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 판매상태</label> <br />
          <TextField
            id="filled-select-currency"
            placeholder="고정가격"
            variant="outlined"
            value={details?.selling_available}
            style={{ width: "300px" }}
            disabled
          />
        </div>
      </div>

      <div
        className="row mt-5"
        style={{ fontWeight: "700", fontSize: "40px", width: "100%" }}
      >
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 창작자 </label> <br />
          <TextField
            id="filled-select-currency"
            placeholder="드랙퀸"
            variant="outlined"
            fullwidth
            value={details?.creator?.creator_nickname}
            style={{ width: "300px" }}
            disabled
          />
        </div>
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 소유자</label> <br />
          <TextField
            id="filled-select-currency"
            placeholder="집중하는 소년"
            variant="outlined"
            fullwidth
            value={details?.current_owner?.current_owner_email}
            style={{ width: "300px" }}
            disabled
          />
        </div>
        <div className="col-lg-4 px-5">
          <label style={{ color: "#000" }}> 판매 History </label> <br />
          <Button
            style={{
              color: "#5376FF",
              border: "2px solid #5376FF",
              width: "250px",
            }}
            variant="contained"
            fullWidth
            onClick={() => {
              onclickViewHistory(details?.art_work_id);
            }}
          >
            HISTORY 보기
          </Button>
        </div>
      </div>

      {historydata?.length > 0 ? (
        <DialogContent>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead style={{ backgroundColor: "#E5E5E5" }}>
                <TableRow>
                  <TableCell>NO</TableCell>
                  <TableCell align="right">type</TableCell>
                  <TableCell align="right">Seller</TableCell>
                  <TableCell align="right">Buyer</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historydata.map((row, index) => (
                  <TableRow key={row?.name}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="right">{row?.purchase_type}</TableCell>
                    <TableCell align="right">
                      {row?.creator?.creator_nickname}
                    </TableCell>
                    <TableCell align="right">
                      {row?.buyer?.buyer_nickname}
                    </TableCell>
                    <TableCell align="right">{row?.price}</TableCell>
                    <TableCell align="right">{row?.created_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      ) : (
        <>No History</>
      )}

      <div
        className="row mt-5"
        style={{
          fontWeight: "700",
          fontSize: "18px",
          display: "flex",
          width: "100%",
        }}
      >
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 판매코인 </label> <br />
          <TextField
            id="filled-select-currency"
            variant="outlined"
            fullwidth
            placeholder="ETH"
            value={details.coin_name}
            style={{ width: "300px" }}
            disabled
          />
        </div>
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> 판매가 / 현재가 </label> <br />
          <TextField
            id="filled-select-currency"
            variant="outlined"
            fullwidth
            placeholder="1.213 ETH"
            value={details.sale_price}
            style={{ width: "300px" }}
            disabled
          />
        </div>
        {/* checked={service.usage === "false"} */}
        <div className="col-lg-4">
          <label style={{ color: "#000" }}> KRW Price </label> <br />
          <TextField
            id="filled-select-currency"
            variant="outlined"
            fullwidth
            placeholder=""
            value={price}
            style={{ width: "300px" }}
            onChange={(e) => setPrice(e.target.value)}
          />
          <div>
            <FormLabel style={{ color: "#000" }} component="legend">
              {" "}
              Show Korean Price
            </FormLabel>
            <RadioGroup
              defaultValue={true}
              onChange={handleChangePublic}
              id="usage"
            >
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  style={{ color: "#000" }}
                  control={<Radio checked={isPublic === true} />}
                  label="Show"
                  value="true"
                  name="usage"
                />
                <FormControlLabel
                  style={{ color: "#000" }}
                  control={<Radio checked={isPublic === false} />}
                  label="Dont show"
                  value="false"
                  name="usage"
                />
              </div>
            </RadioGroup>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <label style={{ color: "#000" }}> 로열티 10% </label> <br />
        {royalty &&
          royalty.map((data) => {
            return (
              <div
                className="row mt-2"
                style={{
                  fontWeight: "700",
                  fontSize: "18px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div className="col-lg-4 d-flex">
                  <div className="pr-1" style={{ flex: "1" }}>
                    <TextField
                      id="filled-select-currency"
                      variant="outlined"
                      fullwidth
                      value={data.percentage}
                      placeholder="1.213 ETH"
                      disabled
                    />
                  </div>
                  <div className="pl-1" style={{ flex: "3" }}>
                    <TextField
                      id="filled-select-currency"
                      variant="outlined"
                      fullwidth
                      value={data.wallet_address}
                      placeholder="1.213 ETH"
                      disabled
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <div
        className="row mt-5"
        style={{
          fontWeight: "700",
          fontSize: "18px",
          display: "flex",
          width: "100%",
        }}
      >
        <div className="col-lg-1">
          <Button
            style={{
              backgroundColor: "#5376FF",
              width: "100px",
              height: "auto",
            }}
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            저장
          </Button>
        </div>
        <div className="col-lg-1">
          <Link to="/nft-management" style={{ textDecoration: "none" }}>
            <Button
              style={{
                border: "1px solid #5376FF",
                color: "#5376FF",
                width: "100px",
                height: "auto",
                backgroundColor: "transparent",
              }}
              variant="contained"
              color="primary"
            >
              목록
            </Button>
          </Link>
        </div>
        <div className="col-lg-9"></div>
        <div className="col-lg-1">
          <Button
            onClick={deleteRoyalty}
            style={{
              backgroundColor: "#5376FF",
              width: "100px",
              height: "auto",
            }}
            variant="contained"
            color="primary"
          >
            Burn NFT
          </Button>
        </div>
        <div className="col-lg-10"></div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-center mt-3"
        >
          <strong>{"NFT소각 관리자 확인"}</strong>
        </DialogTitle>

        <DialogContent>
          {/* <DialogContentText>
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <input
              type="text"
              name="email"
              className="text-fields"
              placeholder="admin03"
              style={{ width: "400px" }}
              value={adminEmail}
              onChange={(e) => setAdminEmail(e.target.value)}
            />
          </DialogContentText> */}
          <DialogContentText>
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <input
             type="password"
             name="password"
             className="text-fields"
             value={application.password}
             placeholder="Please enter the administrator password."
             onChange={handleChange}
             style={{ width: "100%" }}
            />
            <p style={{ color: "red" }}>{errMsg}</p>
          </DialogContentText>
        </DialogContent>  
        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={() => {
              if (!application.password) {
                _errMsg("Fill password");
              } else {
                setOpen2(true);
                updateUserNFTState(application, id, price, isPublic).then((res) => {
              if (res.error === false) {
                setAlert({
                  type: "success",
                  message: res.message
                });
                setOpen(false);
                window.location="/nft-management"
              } else {
                setAlert({
                  type: "error",
                  message: "Password is incorrect"
                });
              }
                });
              }
            }}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none"
            }}
          >
            confirm
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto",
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open2}
        handleClose={handleClose}
      />
    </div>
  );
}
