import axios from "axios";

// import lib
import setAuthToken from "../lib/setAuthToken";

const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

export const getAllNFT = async () => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/user/art-work`
    );
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const EditNFT = async (data, id) => {
  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/nft/edit/${id}`,
      data,
      config
    );

    return {
      status: "success",
      loading: false,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getANFT = async (id) => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/nft/${id}`,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const stoppedChange = async (data, id) => {
  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/nft/isStopped/${id}`,
      data,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const allNFTCategory = async () => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/user/art-work/category/fixed`
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
}

export const allCategory = async (page) => {
  const limit = 10;
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/nft/category/all?page=${page}&limit=${limit}`,
      config
    );
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addCategory = async (data) => {
  try {
    let respData = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/nft/category/add`,
      data,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getACategory = async (id) => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/nft/category/${id}`,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const editCategory = async (data, id) => {
  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/nft/category/edit/${id}`,
      data,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addaSetting = async (data) => {
  try {
    let respData = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/nft/setting`,
      data,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const editSetting = async (data) => {
  try {
    if (Number(data.admin_fee) >= 0) {
      const settings = {
        capacity: data.capacity,
        royalty: data.royalty,
        usage: data.usage,
        fees: { admin_fee: data?.admin_fee },
        ip: data.ip,
      };
      let respData = await axios.put(
        `${process.env.REACT_APP_SERVER}/api/nft/update-nft-settings`,
        settings,
        config
      );
      return {
        status: "success",
        loading: false,
        result: respData.data,
      };
    } else {
      let respData = await axios.put(
        `${process.env.REACT_APP_SERVER}/api/nft/update-nft-settings`,
        data,
        config
      );
      return {
        status: "success",
        loading: false,
        result: respData.data,
      };
    }
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getASetting = async () => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/nft/get-nft-settings`,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const deleteAnIP = async (id, data) => {
  const value = {
    ip: data,
  };
  try {
    let respData = await axios.post(
      `${process.env.REACT_APP_SERVER}/api/nft/ip/delete/${id}`,
      value,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const addAnIp = async (stop, id, data) => {
  const value = {
    admin_id: stop.admin_id,
    password: stop.password,
    ip: data,
  };
  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/nft/ip/add/${id}`,
      value,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getUnverifiedList = async () => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin-art-work/unverified`,
      config
    );
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const nftArtworkVerify = async (application, id, status) => {
  const admin_id = application.admin_id;
  const password = application.password;

  const data = {
    art_work_id: id,
    status: status,
    admin_id: admin_id,
    password: password,
  };
  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/admin-art-work/real-estate-status`,
      data,
      config
    );
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getNFTManagementList = async (page, search) => {
  if(search == ""){
    search=""
  }
  const limit = 100;
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/user/art-work/search=${search}?page=${page}&limit=${limit}&pending=false`,
      config
    );
    return respData.data.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const getAllNft = async (search, page) => {
  const limit = 100;
  console.log("search :", search);
  try {
    // api/admin-art-work/nft-management-list
    let respData = await axios.get(
      // `${process.env.REACT_APP_SERVER}/api/user/art-work/search?search=${search}&page=${page}&limit=${limit}`,
      `${process.env.REACT_APP_SERVER}/api/admin-art-work/nft-management-list?page=${page}&limit=${limit}`,
      // `${process.env.REACT_APP_SERVER}/api/admin-art-work/nft-management-list?search=${search}&page=${page}&limit=${limit}`,
      config
    );
    console.log(respData.data);
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
}

// export const getSearchNft = async (page, searchParams) => {
//   console.log("searchParams nft.js API call:", searchParams);
//   const jsonString = JSON.stringify(searchParams);
//   const encodedSearchJsonString = encodeURIComponent(jsonString);
//   const limit = 100;
//   console.log("search :", encodedSearchJsonString);

//   try {
//     // api/admin-art-work/nft-management-search-list
//     let respData = await axios.get(
//       `${process.env.REACT_APP_SERVER}/api/admin-art-work/nft-management-search-list?search=${encodedSearchJsonString}&page=${page}&limit=${limit}`,
//       config
//     );
//     console.log(respData.data);
//     return respData.data;
//   } catch (err) {
//     return {
//       status: "failed",
//       loading: false,
//       error: err.response.data.errors,
//     };
//   }
// }

let cancelTokenSource = null;

export const getSearchNft = async (page, searchParams) => {
  // Cancel the previous request, if it exists
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Request cancelled due to new search");
  }

  console.log("searchParams nft.js API call:", searchParams);
  const jsonString = JSON.stringify(searchParams);
  const encodedSearchJsonString = encodeURIComponent(jsonString);
  const limit = 100;
  console.log("search:", encodedSearchJsonString);

  // Create a new CancelToken source for the current request
  cancelTokenSource = axios.CancelToken.source();

  try {
    const respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin-art-work/nft-management-search-list?search=${searchParams}&page=${page}&limit=${limit}`,
      {
        cancelToken: cancelTokenSource.token, // Assign the cancel token to the request
        ...config, // Make sure you have your 'config' object defined somewhere
      }
    );
    console.log(respData.data);
    return respData.data;
  } catch (err) {
    if (axios.isCancel(err)) {
      // Request was cancelled, handle it as needed
      console.log('Request was cancelled:', err.message);
      return {
        status: 'cancelled',
        loading: false,
      };
    } else {
      // Handle other errors
      return {
        status: 'failed',
        loading: false,
        error: err.response ? err.response.data.errors : 'An error occurred',
      };
    }
  }
};


export const getNFTArtworks = async (artworkid) => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/user/art-work/purchase-history-by-id?art_work_id=${artworkid}`,
      config
    );
    return respData.data;
  } catch (err) {
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
