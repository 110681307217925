// import package
import React, { useState } from "react";
import { Link } from "react-router-dom";

// @material-ui/core
import { FormControlLabel } from "@mui/material";

// import action
import { addNewAdmin } from "../../api/admin";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import CustomAlert from "../../components/Alerts/CustomAlert";

import * as Yup from "yup";
import { useFormik } from "formik";

const initialFormValue = {
  name: "",
  mobile: "",
  email: "",
  department: "",
  password: "",
  confirm_password: "",
  status: "",
  notes: "",
};

const AdminAdd = (props) => {
  const [alert, setAlert] = useState({ type: "", message: "" });
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile:"",
      department: "",
      password: "",
      confirm_password: "",
      status: "",
      notes: "",
    },
    // enableReinitialize={true}
    onSubmit: async (val, { resetForm }) => {
      setOpen(true);
      try {
        const inputData = { mobile: formValue.mobile, ...val };
        const result = await addNewAdmin(inputData);
        if (result && result.status === "success") {
          setAlert({ type: "success", message: "Saved Successfully." });
          window.location = "/admin-list";
        } else {
          setAlert({ type: "error", message: "Email already Exist." });
        }
      } catch (err) {
        setAlert({ type: "error", message: "Error occured Try again Later." });
      }
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Fill name"),
      email: Yup.string().required("Fill Email").email("Email is not valid"),
      department: Yup.string().required("Fill department"),
      password: Yup.string().required("Fill passwiord"),
      confirm_password: Yup.string().required("Fill confirm password"),
      status: Yup.string().required("Fill status"),
      notes: Yup.string().required("Fill notes"),
      mobile:Yup.number().required("Fill Mobileno")
    }),
  });

  // state
  const [formValue, setFormValue] = useState(initialFormValue);

  // function
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  return (
    <div className="mx-5 mt-5">
      <div style={{ fontWeight: "700", fontSize: "40px" }}>
        관리자 등록 & 수정
      </div>
      <form onSubmit={formik.handleSubmit} className="my-5">
        <div
          className="mt-5 row"
          style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
        >
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 아이디</label> <br />
            <TextField
              id="email"
              placeholder="아이디를 입력해주세요."
              variant="outlined"
              fullwidth
              name="email"
              className="text-fields"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              style={{ fontWeight: "300", width: "300px" }}
            />
            <br />
            {formik.touched.email && formik.errors.email ? (
              <small className="text-danger">{formik.errors.email}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 이름</label> <br />
            <TextField
              id="name"
              placeholder="이름을 입력해주세요."
              variant="outlined"
              fullwidth
              name="name"
              // required
              // onChange={handleChange}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.name && formik.errors.name ? (
              <small className="text-danger">{formik.errors.name}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 휴대폰 번호</label> <br />
            <PhoneInput
              inputProps={{ required: true }}
              specialLabel=""
              country={"us"}
              id="mobile"
              onChange={(e) => formik.setFieldValue("mobile", e)}
              style={{
                borderRadius: "18px",
                backgroundColor: "transparent",
                padding: "0px 4px 0px 4px", 
                height: "39px", 
                lineHeight: "60px", 
              }}
            />
               {formik.touched.mobile && formik.errors.mobile ? (
              <small className="text-danger">{formik.errors.mobile}</small>
            ) : (
              ""
            )}
            <br />
          </div>
        </div>

        <div
          className="row mt-5"
          style={{ fontWeight: "600", fontSize: "16px", width: "100%" }}
        >
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 담당부서 </label> <br />
            <TextField
              id="department"
              placeholder="영업부"
              variant="outlined"
              fullwidth
              name="department"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.department}
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.department && formik.errors.department ? (
              <small className="text-danger">{formik.errors.department}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4">
            <label style={{ color: "#000" }}> 관리자 비밀번호</label> <br />
            <TextField
              id="password"
              variant="outlined"
              fullwidth
              type="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.password && formik.errors.password ? (
              <small className="text-danger">{formik.errors.password}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-4" style={{ width: "300px" }}>
            <label style={{ color: "#000" }}> 비밀번호 확인</label> <br />
            <TextField
              id="confirm_password"
              variant="outlined"
              fullwidth
              type="password"
              name="confirm_password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirm_password}
              style={{ width: "300px" }}
            />
            <br />
            {formik.touched.confirm_password &&
              formik.errors.confirm_password ? (
              <small className="text-danger">
                {formik.errors.confirm_password}
              </small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className="row mt-5"
          style={{
            fontWeight: "700",
            fontSize: "18px",
            display: "flex",
            width: "100%",
          }}
        >
          <div className="col-lg-4">
            <FormLabel style={{ color: "#000" }} component="legend">
              관리자 담당상태
            </FormLabel>
            <RadioGroup>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="활성 "
                  control={<Radio />}
                  label="활성"
                  name="status"
                  id="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormControlLabel
                  style={{ color: "#000" }}
                  value="비활성"
                  control={<Radio />}
                  label="비활성"
                  name="status"
                  id="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </RadioGroup>
            <br />
            {formik.touched.status && formik.errors.status ? (
              <small className="text-danger">{formik.errors.status}</small>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-7">
            <label style={{ color: "#000" }}> 관리자 메모 </label> <br />
            <textarea
              name="notes"
              id="notes"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notes}
              style={{
                height: "100px",
                width: "95%",
                backgroundColor: "transparent",
                borderRadius: "6px",
              }}
            />
            <br />
            {formik.touched.notes && formik.errors.notes ? (
              <small className="text-danger">{formik.errors.notes}</small>
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className="row mt-5"
          style={{
            fontWeight: "700",
            fontSize: "18px",
            display: "flex",
            width: "100%",
          }}
        >
          <div className="col-lg-1" style={{marginRight: "24px"}}>
            <Button
              type="submit"
              style={{
                backgroundColor: "#5376FF",
                border: "1px solid #5376FF",
                width: "100px",
                height: "auto",
                outline: "none",
              }}
              variant="contained"
              color="primary"
            >
              저장
            </Button>
          </div>
          <div className="col-lg-1">
            <Link to="/admin-list">
              <Button
                style={{
                  border: "1px solid #5376FF",
                  color: "#5376FF",
                  width: "100px",
                  height: "auto",
                  backgroundColor: "transparent",
                  outline: "none",
                }}
                variant="contained"
                color="primary"
              >
                목록
              </Button>
            </Link>
          </div>
          <div className="col-lg-10"></div>
        </div>
      </form>
      <CustomAlert
        type={alert.type}
        message={alert.message}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};

export default AdminAdd;
