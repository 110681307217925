import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import {
  getAllQuestions,
  addNewQuestion,
  getAQuestion,
  sendEmail,
} from "../../api/question";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Loading from "../../components/Loading/Loading";
import Pagination2 from "../../components/Pagination/PaginationNormal";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QuestionList() {
  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [open2, setOpen2] = React.useState(false);

  const [pages, setPages] = useState(1);
  const [size, setSize] = useState();
  const [newPage, setNewPage] = useState();

  const [question, setQuestion] = useState();
  const [newQuestion, setNewQuestion] = useState({
    question_id: "",
    email: "",
    inquiry: "",
    content: "",
    answer: "",
    isAnswer: "",
    user_id: ""
  });

  const [serach, setSearch] = useState({
    from: "",
    to: "",
    answered: "",
    option: "",
    text: "",
  });
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (id) => {
    setOpen(true);
    getAQuestion(id).then((res) => {
      setNewQuestion(res);
    });
  };

  const handleAutoClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    getAllQuestions(1)
      .then((res) => {
        setLoading(false);
        setQuestion(res.data);
        setCount(res.count);
        setPages(res.totalPages)
        setTotalPages(res.totalPages);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...serach, ...{ [name]: value } };
    setSearch(formData);
  };

  const clearText = () => {
    setSearch({ ...serach, ...{ text: "", option: "" } });
  };

  const questionChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...newQuestion, ...{ [name]: value } };
    setNewQuestion(formData);
  };

  const submitQuestion = () => {
    sendEmail(newQuestion)
      .then(() => {
        alert("Email sent.");
        handleAutoClose();
      })
      .catch((e) => {});
  };
  return (
    <div>
      <div className="mx-5">
        <div style={{ fontWeight: "700", fontSize: "40px"}}>
          일대일 문의 목록
        </div>

        <div
          className="align-items-center"
          style={{ fontWeight: "700", display: "flex" }}
        >
          <div style={{ flex: "2", margin: "0 10px" }}></div>
          <div style={{ margin: "0 10px", color: "#000", fontWeight: "700" }}>
            등록일 기간 검색
          </div>
          <div style={{ margin: "0 0 0 10px" }}>
            <TextField
              variant="outlined"
              id="date"
              type="date"
              label="시작일"
              name="from"
              onChange={handleChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "#A9ABB0",
              width: "55px",
              height: "55px",
              color: "white",
              borderRadius: "3px",
              textAlign: "center",
              marginLeft:"12px"
            }}
          >
            ~
          </div>
          <div style={{marginLeft:"12px"}}>
            <TextField
              variant="outlined"
              id="date"
              type="date"
              name="to"
              onChange={handleChange}
              label="종료일"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>

        <div
          className="align-items-center"
          style={{ fontWeight: "700", display: "flex", marginTop:"24px" }}
        >
          <div style={{ flex: "3" }}></div>
          <div style={{ flex: "5", color: "#000", fontWeight: "700" }}>
            <TextField
              id="filled-select-currency"
              select
              label="=status="
              variant="outlined"
              name="answered"
              onChange={handleChange}
              style={{ width: "150px", margin: "0 5px" }}
            >
              <MenuItem value="">Default</MenuItem>
              <MenuItem value="true">Answered</MenuItem>
              <MenuItem value="false">not answered</MenuItem>
            </TextField>

            <TextField
              id="filled-select-currency"
              select
              label="=Option="
              variant="outlined"
              name="option"
              onChange={handleChange}
              style={{ width: "150px" }}
            >
              <MenuItem value="">Default</MenuItem>
              <MenuItem value="title">Title</MenuItem>
              <MenuItem value="id">ID</MenuItem>
              <MenuItem value="name">e-mail</MenuItem>
            </TextField>
          </div>
          <div style={{ flex: "3" }}>
            <TextField
              id="filled-select-currency"
              // placeholder="검색옵션을 선택해주세요."
              label="검색옵션을 선택해주세요."

              variant="outlined"
              fullwidth
              name="text"
              value={serach.text}
              onChange={handleChange}
            />
          </div>
          <div style={{ flex: "1" }}>
            <Button
              style={{
                backgroundColor: "#5376FF",
                color: "#fff",
                padding: "0 2px",
                marginLeft: "-40px",
                height:"54px"
              }}
            >
              {" "}
              검색{" "}
            </Button>
          </div>
          <div style={{ flex: "1" }}>
            <Button
              style={{
                backgroundColor: "#fff",
                color: "#5376FF",
                padding: "0 2px",
                border: "1px solid #c4c4c4"
              }}
            >
              {" "}
              Edit{" "}
            </Button>
          </div>
        </div>

        <div style={{ fontWeight: "500" }}>Total {count}: Count ({newPage}/{totalPages})</div>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#E5E5E5" }}>
              <TableRow>
                <TableCell>NO</TableCell>
                <TableCell align="center">PK</TableCell>
                <TableCell align="center">ID / 닉네임</TableCell>
                <TableCell align="center">1 : 1 문의 제목</TableCell>
                <TableCell align="center">내용</TableCell>
                <TableCell align="center">답변 여부</TableCell>
                <TableCell align="center">문의일시 </TableCell>
              </TableRow>
            </TableHead>
            {loading ? 
            <div style={{ position: 'relative', height: '50vh' }}>
            <div style={{ position: 'absolute', top: '50%', left: '500px', transform: 'translate(-50%, -50%)' }}>
              <CircularProgress />
            </div>
          </div>
          :
            <TableBody>
              {question &&
                question
                  .filter((val) => {
                    if (serach.from == "") {
                      return val;
                    } else if (val.created_at.includes(serach.from)) {
                      return val;
                    }
                  })
                  .filter((val) => {
                    if (serach.to == "") {
                      return val;
                    } else if (val.updated_at.includes(serach.to)) {
                      return val;
                    }
                  })
                  .filter((val) => {
                    if (serach.answered == "") {
                      return val;
                    } else if (serach.answered == val.isAnswer.toString()) {
                      return val;
                    }
                  })
                  .filter((val) => {
                    if (serach.text == "" || serach.option == "") {
                      return val;
                    } else if (serach.option === "title") {
                      if (serach.text == "") {
                        return val;
                      } else if (val.inquiry.includes(serach.text)) {
                        return val;
                      }
                    } else if (
                      serach.option === "id" ||
                      serach.option === "name"
                    ) {
                      if (serach.text == "") {
                        return val;
                      } else if (val.nickname.includes(serach.text)) {
                        return val;
                      }
                    }
                  })
                  .map((quiz, index) => (
                    <TableRow key={quiz.question_id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{quiz.nickname}</TableCell>
                      <TableCell align="center">{quiz.inquiry}</TableCell>
                      <TableCell align="center">
                        <Button
                          onClick={() => handleClickOpen(quiz?.question_id)}
                          style={{
                            backgroundColor: "#fff",
                            color: "#5376FF",
                            padding: "8px 16px",
                            outline: "none",
                            border: "1px solid #5376FF",
                          }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                      {quiz.isAnswer == true ? (
                        <TableCell align="center"> Answered </TableCell>
                      ) : (
                        <TableCell align="center"> Not answered </TableCell>
                      )}
                      <TableCell align="center">
                        {quiz.created_at.split("T")[0]}
                        {"  "}
                        {quiz.created_at.split("T")[1].split(".")[0]}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          }
          </Table>
        </TableContainer>

        <div className="d-flex justify-content-center mt-5">
          <div style={{ flex: "8" }}>
            <Pagination2
              pages={pages}
              setLoading={loading => setLoading(loading)}
              setAdmin={(question) => setQuestion(question)}
              getList={getAllQuestions}
              setSize={(size) => {
                setSize(size);
              }}
              setNewPage={(newPage) => {
                setNewPage(newPage + 1);
              }}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText>
            <label style={{ color: "#000" }}> Email</label> <br />
            <input
              type="text"
              name="email"
              value={newQuestion?.nickname}
              disabled
              className="text-fields"
              placeholder="admin03"
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> Title</label> <br />
            <input
              type="text"
              className="text-fields"
              placeholder="This is Dummy Text"
              name="inquiry"
              disabled
              value={newQuestion?.inquiry}
              onChange={questionChange}
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> Content</label> <br />
            <input
              type="text"
              name="content"
              onChange={questionChange}
              value={newQuestion?.content}
              disabled
              className="text-fields"
              placeholder="This is Dummy Text"
              style={{ width: "400px" }}
            />
          </DialogContentText>
          <DialogContentText>
            <label style={{ color: "#000" }}> Answer</label> <br />
            <textarea
              onChange={questionChange}
              value={newQuestion?.answer}
              name="answer"
              style={{
                height: "100px",
                width: "100%",
                backgroundColor: "transparent",
                borderRadius: "6px",
              }}
              placeholder="This is Dummy Text"
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={submitQuestion}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto",
            }}
          >
            Reply by Email
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Loading open={loading} />*/}
    </div>
  );
}
