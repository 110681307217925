import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";

function CustomAlert({ type, message, open, handleClose }) {
  const vertical = "bottom";
  const horizontal = "right";
  return (
    <Snackbar
      open={open}
      autoHideDuration={1500}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        severity={type}
        style={{
          width: "400px"
        }}
      >
        {type === "success" ? (
          <AlertTitle>Success</AlertTitle>
        ) : type === "error" ? (
          <AlertTitle>Error</AlertTitle>
        ) : type === "warning" ? (
          <AlertTitle>Warning</AlertTitle>
        ) : type === "info" ? (
          <AlertTitle>Info</AlertTitle>
        ) : (
          <AlertTitle></AlertTitle>
        )}
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomAlert;
