import React from "react";
import LoginPage from "../../pages/Login/Login";
import AdminList from "../../pages/Admin/AdminList";
import Sidebar from "../SideBar/SideBar";
import AdminAdd from "../../pages/Admin/AdminAdd";
import AdminEdit from "../../pages/Admin/AdminEdit";
import AdminProfile from "../../pages/Profile/AdminProfile";
import Terms from "../../pages/TermsPolicy/Terms";
import Policy from "../../pages/TermsPolicy/Policy";
import { useEffect, useState } from "react";

// Avatar
import AvatarList from "../../pages/Avatar/AvatarList";
import AvatarRegistration from "../../pages/Avatar/AvatarRegistration";
import AvatarEdit from "../../pages/Avatar/AvatarEdit";

// NFT Management
import NFTCategoryList from "../../pages/NFTManagement/NFTCategoryList";
import NFTCategoryRegistration from "../../pages/NFTManagement/NFTCategoryRegistration";
import NFTCategoryEdit from "../../pages/NFTManagement/NFTCategoryEdit";
import NFTManagement from "../../pages/NFTManagement/NFTManagement";
import NFTModification from "../../pages/NFTManagement/NFTModification";
import NFTSettings from "../../pages/NFTManagement/NFTSettings";
import NFTTransaction from "../../pages/NFTManagement/NFTTransaction";
import NFTRealEstateVerify from "../../pages/NFTManagement/NFTRealEstateVerify";

// Site Management
import PopularList from "../../pages/SiteManagement/PopularList";
import ListExpose from "../../pages/SiteManagement/ListExpose";
import WorldFiltering from "../../pages/SiteManagement/WorldFiltering";

// Service Management
import FrequentsList from "../../pages/Services/FrequenceQuestionList";
import FrequestRegister from "../../pages/Services/RegisterFrequance";
import EditFrequance from "../../pages/Services/EditFrequance";
import NoticeList from "../../pages/Services/NoticeList";
import EditNotice from "../../pages/Services/EditNotice";

import QuestionList from "../../pages/Services/QuestionList";

import UnverifiedUserList from "../../pages/Users/UnverifiedUserList";
import UserEdit from "../../pages/Users/UserEdit";
import UnverifiedUserEdit from "../../pages/Users/UnverifiedUserEdit";
import VerifiedUsers from "../../pages/Users/VerifiedUsers";
import BuyerApprove from "../../pages/Users/BuyerApprove";

import isLogin from "../Middlewares/isLogin";

import { Routes, Route, Navigate } from "react-router-dom";
import Page404 from "../../pages/404Page/Page404";

import { getanAdminMenu } from "../../api/admin";
import Loading from "../Loading/Loading";

import RegistrationOfNotice from "../../pages/Services/RegistrationOfNotice";
import PurchaseApproval from "../../pages/PurchaseApproval/PurchaseApproval";

function AllRoutes() {
	let userData = localStorage.getItem("token");
	const adminId = localStorage.getItem("admin");

	const [sidemenu, setSideMenu] = useState({
		admin_list: "",
		category_list: "",
		nft_list: "",
		nft_settings: "",
		transaction_history: "",
		popular_list: "",
		product_list: "",
		filtering_list: "",
		member_list: "",
		avatar_list: "",
		notice_list: "",
		faq_list: "",
		one_question_list: "",
		terms: "",
		policy: "",
	});

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		getanAdminMenu(adminId).then((res) => {
			setLoading(false);
			if (res?.result) {
				setSideMenu(res?.result[0]);
			} else {
				localStorage.removeItem("token");
			}
		});
	}, []);

	return (
		<>
			<Routes>
				<Route
					exact={true}
					path="/"
					element={
						userData ? <Navigate to="/admin-list" replace /> : <LoginPage />
					}
				/>

				{sidemenu?.admin_list === "true" ? (
					<Route
						path="/admin-list"
						exact={true}
						element={<Sidebar data={userData} component={<AdminList />} />}
					/>
				) : (
					<Route
						path="/admin-list"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.admin_list === "true" ? (
					<Route
						path="/admin-add"
						exact={true}
						element={<Sidebar data={userData} component={<AdminAdd />} />}
					/>
				) : (
					<Route
						path="/admin-add"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.admin_list === "true" ? (
					<Route
						path="/admin-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<AdminEdit />} />}
					/>
				) : (
					<Route
						path="/admin-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.terms === "true" ? (
					<Route
						path="/terms"
						exact={true}
						element={<Sidebar data={userData} component={<Terms />} />}
					/>
				) : (
					<Route
						path="/terms"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.policy === "true" ? (
					<Route
						path="/policy"
						exact={true}
						element={<Sidebar data={userData} component={<Policy />} />}
					/>
				) : (
					<Route
						path="/policy"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				<Route
					path="/admin-profile"
					exact={true}
					element={<Sidebar data={userData} component={<AdminProfile />} />}
				/>
				{sidemenu?.avatar_list === "true" ? (
					<Route
						path="/avatar-list"
						exact={true}
						element={<Sidebar data={userData} component={<AvatarList />} />}
					/>
				) : (
					<Route
						path="/avatar-list"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.avatar_list === "true" ? (
					<Route
						path="/avatar-register"
						exact={true}
						element={
							<Sidebar data={userData} component={<AvatarRegistration />} />
						}
					/>
				) : (
					<Route
						path="/avatar-register"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.avatar_list === "true" ? (
					<Route
						path="/avatar-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<AvatarEdit />} />}
					/>
				) : (
					<Route
						path="/avatar-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.category_list === "true" ? (
					<Route
						path="/nft-categories"
						exact={true}
						element={
							<Sidebar data={userData} component={<NFTCategoryList />} />
						}
					/>
				) : (
					<Route
						path="/nft-categories"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.category_list === "true" ? (
					<Route
						path="/nft-category-reg"
						exact={true}
						element={
							<Sidebar
								data={userData}
								component={<NFTCategoryRegistration />}
							/>
						}
					/>
				) : (
					<Route
						path="/nft-category-reg"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.category_list === "true" ? (
					<Route
						path="/nft-category-edit/:id"
						exact={true}
						element={
							<Sidebar data={userData} component={<NFTCategoryEdit />} />
						}
					/>
				) : (
					<Route
						path="/nft-category-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.nft_list === "true" ? (
					<Route
						path="/nft-management"
						exact={true}
						element={<Sidebar data={userData} component={<NFTManagement />} />}
					/>
				) : (
					<Route
						path="/nft-management"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.nft_list === "true" ? (
					<Route
						path="/nft-modification/:id"
						exact={true}
						element={
							<Sidebar data={userData} component={<NFTModification />} />
						}
					/>
				) : (
					<Route
						path="/nft-modification/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.nft_settings === "true" ? (
					<Route
						path="/nft-settings"
						exact={true}
						element={<Sidebar data={userData} component={<NFTSettings />} />}
					/>
				) : (
					<Route
						path="/nft-settings"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.transaction_history === "true" ? (
					<Route
						path="/nft-transaction"
						exact={true}
						element={<Sidebar data={userData} component={<NFTTransaction />} />}
					/>
				) : (
					<Route
						path="/nft-transaction"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.popular_list === "true" ? (
					<Route
						path="/popular"
						exact={true}
						element={<Sidebar data={userData} component={<PopularList />} />}
					/>
				) : (
					<Route
						path="/popular"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				<Route
					path="/expose"
					exact={true}
					element={<Sidebar data={userData} component={<ListExpose />} />}
				/>
				{sidemenu?.filtering_list === "true" ? (
					<Route
						path="/world-filter"
						exact={true}
						element={<Sidebar data={userData} component={<WorldFiltering />} />}
					/>
				) : (
					<Route
						path="/world-filter"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.notice_list === "true" ? (
					<Route
						path="/notice-list"
						exact={true}
						element={<Sidebar data={userData} component={<NoticeList />} />}
					/>
				) : (
					<Route
						path="/notice-list"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.notice_list === "true" ? (
					<Route
						path="/notice-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<EditNotice />} />}
					/>
				) : (
					<Route
						path="/notice-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.faq_list === "true" ? (
					<Route
						path="/frequent-list"
						exact={true}
						element={<Sidebar data={userData} component={<FrequentsList />} />}
					/>
				) : (
					<Route
						path="/frequent-list"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.faq_list === "true" ? (
					<Route
						path="/frequent-register"
						exact={true}
						element={
							<Sidebar data={userData} component={<FrequestRegister />} />
						}
					/>
				) : (
					<Route
						path="/frequent-register"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.faq_list === "true" ? (
					<Route
						path="/frequent-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<EditFrequance />} />}
					/>
				) : (
					<Route
						path="/frequent-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.one_question_list === "true" ? (
					<Route
						path="/question-list"
						exact={true}
						element={<Sidebar data={userData} component={<QuestionList />} />}
					/>
				) : (
					<Route
						path="/question-list"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				{sidemenu?.member_list === "true" ? (
					<Route
						path="/unverified-user-list"
						exact={true}
						element={<Sidebar data={userData} component={<UnverifiedUserList />} />}
					/>
				) : (
					<Route
						path="/unverified-user-list"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.member_list === "true" ? (
					<Route
						path="/verified-member-list"
						exact={true}
						element={<Sidebar data={userData} component={<VerifiedUsers />} />}
					/>
				) : (
					<Route
						path="/verified-member-list"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.member_list === "true" ? (
					<Route
						path="/user-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<UserEdit />} />}
					/>
				) : (
					<Route
						path="/user-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}

				{sidemenu?.member_list === "true" ? (
					<Route
						path="/unverified-edit/:id"
						exact={true}
						element={
							<Sidebar data={userData} component={<UnverifiedUserEdit />} />
						}
					/>
				) : (
					<Route
						path="/unverified-edit/:id"
						exact={true}
						element={<Sidebar data={userData} component={<Page404 />} />}
					/>
				)}
				<Route
					path="/*"
					exact={true}
					element={<Sidebar data={userData} component={<Page404 />} />}
				/>

				<Route
					path="/nft-verification"
					exact={true}
					element={
						<Sidebar data={userData} component={<NFTRealEstateVerify />} />
					}
				/>

				<Route
					path="/purchase-verification"
					exact={true}
					element={<Sidebar data={userData} component={<BuyerApprove />} />}
				/>

				<Route
					path="/notice-register"
					exact={true}
					element={
						<Sidebar data={userData} component={<RegistrationOfNotice />} />
					}
				/>

				<Route
					path="/purchase-requests"
					exact={true}
					element={<Sidebar data={userData} component={<PurchaseApproval />} />}
				/>
			</Routes>
			{/*<Loading open={loading} />*/}
		</>
	);
}

export default AllRoutes;
