import React, { useState, useEffect } from "react";
import Expansion from "../../components/Expansion/Expansion";
import { getUnverifiedList } from "../../api/nft";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";

import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { nftArtworkVerify } from "../../api/nft";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BuyerApprove() {
  const [unverified, setUnverified] = useState();
  const useStyles = makeStyles({
    table: {
      minWidth: 700
    }
  });

  const admin_id = localStorage.getItem("admin");

  const [application, setApplication] = React.useState({
    admin_id: admin_id,
    password: "",
    comments: ""
  });

  const [open, setOpen] = React.useState(false);

  const [state, setState] = React.useState();

  const [id, setId] = useState();

  const handleClickOpen = (e) => {
    setOpen(true);
    // e.preventDefault();
  };

  const handleAutoClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...application, ...{ [name]: value } };
    setApplication(formData);
  };

  const classes = useStyles();
  useEffect(() => {
    getUnverifiedList().then((res) => {
      setUnverified(res);
    });
  }, []);
  return (
    <div className="mx-5 mt-5">
      <div className="mb-5" style={{ fontWeight: "700", fontSize: "40px" }}>
        Purchase verification
      </div>
      <div className="mt-5">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead style={{ backgroundColor: "#E5E5E5" }}>
              <TableRow>
                <TableCell>NO</TableCell>
                <TableCell align="center">PK</TableCell>
                <TableCell align="center">Artwork name</TableCell>
                <TableCell align="center">Buyer name</TableCell>
                <TableCell align="center">Artwork</TableCell>
                <TableCell align="center">Approve</TableCell>
                <TableCell align="center">Decline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unverified &&
                unverified.map((data, index) => (
                  <TableRow key={data.id}>
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{data.title}</TableCell>
                    <TableCell align="center"></TableCell>

                    <TableCell align="center">
                      <img
                        alt="artwork_image"
                        src={`${process.env.REACT_APP_IPFS_URL}${data.nft_token[0]?.file}`}
                        style={{ width: "25%", height: "max-width" }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => {
                          handleClickOpen();
                          setId(data.art_work_id);
                          setState("verified");
                        }}
                        style={{
                          backgroundColor: "#fff",
                          color: "#5376FF",
                          padding: "8px 16px",
                          outline: "none",
                          border: "1px solid #5376FF"
                        }}
                      >
                        Approve
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => {
                          handleClickOpen();
                          setId(data.art_work_id);
                          setState("unverified");
                        }}
                        style={{
                          backgroundColor: "#fff",
                          color: "#5376FF",
                          padding: "8px 16px",
                          outline: "none",
                          border: "1px solid #5376FF"
                        }}
                      >
                        Decline
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAutoClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title" className="mt-3 text-center">
          <h2>Approved by KYC manager</h2>
          <p
            className="font-weight-light mx-5 mt-3 mb-5"
            style={{ fontSize: "16px", color: "#7E99AE" }}
          >
            If you approve KYC, you need the administrator password. Please
            enter it and proceed.
          </p>
        </DialogTitle>

        <DialogContent className="mx-4">
          <DialogContentText>
            <label style={{ color: "#000" }}> Password</label> <br />
            <input
              type="password"
              name="password"
              className="text-fields"
              value={application.password}
              placeholder="Please enter the administrator password."
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </DialogContentText>
          <DialogContentText className="mt-3">
            <label style={{ color: "#000" }}> 비밀번호</label> <br />
            <textarea
              name="comments"
              className="text-fields"
              value={application.comments}
              onChange={handleChange}
              rows={5}
              placeholder="Please write a comment."
              style={{ width: "100%", height: "100px" }}
            />
            {/*message && <p style={{ color: "red" }}>Password is incorrect</p>*/}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center mb-5">
          <Button
            onClick={() => nftArtworkVerify(application, id, state)}
            style={{
              backgroundColor: "#076D42",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none"
            }}
          >
            거래 중지
          </Button>
          <Button
            onClick={handleAutoClose}
            style={{
              backgroundColor: "#0500FF",
              color: "#fff",
              width: "150px",
              height: "auto",
              outline: "none"
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BuyerApprove;
