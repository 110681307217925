import axios from "axios";
const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
};

export const getAllPurchaserequest = async () => {
  try {
    let respData = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/admin/all-purchase-request`,
      config
    );

    return respData.data;
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const approvePurchanseRequest = async (id, user, _id) => {
  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/admin/approve-purchase-request/${id}/${user}/${_id}`,
      {},
      config
    );

    return respData;
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const rejectPurchanseRequest = async (id, user, _id) => {
  try {
    let respData = await axios.put(
      `${process.env.REACT_APP_SERVER}/api/admin/reject-purchase-request/${id}/${user}/${_id}`,
      {},
      config
    );
    return respData;
  } catch (err) {
    localStorage.removeItem("token");
    return {
      status: "failed",
      loading: false,
      error: err.response.data.errors,
    };
  }
};
